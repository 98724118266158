import styled, { css } from 'styled-components';
import { helpers, media } from '@/assets/styles';
import { colors } from '@/designSystem/colors';

const OriginDescription = styled.p(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: theme.typography.body.fontSize })};
    ${helpers.colorSet({ color: theme.colors.text[100] })};
  `,
);

export const Container = styled.div``;
export const Description = styled(OriginDescription)`
  margin-bottom: 30px;
`;
export const SecondDescription = styled(OriginDescription)`
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const ListContainer = styled.ul`
  color: #69686a;
  margin: 0px;
  padding: 0px;
  margin-top: 12px;
  list-style: none;
`;

export const ListContainerCheckbox = styled.ul`
  color: #69686a;
  list-style: none;
  margin-top: 25px;
`;

export const ListItem = styled.li`
  list-style: none;
  margin: 0px;
  padding: 0px;
`;

export const AgreeBox = styled.div(
  ({ theme }) => css`
    ${helpers.flexSet('row', 'flex-start', 'flex-start')};
    ${helpers.borderSet({
      borderRadius: theme.borders.default.radius,
      borderWidth: theme.borders.default.width,
      borderColor: theme.colors.primary[100],
    })}
    ${helpers.colorSet({ backgroundColor: theme.colors.primary[50] })};

    padding: 12px 20px;
    margin: 16px 0;
  `,
);

export const Terms = styled.p(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: theme.typography.body.fontSize })};
    ${helpers.colorSet({ color: theme.colors.text[100] })};
    margin-left: 10px;
    align-self: center;
  `,
);

export const LinkText = styled.a(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: theme.typography.body.fontSize })};
    ${helpers.colorSet({ color: theme.colors.link.default })};
  `,
);

export const More = styled.p(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: theme.typography.body.fontSize })};
    ${helpers.colorSet({ color: theme.colors.text[100] })};
    margin-bottom: 44px;
  `,
);

export const BodyWrapper = styled.div`
  border-radius: 8px;
  background: ${colors.white};
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  height: calc(100vh - 102px);
  ${media.lg`
    height: fit-content;
    padding: 0 16px;
    gap: 30px;
    padding: 16px 24px;
  `}
`;
