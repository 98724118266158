import React from 'react';
import { useTranslation } from 'react-i18next';
import { Description } from './index.styles';
import { NotVerifiedViewProps } from './index.types';

const NotVerifiedView = ({
  descriptionText,
  descriptionText2,
  descriptionText3,
  descriptionText4,
}: NotVerifiedViewProps) => {
  const { t } = useTranslation('common');

  return (
    <>
      {!!t(descriptionText) && <Description tabIndex={0}>{t(descriptionText)}</Description>}
      {!!t(descriptionText2) && <Description tabIndex={0}>{t(descriptionText2)}</Description>}
      {!!t(descriptionText3) && <Description tabIndex={0}>{t(descriptionText3)}</Description>}
      {!!t(descriptionText4) && <Description tabIndex={0}>{t(descriptionText4)}</Description>}
    </>
  );
};

export default NotVerifiedView;
