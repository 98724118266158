import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { UnitHelper } from '@/helpers/UnitHelper';
import { accessibilityUsageLabel } from '@/helpers/AccessibilityHelper';
import { UserStatusEnum } from '@/types/dr';
import { images } from '@/assets/styles';
import { getProgramNameFromSite } from '@/helpers/GetProgramName';
import { APP_ENV } from '@/constants/environment';
import { PerEventDisclaimer } from '@/helpers/PerEventDisclaimer';
import { getProgramContext } from '@/helpers/ProgramStrategy';
import { IEventItemProps } from './index.types';
import {
  Wrapper,
  Percent,
  Date,
  EventUsage,
  Button,
  EventTitleConainer,
  EventTitle,
  DateContainer,
  Img,
  Divider,
  ValueContainer,
  UsageRowContainer,
  Description,
  ImgArrow,
} from './index.styles';

const EventItemContainer = ({
  item,
  onClick,
  index,
  selectedEvent,
  changeInValue,
  percentageChangeInValue,
  isEmission = false,
}: IEventItemProps) => {
  const {
    sites: {
      data: { currentSite },
    },
  } = useSelector((state: RootState) => state);

  const { t } = useTranslation('common');

  const checkPending = item.hadJoined && !item.hasUsageData;

  const checkNoReply = !item.hadJoined && item.userStatus === UserStatusEnum.DefaultEvent;

  const checkDeclined = !item.hadJoined;

  const isFinalWithErrors = item.quickViewData.moneyEarnedDisclaimer === PerEventDisclaimer.Incalculable;

  const programName = getProgramNameFromSite(currentSite);
  const context = getProgramContext({ programName });
  const shouldDisplayCO2FeatureChanges = context?.getShouldDisplayCO2Emissions();

  const checkReduced = () => {
    if (isFinalWithErrors) {
      return true;
    }
    if (checkPending || checkNoReply || checkDeclined) {
      return undefined;
    }

    return item.quickViewData.hadReduced;
  };

  const renderName = () => {
    if (!item?.targetDirection && APP_ENV === 'production') {
      return '';
    }
    return `${t(`programs.${programName}.savingsEventsScreen.targetDirection.${item.targetDirection}`)} #${index}`;
  };

  const renderUsage = () => {
    if (checkPending && !isFinalWithErrors) {
      return (
        <EventUsage
          reduced={checkReduced()}
          aria-label={accessibilityUsageLabel(
            t('screens.main.savingsEvents.savingsEventsHistoryScreen.reduction.pending.title'),
          )}
        >
          {t('screens.main.savingsEvents.savingsEventsHistoryScreen.reduction.pending.title')}
        </EventUsage>
      );
    }

    if (checkNoReply) {
      return (
        <EventUsage
          reduced={checkReduced()}
          aria-label={accessibilityUsageLabel(
            t('screens.main.savingsEvents.savingsEventsHistoryScreen.reduction.noReply.title'),
          )}
        >
          {t('screens.main.savingsEvents.savingsEventsHistoryScreen.reduction.noReply.title')}
        </EventUsage>
      );
    }

    if (checkDeclined) {
      return (
        <EventUsage
          reduced={checkReduced()}
          aria-label={accessibilityUsageLabel(
            t('screens.main.savingsEvents.savingsEventsHistoryScreen.reduction.declined.title'),
          )}
        >
          {t('screens.main.savingsEvents.savingsEventsHistoryScreen.reduction.declined.title')}
        </EventUsage>
      );
    }

    const absoluteChangeInValue = isFinalWithErrors ? 0 : Math.abs(changeInValue);
    const formattedEnergyValue = isEmission
      ? `${UnitHelper.renderUnitValue(changeInValue || 0, 'lbs', false, 2)} CO2`
      : UnitHelper.formatEnergyValue(absoluteChangeInValue, 2);

    return (
      <div>
        <UsageRowContainer>
          <Img
            src={checkReduced() ? images.arrowDownPerformanceBlue.image : images.eventArrowUpRed.image}
            style={{ width: '7px', height: '20px' }}
          />
          <EventUsage reduced={checkReduced()} aria-label={accessibilityUsageLabel(formattedEnergyValue)}>
            {formattedEnergyValue}
          </EventUsage>
          {!shouldDisplayCO2FeatureChanges && (
            <Description reduced={checkReduced()}>
              {t('screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.reduction')}
            </Description>
          )}
        </UsageRowContainer>
        <UsageRowContainer>
          <Img
            src={checkReduced() ? images.arrowDownBlack.image : images.arrowUpBlack.image}
            style={{ height: '20px', width: '7px' }}
          />
          <Percent>{UnitHelper.renderUnitValue(percentageChangeInValue || 0, 'percent', false, 2)}</Percent>
        </UsageRowContainer>
      </div>
    );
  };

  return (
    <Button
      onClick={() => onClick({ ...item, name: renderName() })}
      disabled={checkDeclined || isFinalWithErrors}
      selected={selectedEvent.eventId === item.eventId}
    >
      <Wrapper>
        <EventTitleConainer>
          <EventTitle>{renderName()}</EventTitle>
          <DateContainer>
            <Img src={images.calendarGrey.image} alt={images.calendarGrey.text} />
            <Date>{moment(item.date).format('MMMM Do, YYYY')}</Date>
          </DateContainer>
        </EventTitleConainer>
        <Divider />
        <ValueContainer>{renderUsage()}</ValueContainer>
      </Wrapper>
      <ImgArrow
        src={images.arrowRightGreen.image}
        alt={images.arrowRightGreen.text}
        style={{ width: '32px', height: '32px' }}
      />
    </Button>
  );
};

export default EventItemContainer;
