import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '@/stores/index';
import contents from '@/assets/config/appInfo';
import {
  BodyWrapper,
  LeftSection,
  Navigator,
  ScreenName,
  SiteName,
  Wrapper,
} from '@/screens/Main/Settings/MySites/ManageSites/ManageSites.styles';
import { SiteItem as SiteItemType } from '@/types/sites';
import { EnrollmentStatus, ProgramName } from '@/types/enrollment';
import { images } from '@/assets/styles';
import IconButton from '@/atoms/IconButton';
import { LinkButton, PrimaryButton } from '@/designSystem/components/index';
import PATHS from '@/types/navigationPaths';
import usePostReEnrollmentMultiSites from '@/hooks/useReEnrollmentMultiSites';
import { FetchingStatus } from '@/hooks/types';
import {
  Description,
  ListContainer,
  ListContainerCheckbox,
  ListItem,
  Terms,
  AgreeBox,
  LinkText,
  More,
} from './ReEnrollmentMultipleSites.styles';
import {
  HomesSection,
  SectionDescriptionModal,
  SiteCheckboxItem,
  SiteItem,
} from '../../../../Enrollment/ProvisionalProgramsScreen/components';
import { ReEnrollmentErrorModal } from '../ReEnrollmentErrorModal';
import { ReEnrollmentSuccessModal } from '../ReEnrollmentSuccessModal';

const formatSiteAddress = (site: SiteItemType) =>
  [site.addressStreet1, site.addressStreet2, site.city, site.state, site.zipcode].join(' ');

const ReEnrollmentMultipleSitesScreen = () => {
  const { t } = useTranslation('common');
  const {
    state: { eligibleSiteIds },
  } = useLocation();
  const [showEnrolledHomesModal, setShowEnrolledHomesModal] = useState(false);
  const [showIneligibleHomesModal, setShowIneligibleHomesModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const reEnrollmentMultiSitesState = usePostReEnrollmentMultiSites();
  const navigate = useNavigate();

  const {
    sites: {
      data: { sites },
    },
  } = useSelector((state: RootState) => state);
  document.title = `ReEnrollment Multi Site - ${contents.name}`;

  const programName = sites[0].program;

  const onSubmit = async (payload: Array<{ program: ProgramName; siteId: string }>) => {
    if (
      reEnrollmentMultiSitesState.fetchingStatus === FetchingStatus.INITIAL ||
      reEnrollmentMultiSitesState.fetchingStatus === FetchingStatus.SUCCESS ||
      reEnrollmentMultiSitesState.fetchingStatus === FetchingStatus.ERROR
    ) {
      reEnrollmentMultiSitesState.post(payload, {
        onSuccess: () => setShowSuccessModal(true),
        onError: () => setShowErrorModal(true),
      });
    }
  };

  const handleBackSetting = () => {
    navigate(PATHS.SCREEN_SETTING_MANAGE_SITE.pathname);
  };

  useEffect(() => {
    if (!programName) {
      handleBackSetting();
    }
  }, [sites]);

  const [selectedHomesIds, setSelectedHomesIds] = useState<Array<string>>([]);

  const eligiblesAndNotEnrolledHomes: Array<SiteItemType> = [];
  const enrolledHomes: Array<SiteItemType> = [];
  const ineligibleHomes: Array<SiteItemType> = [];

  const onClickCheckbox = (selectedHomeId: string) => {
    if (!selectedHomesIds.includes(selectedHomeId)) {
      setSelectedHomesIds([...selectedHomesIds, selectedHomeId]);
      return;
    }
    setSelectedHomesIds(selectedHomesIds.filter((siteId) => siteId !== selectedHomeId));
  };

  const handleTermsLink = (url: string) => {
    if (window.ReactNativeWebView) {
      const data = JSON.stringify({ open: 'browser', message: url });
      window.ReactNativeWebView.postMessage(data);
    } else {
      window.open(url, '_blank');
    }
  };

  const noSitesChecked = selectedHomesIds.length === 0;
  const noSitesEnrolled = enrolledHomes.length === 0;
  const eligiblesHomesDescription = noSitesEnrolled
    ? t('screens.enrollment.provisionalProgramsScreen.eligible.descriptionWithoutEnrolledSites')
    : t('screens.enrollment.provisionalProgramsScreen.eligible.descriptionWithEnrolledSites');
  const nextButtonDisabled = (noSitesChecked && noSitesEnrolled) || !termsAgreed;

  const sitesToSubmit = sites
    .filter(({ id }) => selectedHomesIds.includes(id))
    .map(({ id, program }) => ({ siteId: id, program }));

  sites.forEach((site) => {
    if (!site) {
      return;
    }
    if (!!eligibleSiteIds?.length && eligibleSiteIds.includes(site.id)) {
      eligiblesAndNotEnrolledHomes.push(site);
      return;
    }
    if (site.enrollmentStatus === EnrollmentStatus.Enrolled) {
      enrolledHomes.push(site);
      return;
    }
    ineligibleHomes.push(site);
  });

  return (
    <Wrapper>
      <Navigator>
        <LeftSection>
          <ScreenName>{t('screens.main.settings.mySites.mySitesScreen.title')}</ScreenName>
          <SiteName>{t('screens.main.settings.mySites.reEnrollmentScreen.title')}</SiteName>
        </LeftSection>
        <LinkButton
          onClick={handleBackSetting}
          image={{
            imagePlace: 'left',
            active: { src: images.greenChevronUp.image, alt: t(images.greenChevronUp.text) },
            disabled: { src: images.greenChevronUp.image, alt: t(images.greenChevronUp.text) },
          }}
          underline={false}
          title={t('common.generalButtons.back')}
          padding="12px 16px"
        />
      </Navigator>
      <BodyWrapper>
        <Description tabIndex={0}>{t('screens.enrollment.provisionalProgramsScreen.description')}</Description>
        <>
          <HomesSection
            title={t('screens.enrollment.provisionalProgramsScreen.eligible.title')}
            description={eligiblesHomesDescription}
          >
            <ListContainerCheckbox>
              {eligiblesAndNotEnrolledHomes.map((site) => (
                <ListItem key={site.id}>
                  <SiteCheckboxItem
                    siteId={site.id}
                    title={site.name}
                    isChecked={selectedHomesIds.includes(site.id)}
                    onClickCheck={() => onClickCheckbox(site.id)}
                    description={formatSiteAddress(site)}
                  />
                </ListItem>
              ))}
            </ListContainerCheckbox>
          </HomesSection>
          {enrolledHomes.length > 0 && (
            <HomesSection
              title={t('screens.enrollment.provisionalProgramsScreen.enrolled.title')}
              onClickHelp={() => setShowEnrolledHomesModal(true)}
            >
              <ListContainer>
                {enrolledHomes.map((site) => (
                  <ListItem key={site.id}>
                    <SiteItem title={site.name} description={formatSiteAddress(site)} siteId={site.id} />
                  </ListItem>
                ))}
              </ListContainer>
            </HomesSection>
          )}
          {ineligibleHomes.length > 0 && (
            <HomesSection
              title={t('screens.enrollment.provisionalProgramsScreen.ineligible.title')}
              onClickHelp={() => setShowIneligibleHomesModal(true)}
            >
              <ListContainer>
                {ineligibleHomes.map((site) => (
                  <ListItem key={site.id}>
                    <SiteItem title={site.name} description={formatSiteAddress(site)} siteId={site.id} />
                  </ListItem>
                ))}
              </ListContainer>
            </HomesSection>
          )}
          {!!selectedHomesIds?.length && (
            <div>
              <AgreeBox>
                <IconButton
                  role="checkbox"
                  ariaSelected={termsAgreed}
                  height={20}
                  width={20}
                  icon={termsAgreed ? images.checkboxChecked.image : images.checkbox.image}
                  onClick={() => setTermsAgreed(!termsAgreed)}
                  ariaLabel="Terms and Conditions checkbox"
                />
                <Terms>
                  {t(`programs.${programName}.availableProgramScreen.terms.sentence1`)}
                  <LinkText
                    onClick={() => handleTermsLink(t(`programs.${programName}.availableProgramScreen.terms.link`))}
                    role="link"
                    tabIndex={0}
                  >
                    {t(`programs.${programName}.availableProgramScreen.terms.sentenceBtn`)}
                  </LinkText>
                  {t(`programs.${programName}.availableProgramScreen.terms.sentence2`)}
                  <LinkText
                    onClick={() => handleTermsLink(t(`programs.${programName}.availableProgramScreen.terms.link2`))}
                    role="link"
                    tabIndex={0}
                  >
                    {t(`programs.${programName}.availableProgramScreen.terms.sentenceBtn2`)}
                  </LinkText>
                </Terms>
              </AgreeBox>
              <More>
                {t(`programs.${programName}.availableProgramScreen.more.sentence1`)}
                <LinkText
                  onClick={() => handleTermsLink(t(`programs.${programName}.availableProgramScreen.more.link`))}
                  role="link"
                  tabIndex={0}
                >
                  {t(`programs.${programName}.availableProgramScreen.more.sentenceBtn`)}
                </LinkText>
                {t(`programs.${programName}.availableProgramScreen.more.sentence2`)}
              </More>
            </div>
          )}
          <PrimaryButton
            disabled={nextButtonDisabled}
            title={t('common.generalButtons.enroll')}
            onClick={() => onSubmit(sitesToSubmit)}
            ariaLabel={t('common.generalButtons.enroll')}
          />
        </>
      </BodyWrapper>

      <SectionDescriptionModal
        showModal={showEnrolledHomesModal}
        closeModal={() => setShowEnrolledHomesModal(false)}
        title="Enrolled Home(s)"
      >
        <p>
          {t(`programs.${programName}.provisionalProgramsScreen.enrolled.modalDescription.regularText`, {
            programName: t(`programs.${programName}.title`),
          })}
          <a
            href={t(`programs.${programName}.provisionalProgramsScreen.enrolled.modalDescription.link.href`)}
            target="_blank"
            rel="noreferrer"
          >
            {t(`programs.${programName}.provisionalProgramsScreen.enrolled.modalDescription.link.text`)}
          </a>
        </p>
      </SectionDescriptionModal>
      <SectionDescriptionModal
        showModal={showIneligibleHomesModal}
        closeModal={() => setShowIneligibleHomesModal(false)}
        title="Ineligible Home(s)"
      >
        <p>{t(`programs.${programName}.provisionalProgramsScreen.ineligible.modalDescription.regularText`)}</p>
      </SectionDescriptionModal>

      {showErrorModal && (
        <ReEnrollmentErrorModal
          title={t('screens.main.settings.mySites.reEnrollmentScreen.error.headerTitle')}
          description={t('screens.main.settings.mySites.reEnrollmentScreen.error.description')}
          isVisible={showErrorModal}
          onClose={() => setShowErrorModal(false)}
          buttonText={t('common.generalButtons.close')}
        />
      )}
      {showSuccessModal && (
        <ReEnrollmentSuccessModal
          title={t('screens.main.settings.mySites.reEnrollmentSuccessScreen.title')}
          description={t('screens.main.settings.mySites.reEnrollmentSuccessScreen.description', {
            programTitle: t(`programs.${programName}.title`),
          })}
          isVisible={showSuccessModal}
          onClose={() => {
            setShowSuccessModal(false);
            navigate(PATHS.SCREEN_DASHBOARD.pathname);
          }}
          buttonText={t('common.generalButtons.ok')}
        />
      )}
    </Wrapper>
  );
};

export default ReEnrollmentMultipleSitesScreen;
