import { useMutation } from '@tanstack/react-query';
import * as apiEnrollment from '@/apis/enrollment';
import { ConfirmLinkEnrollmentResponse } from '@/types/enrollment';
import { IResponse } from '@/apis/api';
import { FetchingStatus, PostRequestHook } from './types';

const useConfirmLinkEnrollment = (): PostRequestHook<void, IResponse<ConfirmLinkEnrollmentResponse>> => {
  const { error, isError, mutate, isLoading, isSuccess } = useMutation({
    mutationFn: () => apiEnrollment.postConfirmLinkEnrollment(),
    mutationKey: ['apiEnrollment.confirmLinkEnrollment'],
  });

  if (isLoading) {
    return { fetchingStatus: FetchingStatus.LOADING };
  }

  if (isError) {
    return {
      fetchingStatus: FetchingStatus.ERROR,
      error,
      post: mutate,
    };
  }

  if (isSuccess) {
    return {
      fetchingStatus: FetchingStatus.SUCCESS,
      post: mutate,
    };
  }

  return {
    fetchingStatus: FetchingStatus.INITIAL,
    post: mutate,
  };
};

export default useConfirmLinkEnrollment;
