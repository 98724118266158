import styled, { css } from 'styled-components';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';
import { media } from '@/assets/styles';

export const Wrapper = styled.div`
  background-color: ${colors.white};
  border: none;
  border-radius: 8px;
  padding: 15px 0 5px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  ${media.lg`
    flex-direction: row;
    padding: 15px;
  `}

  ${media.xl`
    gap: 40px;
    justify-content: unset;
  `}
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const EarningsTitleConainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 10px;
  flex: 1;
`;

export const EarningsTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const DateContainer = styled.div`
  display: flex;
  gap: 5px;
`;

export const Img = styled.img<{ width?: string; height?: string }>`
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
`;
export const ValueContainer = styled.div`
  display: flex;
  flex: 1;
  ${media.lg`  
    justify-content: center;
    align-items: center;
  `}
`;

export const Date = styled.p`
  ${typography.xxs};
  line-height: 16px;
  font-weight: 400;
  color: ${colors.grey};
`;

export const Incentive = styled.p`
  ${typography.xs};
  font-weight: 800;
  line-height: 18px;
  color: ${colors.text};
`;

export const Divider = styled.div`
  border-top: 1.5px solid ${colors.lightGrey};
  height: 37px;
  ${media.lg`
    padding-top: unset;
    margin-bottom: unset;
    border-left: 1.5px solid ${colors.lightGrey};
  `}
`;

export const PendingEvent = styled.p`
  ${typography.xs};
  font-weight: 800;
  line-height: 18px;
  color: ${colors.text}};
  padding-bottom: 10px;
  ${media.lg` 
    display:  flex;
    padding-bottom: 0px;
    height: 100%;
    align-items: center;
  `}
  ${media.xl`
    display:  flex;
    padding-bottom: 0px;
    height: 100%;
    align-items: center;
  `}
`;
