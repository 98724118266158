import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PATHS from '@/types/navigationPaths';
import { RootState } from '@/stores/index';
import useUsagePattern from '@/hooks/useUsagePattern';
import { useSites } from '@/hooks/index';
import { GBCStatus as GBCStatusType } from '@/types/userInfo';
import contents from '@/assets/config/appInfo';
import { useTranslation } from 'react-i18next';
import { TimePeriod } from '@/types/usagePattern';
import { sentEvent } from '@/helpers/GAHelper';
import { FetchingStatus } from '@/hooks/types';
import UsagePatternView from '@/organisms/UsagePattern/index.view';
import { IconButton, PrimaryButton } from '@/designSystem/components/index';
import useUsagePatternDateList from '@/hooks/useUsagePatternDateList';
import { DropdownContainer, Header, MobileTitle, Wrapper as Content } from '@/organisms/UsagePattern';
import Dropdown from '@/molecules/Dropdown';
import { images } from '@/assets/styles';
import Tabs from '@/molecules/Tabs';
import ReactLoading from 'react-loading';
import { colors } from '@/designSystem/colors';
import { ErrorContainer, ErrorDescription, ErrorTitle, LoadingWrapper, Wrapper } from './index.styles';

const makeTimeList = (timeList: Array<string>) => {
  if (timeList.length === 1 && timeList[0] === '') {
    return [];
  }

  return timeList.map((text: string) => ({ id: text, name: text, text }));
};

const UsagePatternScreenContainer = () => {
  const { t } = useTranslation('common');
  const {
    userInfo: {
      data: { userInfo },
    },
    sites: {
      data: { currentSite },
    },
  } = useSelector((state: RootState) => state);

  // Energy Usage - ClimateResponse®
  document.title = `${t('screens.main.usagePattern.usagePatternScreen.title')} - ${contents.name}`;

  const [selectedPeriodFilter, setSelectedPeriodFilter] = useState(TimePeriod.day);
  const [currentRange, setCurrentRange] = useState('initial');

  const onPeriodUpdate = (newPeriod: TimePeriod) => {
    if (!currentSite || selectedPeriodFilter === newPeriod) {
      return;
    }

    setSelectedPeriodFilter(newPeriod);

    sentEvent('click', PATHS.DATE(newPeriod));
  };

  const onUpdateRange = (newRange: string) => {
    if (!currentSite || currentRange === newRange) {
      return;
    }

    setCurrentRange(newRange);
    sentEvent('click', PATHS.DATE(newRange));
  };

  const handlePrevRange = ({ timeList, range }: { timeList: Array<string>; range: string }) => {
    if (range === 'initial') {
      onUpdateRange(timeList[1]);
      return;
    }
    const currentIndex = timeList.findIndex((date: string) => date === range);
    const changeRange = timeList[Math.min(timeList.length, currentIndex + 1)];
    onUpdateRange(changeRange);
  };

  const handleNextRange = ({ timeList, range }: { timeList: Array<string>; range: string }) => {
    const currentIndex = timeList.findIndex((date: string) => date === range);
    const changeRange = timeList[Math.max(0, currentIndex - 1)];
    onUpdateRange(changeRange);
  };

  const { fetchSites } = useSites();
  const usagePatternState = useUsagePattern({
    siteId: currentSite?.id || '',
    timePeriod: selectedPeriodFilter,
    range: currentRange,
  });

  const dateListState = useUsagePatternDateList({
    siteId: currentSite?.id || '',
    timePeriod: selectedPeriodFilter,
  });

  useEffect(() => {
    if (userInfo.gbcStatus === GBCStatusType.completed && !currentSite) {
      fetchSites(userInfo.siteName);
    }
  }, []);

  useEffect(() => {
    onUpdateRange('initial');
  }, [selectedPeriodFilter]);

  const navigate = useNavigate();
  useEffect(() => {
    if (userInfo.isDeleted === true) {
      navigate(PATHS.SCREEN_SETTING_ACCOUNT.pathname);
    }
  });

  if (dateListState.fetchingStatus === FetchingStatus.LOADING) {
    return (
      <LoadingWrapper style={{ marginTop: 20 }}>
        <ReactLoading type="spin" color={colors.primary} height="3%" width="3%" />
      </LoadingWrapper>
    );
  }

  if (usagePatternState.fetchingStatus === FetchingStatus.ERROR) {
    return (
      <ErrorContainer>
        <ErrorTitle>{t('screens.errorScreen.data.title')}</ErrorTitle>
        <ErrorDescription>{t('screens.errorScreen.data.description')}</ErrorDescription>
        <PrimaryButton onClick={usagePatternState.refetch} title={t('common.generalButtons.tryAgain')} />
      </ErrorContainer>
    );
  }

  if (dateListState.fetchingStatus === FetchingStatus.ERROR) {
    return (
      <ErrorContainer>
        <ErrorTitle>{t('screens.errorScreen.data.title')}</ErrorTitle>
        <ErrorDescription>{t('screens.errorScreen.data.description')}</ErrorDescription>
        <PrimaryButton onClick={dateListState.refetch} title={t('common.generalButtons.tryAgain')} />
      </ErrorContainer>
    );
  }

  if (dateListState.fetchingStatus === FetchingStatus.SUCCESS) {
    const actualTimeList = makeTimeList(dateListState.data.timeList);
    const displayedValue = currentRange === 'initial' ? dateListState.data.timeList[0] : currentRange;
    const currentIndex = dateListState.data.timeList.findIndex((text: string) => text === displayedValue);

    return (
      <Wrapper>
        <Content>
          <Header>
            <DropdownContainer id="dropdownContainer">
              <IconButton
                skipBlur
                onClick={() => handlePrevRange({ ...dateListState.data, range: currentRange })}
                image={{
                  active: {
                    src: images.datepickerArrowLeft.image,
                    alt: images.datepickerArrowLeft.text,
                  },
                  disabled: {
                    src: images.datepickerArrowLeft.image,
                    alt: images.datepickerArrowLeft.text,
                  },
                }}
                disabled={currentIndex === dateListState.data.timeList.length - 1 || actualTimeList.length === 0}
                ariaLabel={t('common.generalButtons.previous')}
                padding="6px 8px"
              />
              <Dropdown
                accessibilityLabel={t('screens.main.usagePattern.usagePatternScreen.dropdownLabel')}
                items={actualTimeList}
                values={[displayedValue]}
                onChange={(name) => onUpdateRange(name[0] as string)}
                hideBoxShadow
                hideBackground
              />
              <IconButton
                skipBlur
                onClick={() => handleNextRange({ ...dateListState.data, range: currentRange })}
                image={{
                  active: {
                    src: images.datepickerArrowRight.image,
                    alt: images.datepickerArrowRight.text,
                  },
                  disabled: {
                    src: images.datepickerArrowRight.image,
                    alt: images.datepickerArrowRight.text,
                  },
                }}
                disabled={currentIndex <= 0 || actualTimeList.length === 0}
                ariaLabel={t('common.generalButtons.next')}
                padding="6px 8px"
              />
            </DropdownContainer>
            <Tabs
              tabs={[
                {
                  id: 0,
                  title: t('screens.main.usagePattern.usagePatternScreen.graph.filter.day'),
                  tabName: TimePeriod.day,
                },
                {
                  id: 1,
                  title: t('screens.main.usagePattern.usagePatternScreen.graph.filter.week'),
                  tabName: TimePeriod.week,
                },
                {
                  id: 2,
                  title: t('screens.main.usagePattern.usagePatternScreen.graph.filter.month'),
                  tabName: TimePeriod.month,
                },
                {
                  id: 3,
                  title: t('screens.main.usagePattern.usagePatternScreen.graph.filter.year'),
                  tabName: TimePeriod.year,
                },
              ]}
              selected={selectedPeriodFilter}
              onClick={(val) => onPeriodUpdate(val as TimePeriod)}
              hideBorder
            />
            <MobileTitle>{t('screens.main.usagePattern.usagePatternScreen.title')}</MobileTitle>
          </Header>
          {usagePatternState.fetchingStatus === FetchingStatus.SUCCESS && (
            <UsagePatternView
              items={{ ...usagePatternState.data, range: dateListState.data.range }}
              selectedPeriodFilter={selectedPeriodFilter}
            />
          )}
          {usagePatternState.fetchingStatus === FetchingStatus.LOADING && (
            <LoadingWrapper>
              <ReactLoading type="spin" color={colors.primary} height="3%" width="3%" />{' '}
            </LoadingWrapper>
          )}
        </Content>
      </Wrapper>
    );
  }

  return <div />;
};
export default UsagePatternScreenContainer;
