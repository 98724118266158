import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/stores';
import { images, theme } from '@/assets/styles';
import Button from '@/atoms/Button';
import IconButton from '@/atoms/IconButton';
import Img from '@/atoms/Img';
import ModalSupport from '@/organisms/ModalSupport';
import { findRouteName } from '@/helpers/NavigationHelper';
import { isUtilityEdition } from '@/helpers/Edition';
import { IEnrollmentTemplateViewProps } from './index.types';
import {
  Wrapper,
  Container,
  Header,
  Body,
  NavigationPath,
  Left,
  Footer,
  ButtonsContainer,
  Content,
  Row,
  ImageContainer,
  Right,
  Support,
} from './index.styles';

type UtilityLogoType = 'logoSCE' | 'logoSCESquare' | 'logoPGE' | 'logoClimateResponse';

const EnrollmentTemplateView = ({
  children,
  appLogin,
  disabled,
  navigationTitle,
  navigationPath,
  onBack,
  onNext,
  textNext,
  underline,
  onOther,
  textOther,
  showBoxShadow,
  wrapperMargin,
  wrapperMaxWidth,
  buttonsContainerPadding,
  utility,
  hideSupport,
  hideUtilityLogo = false,
  bodyPadding,
  previousNavigationPath,
  showSupport,
  handleShowSupport,
  supportContacts,
  hideBack,
  setShowChat,
  hasChatAccess,
  forceLoading,
}: IEnrollmentTemplateViewProps) => {
  const { t } = useTranslation('common');
  const {
    loading: { loading },
  } = useSelector((state: RootState) => state);

  const shouldDisableButton = disabled || loading || forceLoading;

  const isShowSupport = () => {
    const routeName = findRouteName(window.location);
    if (
      routeName === 'NAV_PROGRAM_ENROLLMENT' ||
      routeName === 'NAV_PROFILE_SETUP' ||
      routeName === 'NAV_LOCATION_SETUP' ||
      routeName === 'NAV_SELECT_UTILITY' ||
      routeName === 'NAV_SELECT_PROVIDER'
    ) {
      return false;
    }
    return !hideSupport;
  };

  useEffect(() => {
    const listener = (event: MessageEvent) => {
      const { data } = event;
      window?.ReactNativeWebView?.postMessage(JSON.stringify(data));
    };
    if (window.ReactNativeWebView) {
      /** android */
      document.addEventListener('message', listener as EventListener);

      /** ios */
      window.addEventListener('message', listener as EventListener);
    }
    return () => {
      document.removeEventListener('message', listener as EventListener);
    };
  }, []);

  const isMobileView = () => {
    return isMobile && window.ReactNativeWebView;
  };

  return (
    <Wrapper maxWidth={wrapperMaxWidth} margin={wrapperMargin} style={{ minHeight: !isMobileView() ? 680 : 0 }}>
      <Container appLogin={appLogin} showBoxShadow={showBoxShadow}>
        {(!hideBack || isShowSupport()) && (
          <Header>
            {!hideBack && (
              <Left>
                <IconButton
                  icon={images.arrowBackBlack}
                  onClick={() => onBack(navigationPath, previousNavigationPath)}
                  ariaLabel={`${t('common.generalButtons.goBack')}`}
                  role="button"
                />
              </Left>
            )}
            {isShowSupport() && (
              <Right onClick={handleShowSupport} aria-label={t('programs.support')}>
                <Img src={images.support} style={{ height: '15px', alignSelf: 'center' }} />
                <Support>{`${t('programs.support')}`}</Support>
              </Right>
            )}
          </Header>
        )}
        <Body padding={bodyPadding} style={{ minHeight: !isMobileView() ? 520 : 0 }}>
          <Row>
            <ImageContainer flex={1}>
              <Img src={images.logoClimateResponse} style={{ height: '50px' }} />
            </ImageContainer>
            {isUtilityEdition && !hideUtilityLogo && (
              <ImageContainer>
                <Img src={images[`logo${utility}` as UtilityLogoType]} style={{ height: '40px' }} />
              </ImageContainer>
            )}
          </Row>
          {navigationTitle && (
            <NavigationPath tabIndex={0} underline={underline}>
              {navigationTitle}
            </NavigationPath>
          )}
          <Content style={{ minHeight: !isMobileView() ? 300 : 0 }}>{children}</Content>
          <Footer>
            <ButtonsContainer padding={buttonsContainerPadding}>
              {textNext && onNext && (
                <Button
                  label={textNext}
                  fontSize={theme.typography.button.fontSize}
                  backgroundColor={shouldDisableButton ? theme.colors.primary[25] : theme.colors.primary[100]}
                  borderRadius={100}
                  borderColor={shouldDisableButton ? theme.colors.primary[25] : theme.colors.primary[100]}
                  disabled={shouldDisableButton}
                  color={theme.colors.text[0]}
                  width="100%"
                  height={46}
                  onClick={onNext}
                  showLoadingCursor={forceLoading || loading}
                  ariaLabel={`${textNext}`}
                  ariaDisabled={shouldDisableButton}
                  role="button"
                />
              )}
              {textOther && onOther && (
                <Button
                  label={textOther}
                  fontSize={theme.typography.button.fontSize}
                  color={theme.colors.primary[100]}
                  transparent
                  width="100%"
                  height={33}
                  marginTop={20}
                  onClick={onOther}
                  ariaLabel={`${textOther}`}
                  role="button"
                />
              )}
            </ButtonsContainer>
          </Footer>
        </Body>
      </Container>
      {showSupport && (
        <ModalSupport
          show={showSupport}
          onClose={handleShowSupport}
          supportContacts={supportContacts}
          setShowChat={setShowChat}
          hasChatAccess={hasChatAccess}
        />
      )}
    </Wrapper>
  );
};

export default EnrollmentTemplateView;
