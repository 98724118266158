export type GeneralHook<T> =
  | { fetchingStatus: FetchingStatus.INITIAL; fetch: () => void }
  | { fetchingStatus: FetchingStatus.LOADING }
  | { fetchingStatus: FetchingStatus.ERROR; error: unknown; refetch: () => void }
  | { fetchingStatus: FetchingStatus.SUCCESS; data: T; refetch: () => void };

export enum FetchingStatus {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  INITIAL = 'INITIAL',
}

// T -> payload
export type PostRequestHook<T, TRes = void> =
  | {
      fetchingStatus: FetchingStatus.INITIAL;
      post: (
        data: T,
        {
          onSuccess,
          onError,
          onSettled,
        }: { onSuccess?: (res: TRes) => void; onError?: (err?: unknown) => void; onSettled?: () => void },
      ) => void;
    }
  | { fetchingStatus: FetchingStatus.LOADING }
  | { fetchingStatus: FetchingStatus.ERROR; error: unknown; post: (data: T) => void }
  | { fetchingStatus: FetchingStatus.SUCCESS; post: (data: T) => void };
