import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Radio from '@/atoms/Radio';
import { EDITION } from '@/constants/environment';
import { LinkEnrollmentChoice } from '@/types/enrollment';
import { IHasExistingEnrollmentViewProps } from './index.types';

const HasExistingEnrollmentView = ({
  nextEnrollmentStage,
  setNextEnrollmentStage,
}: IHasExistingEnrollmentViewProps) => {
  const { t } = useTranslation('common');

  const programLabel = t(`programs.label.${EDITION}`);

  const radioOptions: {
    id: string;
    name: LinkEnrollmentChoice;
    text: string;
  }[] = useMemo(
    () => [
      {
        id: LinkEnrollmentChoice.NewEnrollment,
        name: LinkEnrollmentChoice.NewEnrollment,
        text: t('screens.enrollment.existingCustomerFlowStartScreen.options.newEnrollment', {
          program_label: programLabel,
        }),
      },
      {
        id: LinkEnrollmentChoice.LinkEnrollment,
        name: LinkEnrollmentChoice.LinkEnrollment,
        text: t('screens.enrollment.existingCustomerFlowStartScreen.options.existingEnrollment', {
          program_label: programLabel,
        }),
      },
    ],
    [t],
  );

  return (
    <div>
      <Radio
        items={radioOptions}
        value={nextEnrollmentStage as string}
        onChange={(key) => setNextEnrollmentStage(key as LinkEnrollmentChoice)}
      />
    </div>
  );
};

export default HasExistingEnrollmentView;
