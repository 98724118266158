import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PATHS from '@/types/navigationPaths';
import { RootState } from '@/stores/index';
import contents from '@/assets/config/appInfo';
import { useTranslation } from 'react-i18next';
import Img from '@/atoms/Img';
import { images } from '@/assets/styles';
import { sentEvent } from '@/helpers/GAHelper';
import { useMediaQuery } from 'react-responsive';
import { sizes } from '@/assets/styles/media';
import useMessages from '@/hooks/useMessages';
import { FetchingStatus } from '@/hooks/types';
import ReactLoading from 'react-loading';
import { colors } from '@/designSystem/index';
import { PrimaryButton } from '@/designSystem/components/index';
import {
  Wrapper,
  MessagesWrapper,
  EmptyContainer,
  Empty,
  Container,
  ListItemContainer,
  LogoContainer,
  Description,
  ErrorContainer,
  ErrorTitle,
  ErrorDescription,
  LoadingWrapper,
} from './MessagesScreen.styles';
import MessageItem from './MessageItem/MessageItem';

const MessagesScreen = () => {
  const { t } = useTranslation('common');
  document.title = `${t('screens.main.messages.messagesScreen.title')} - ${contents.name}`;
  const {
    userInfo: {
      data: { userInfo },
    },
  } = useSelector((state: RootState) => state);

  const navigate = useNavigate();
  const isMobileWindowSize = useMediaQuery({ maxWidth: sizes.lg });
  const messagesState = useMessages();
  const [selectedMessageId, setSelectedMessageId] = useState('');

  const handleClick = (messageId: string) => {
    if (isMobileWindowSize) {
      sentEvent('click', PATHS.SELECT_MESSAGE(messageId));
      navigate(`${PATHS.SCREEN_MESSAGE.pathname}/${messageId}`);
      return;
    }
    if (selectedMessageId === messageId) {
      return;
    }

    sentEvent('click', PATHS.SELECT_MESSAGE(messageId));
    setSelectedMessageId(messageId);
  };

  useEffect(() => {
    if (userInfo.isDeleted === true) {
      navigate(PATHS.SCREEN_SETTING_ACCOUNT.pathname);
    }
  });

  if (messagesState.fetchingStatus === FetchingStatus.LOADING) {
    return (
      <Wrapper>
        <LoadingWrapper>
          <ReactLoading type="spin" color={colors.primary} height="5%" width="5%" />
        </LoadingWrapper>
      </Wrapper>
    );
  }

  if (messagesState.fetchingStatus === FetchingStatus.ERROR) {
    return (
      <Wrapper>
        <ErrorContainer>
          <ErrorTitle>{t('screens.errorScreen.data.title')}</ErrorTitle>
          <ErrorDescription>{t('screens.errorScreen.data.description')}</ErrorDescription>
          <PrimaryButton onClick={messagesState.refetch} title={t('common.generalButtons.tryAgain')} />
        </ErrorContainer>
      </Wrapper>
    );
  }

  if (messagesState.fetchingStatus === FetchingStatus.SUCCESS) {
    return (
      <Wrapper>
        <MessagesWrapper>
          {messagesState.data.messages.length === 0 ? (
            <EmptyContainer>
              <LogoContainer>
                <Img src={images.emptyMessages} />
                <Empty>{t('screens.main.messages.messagesScreen.empty')}</Empty>
                <Description>{t('screens.main.messages.messagesScreen.description')}</Description>
              </LogoContainer>
            </EmptyContainer>
          ) : (
            <Container>
              <ListItemContainer>
                {messagesState.data.messages.map((message) => (
                  <MessageItem
                    key={message.id}
                    message={message}
                    selected={message.id === selectedMessageId}
                    onClick={handleClick}
                  />
                ))}
              </ListItemContainer>
            </Container>
          )}
        </MessagesWrapper>
      </Wrapper>
    );
  }

  return <div />;
};

export default MessagesScreen;
