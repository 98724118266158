import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  TMP_PATHS,
  canShowNoThanks,
  findProgram,
  findScreen,
  isNoThanks,
  nextScreenStep,
} from '@/helpers/NavigationHelper';
import { RootState } from '@/stores/index';
import useEnrollment from '@/hooks/useEnrollment';
import contents from '@/assets/config/appInfo';
import { sentEvent } from '@/helpers/GAHelper';
import { Attribute, ProgramName } from '@/types/enrollment';
import useUserInfo from '@/hooks/useUserInfo';
import AvailableProgramScreenView from './index.view';

const AvailableProgramScreenContainer = () => {
  const { t } = useTranslation('common');

  const {
    enrollment: {
      enrollmentInfo,
      enrollmentInfo: { attributes },
      programs: { currentProgram, availablePrograms },
      error: { hasError },
    },
  } = useSelector((state: RootState) => state);

  // Welcome to the Power Saver Rewards Program! - {program name} - ClimateResponse®
  document.title = `${t(`programs.title.${contents.edition}`)} - ${t(
    `common.utilities.${enrollmentInfo.utility}.shortName`,
  )} - ${contents.name}`;

  const [state, setState] = useState({ visibleNoThanks: true, disabledNext: false });

  const noThanksProgramFound = useMemo(
    () => canShowNoThanks(currentProgram.screenStep, { availablePrograms }),
    [currentProgram.screenStep, availablePrograms],
  );

  const [showErrorModal, setShowErrorModal] = useState(false);
  const navigate = useNavigate();
  const { fetchUpdateUserInfo } = useUserInfo();
  const {
    fetchResetGBC,
    fetchGBCResult,
    fetchSubmitTermsAndConditions,
    fetchEnrollmentError,
    fetchChangeProgram,
    fetchAttributeRequest,
  } = useEnrollment();

  useEffect(() => {
    fetchResetGBC();
    fetchGBCResult('');
    fetchAttributeRequest(Attribute.displayNoThanks);
  }, []);

  useEffect(() => {
    validationCheck();
  }, [enrollmentInfo, currentProgram]);

  const validationCheck = () => {
    const disabled = !enrollmentInfo.agreeAvailable;
    const visibleNoThanks = isNoThanks(currentProgram.screenStep);

    setState({ ...state, disabledNext: disabled, visibleNoThanks });
  };

  const handleNext = async () => {
    const nextScreen = nextScreenStep(currentProgram.screenStep, window.location, enrollmentInfo);

    if (currentProgram.program === ProgramName.PGE_ELRP_A6) {
      try {
        await fetchSubmitTermsAndConditions(currentProgram.program);
        sentEvent('terms_and_conditions_submitted');
        navigate(nextScreen);
      } catch (error) {
        console.error('Error submitting terms and conditions', error);
      }
    } else {
      // Currently, savings responses for T&C only supports the PGE ELRP A6 program, so for the others, it moves on to the next screen
      sentEvent('terms_and_conditions_submitted');
      navigate(nextScreen);
    }
  };

  const handleNoThanks = () => {
    sentEvent('terms_and_conditions_no_thanks');
    const currentScreen = findScreen(currentProgram.screenStep);
    const noThanksProgramName = currentScreen.noThanks;

    fetchUpdateUserInfo({ program: noThanksProgramName }, (response) => {
      if (response?.success) {
        const noThanksProgramData = findProgram({ availablePrograms }, noThanksProgramName);
        fetchChangeProgram(noThanksProgramData);
        const screenStep = noThanksProgramData.screenStep[0];
        const nextScreenName = screenStep.name;
        const nextScreenPath = TMP_PATHS[nextScreenName];

        navigate(nextScreenPath);
        return;
      }

      setShowErrorModal(true);
    });
  };

  const handleFailureModal = () => {
    if (showErrorModal) {
      setShowErrorModal(false);
    }
    fetchEnrollmentError();
  };

  return (
    <AvailableProgramScreenView
      enrollmentProgram={currentProgram}
      disabledNext={state.disabledNext}
      isNoThanks={state.visibleNoThanks && (attributes?.displayNoThanks as boolean) && noThanksProgramFound}
      onNext={handleNext}
      onNoThanks={handleNoThanks}
      utility={enrollmentInfo.utility}
      failureModal={hasError || showErrorModal}
      failureModalClose={handleFailureModal}
    />
  );
};

export default AvailableProgramScreenContainer;
