import React from 'react';
import { useTranslation } from 'react-i18next';
import EnrollmentTemplate from '@/templates/EnrollmentTemplate';
import PATHS from '@/types/navigationPaths';
import contents from '@/assets/config/appInfo';
import { useNavigate } from 'react-router-dom';
import { useEnrollment, useUserInfo } from '@/hooks/index';
import { LinkEnrollmentChoice } from '@/types/enrollment';
import { Description } from './VerificationNotEnrolledScreen.styles';

const VerificationNotEnrolledScreen = () => {
  const navigate = useNavigate();
  const { fetchChangeLinkEnrollmentChoice } = useEnrollment();
  const { fetchUpdateUserInfo } = useUserInfo();

  const goBack = () => {
    navigate(PATHS.SCREEN_ENROLLMENT_VERIFICATION.pathname, { replace: true });
  };

  const sendToEnrollmentFlow = () => {
    fetchChangeLinkEnrollmentChoice(LinkEnrollmentChoice.NewEnrollment);
    fetchUpdateUserInfo({ linkEnrollmentChoice: LinkEnrollmentChoice.NewEnrollment });
    navigate(PATHS.SCREEN_ENROLLMENT_PROFILE_SETUP.pathname, { replace: true });
  };

  const { t } = useTranslation('common');

  // Account Verification Not Enrolled
  document.title = `${t('screens.enrollment.verificationNotEnrolledScreen.title')} - ${contents.name}`;

  return (
    <EnrollmentTemplate
      navigationTitle={t('screens.enrollment.verificationNotEnrolledScreen.title')}
      navigationPath={PATHS.SCREEN_ENROLLMENT_VERIFICATION_NOT_ENROLLED.pathname}
      previousNavigationPath={PATHS.SCREEN_ENROLLMENT_VERIFICATION.pathname}
      onBack={goBack}
      onNext={sendToEnrollmentFlow}
      textNext={t('common.generalButtons.enroll')}
      hideUtilityLogo
    >
      <Description>{t('screens.enrollment.verificationNotEnrolledScreen.description')}</Description>
    </EnrollmentTemplate>
  );
};

export default VerificationNotEnrolledScreen;
