import React from 'react';
import { useTranslation } from 'react-i18next';
import Verification from '@/organisms/Verification';
import ModalVerification from '@/organisms/ModalVerification';
import EnrollmentTemplate from '@/templates/EnrollmentTemplate';
import PATHS from '@/types/navigationPaths';
import contents from '@/assets/config/appInfo';
import { IVerificationScreenViewProps, ITemplateParams } from './index.types';

const VerificationScreenView = ({
  enrollmentProgram,
  disabledNext,
  onNext,
  onNoThanks,
  modalVerification,
  modalVerificationClose,
  modalVerificationOk,
  modalVerificationCancel,
  errors,
  utility,
  forceLoading,

  // if no prop is passed for this, then it's a noop
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setErrors = () => {},
}: IVerificationScreenViewProps) => {
  const { t } = useTranslation('common');
  const templateParams = t<string, ITemplateParams>(
    `common:programs.${enrollmentProgram.program}.verificationScreen.templateParams`,
    { returnObjects: true },
  );

  // Account Verification
  document.title = `${
    templateParams.title ?? t('screens.enrollment.verificationScreen.title', { program: enrollmentProgram.program })
  } - ${t(`common.utilities.${utility}.shortName`)} - ${contents.name}`;

  return (
    <>
      <EnrollmentTemplate
        disabled={disabledNext}
        underline
        navigationTitle={
          templateParams.title ??
          t('screens.enrollment.verificationScreen.title', { program: enrollmentProgram.program })
        }
        navigationPath={PATHS.SCREEN_ENROLLMENT_VERIFICATION.pathname}
        previousNavigationPath={PATHS.SCREEN_ENROLLMENT_LOCATION_SETUP.pathname}
        onNext={onNext}
        textNext={t('common.generalButtons.next')}
        onOther={templateParams.showNoThanks ? onNoThanks : undefined}
        textOther={templateParams.showNoThanks ? t('common.generalButtons.noThanks') : ''}
        forceLoading={forceLoading}
      >
        <Verification disabled={disabledNext} onNext={onNext} errors={errors} setErrors={setErrors} />
      </EnrollmentTemplate>
      <ModalVerification
        show={modalVerification}
        onClose={modalVerificationClose}
        onOk={modalVerificationOk}
        onCancel={modalVerificationCancel}
      />
    </>
  );
};

export default VerificationScreenView;
