import { useQuery } from '@tanstack/react-query';
import * as api from '@/apis/enrollment';
import { GeneralHook, FetchingStatus } from './types';

type Response = {
  hasActiveAuthorization: boolean;
  eligibleSiteIds?: Array<string>;
};

const usePreReEnrollmentCheck = (siteId: string): GeneralHook<Response> => {
  const { data, error, isError, isRefetching, isFetching, refetch } = useQuery({
    queryKey: [siteId, 'preReEnrollmentCheck'],
    queryFn: () => api.getPreReEnrollmentCheck(siteId),
    enabled: false,
  });

  if (isRefetching || isFetching) {
    return { fetchingStatus: FetchingStatus.LOADING };
  }

  if (isError) {
    return {
      fetchingStatus: FetchingStatus.ERROR,
      error,
      refetch,
    };
  }

  if (data?.data) {
    return {
      fetchingStatus: FetchingStatus.SUCCESS,
      data: data.data,
      refetch,
    };
  }

  return {
    fetchingStatus: FetchingStatus.INITIAL,
    fetch: refetch,
  };
};

export default usePreReEnrollmentCheck;
