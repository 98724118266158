import React from 'react';
import { useTranslation } from 'react-i18next';
import EnrollmentTemplate from '@/templates/EnrollmentTemplate';
import PATHS from '@/types/navigationPaths';
import contents from '@/assets/config/appInfo';
import { Description } from './VerificationPendingScreen.styles';

const VerificationPendingScreen = () => {
  const { t } = useTranslation('common');

  // Account Verification Pending
  document.title = `${t('screens.enrollment.verificationPendingScreen.title')} - ${contents.name}`;

  return (
    <EnrollmentTemplate
      hideBack
      bodyPadding="30px"
      navigationTitle={t('screens.enrollment.verificationPendingScreen.title')}
      navigationPath={PATHS.SCREEN_ENROLLMENT_VERIFICATION_NOT_ENROLLED.pathname}
      previousNavigationPath={PATHS.SCREEN_ENROLLMENT_VERIFICATION.pathname}
      hideUtilityLogo
    >
      <Description>{t('screens.enrollment.verificationPendingScreen.description')}</Description>
    </EnrollmentTemplate>
  );
};

export default VerificationPendingScreen;
