import { LinkEnrollmentChoice, ProgramName } from './enrollment';

export enum GBCStatus {
  none = 'NONE',
  notStarted = 'NOT_STARTED',
  incomplete = 'INCOMPLETE',
  inProgress = 'IN_PROGRESS',
  stillInProgress = 'STILL_IN_PROGRESS',
  completed = 'COMPLETED',
}

export enum UtilityProviderEnum {
  NONE = '',
  PGE = 'PGE',
  SCE = 'SCE',
  SDGE = 'SDGE',
}

export interface ProgramRecent {
  program: string;
  provider: UtilityProviderEnum;
}

export interface UserInfo {
  id: string;
  isAuthenticated: boolean;

  /**
   * Olivine API AccessToken
   */
  oidcAccessToken: string;

  /**
   * Olivine API LogoutToken
   */
  oidcIdToken: string;

  oidcRefreshToken: string;
  gbcStatus: GBCStatus;
  email: string;
  firstName: string;
  lastName: string;
  isLocationBusiness: boolean;
  zipcode: string;
  phoneNumbers?: string[];
  siteName: string;
  language: string;
  isDeleted: boolean;
  utility: string;
  enrollmentScreen: string;
  program: ProgramName;
  userGbiRedirect: ProgramRecent;
  isPrimary: boolean;
  linkEnrollmentChoice?: LinkEnrollmentChoice;
}

export enum ActivationStatus {
  started = 'started',
  success = 'success',
  error = 'error',
}
