import styled, { css } from 'styled-components';
import { helpers, media } from '@/assets/styles';
import { colors } from '@/designSystem/colors';

export const Wrapper = styled.div`
  padding: 0 16px;

  ${media.lg`
    padding: 0;
  `}
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  background-color: ${colors.white};
  border-radius: 8px;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 70vh;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
  border-radius: 8px;
  margin: 20px;

  ${media.md`
    margin: 15px 0px;
  `}
`;

export const ErrorTitle = styled.p(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: 24, fontWeight: theme.font.weight.bold, align: 'center' })};
    ${helpers.colorSet({ color: theme.colors.text[80] })};
  `,
);

export const ErrorDescription = styled.p(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: 16, align: 'center' })};
    ${helpers.colorSet({ color: theme.colors.text[80] })};

    margin-top: 13px;
    margin-bottom: 30px;
  `,
);
