import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PATHS from '@/types/navigationPaths';
import { sentEvent } from '@/helpers/GAHelper';
import { ProgramName } from '@/types/enrollment';
import contents from '@/assets/config/appInfo';
import { useTranslation } from 'react-i18next';
import {
  BodyWrapper,
  LeftSection,
  Navigator,
  ScreenName,
  SiteName,
  Wrapper,
} from '@/screens/Main/Settings/MySites/ManageSites/ManageSites.styles';
import { images } from '@/assets/styles';
import { LinkButton, PrimaryButton } from '@/designSystem/components/buttons';
import DataOnlyMessage from '@/molecules/DataOnlyMessage';
import ProgramContentsClimateResponse from '@/organisms/ModalProgramInformation/ProgramContents/ProgramContents.climate_response';
import ProgramContentsDefault from '@/organisms/ModalProgramInformation/ProgramContents/ProgramContents.default';
import SingleCheckbox from '@/atoms/SingleCheckbox';
import usePostReEnrollment from '@/hooks/useReEnrollment';
import { FetchingStatus } from '@/hooks/types';
import usePreReEnrollmentCheck from '@/hooks/usePreReEnrollmentCheck';
import ReactLoading from 'react-loading';
import { colors } from '@/designSystem/index';
import { useQueryClient } from '@tanstack/react-query';
import { getProgramContext } from '@/helpers/ProgramStrategy';
import { AgreeBox, LinkText, Terms, Title, TitleContainer } from './ReEnrollmentScreen.styles';
import { ReEnrollmentErrorModal } from '../ReEnrollmentErrorModal';
import { ReEnrollmentSuccessModal } from '../ReEnrollmentSuccessModal';

const ReEnrollmentScreen = () => {
  const { t, i18n } = useTranslation('common');
  const { state: site } = useLocation();
  const queryClient = useQueryClient();
  const reEnrollmentState = usePostReEnrollment();
  const preReEnrollmentCheckState = usePreReEnrollmentCheck(site.id);
  const programContext = getProgramContext({ programName: site.program });
  const shouldCheckGbcAuthorizationBeforeReEnroll = programContext?.shouldCheckGbcAuthStatusBeforeReEnroll();
  const isLoadingPreReEnrollmentCheck = preReEnrollmentCheckState.fetchingStatus === FetchingStatus.LOADING;
  const isLoadingReEnrollment = reEnrollmentState.fetchingStatus === FetchingStatus.LOADING;
  document.title = `${t('screens.main.settings.mySites.reEnrollmentScreen.title')} - ${contents.name}`;

  const navigate = useNavigate();
  const [agreement, setAgreement] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleChangeAgree = () => {
    setAgreement(!agreement);
  };

  const handleLink = (url: string) => {
    if (window.ReactNativeWebView) {
      const data = JSON.stringify({ open: 'browser', message: url });
      window.ReactNativeWebView.postMessage(data);
    } else {
      window.open(url, '_blank');
    }
  };

  const handleBackSetting = () => {
    sentEvent('back_to_settings', PATHS.BACK_TO_SETTINGS);
    navigate(PATHS.SCREEN_SETTING_MY_SITES.pathname);
  };

  useEffect(() => {
    if (!site?.program) {
      handleBackSetting();
    }
  }, [site]);

  const submitWithGbc = () => {
    if (preReEnrollmentCheckState.fetchingStatus === FetchingStatus.INITIAL) {
      preReEnrollmentCheckState.fetch();
    }

    if (preReEnrollmentCheckState.fetchingStatus === FetchingStatus.ERROR) {
      preReEnrollmentCheckState.refetch();
    }
  };

  useEffect(() => {
    return () => queryClient.removeQueries([site.id, 'preReEnrollmentCheck']);
  }, [queryClient]);

  useEffect(() => {
    if (preReEnrollmentCheckState.fetchingStatus === FetchingStatus.SUCCESS) {
      if (
        preReEnrollmentCheckState.data.hasActiveAuthorization &&
        (!preReEnrollmentCheckState.data?.eligibleSiteIds || !preReEnrollmentCheckState.data?.eligibleSiteIds?.length)
      ) {
        onSubmit();
      } else if (
        preReEnrollmentCheckState.data.hasActiveAuthorization &&
        preReEnrollmentCheckState.data.eligibleSiteIds?.length
      ) {
        navigate(PATHS.SCREEN_SETTING_RE_ENROLLMENT_MULTIPLE_SITES, {
          state: { eligibleSiteIds: [...preReEnrollmentCheckState.data.eligibleSiteIds, site.id] },
        });
      } else {
        setShowErrorModal(true);
      }
    }

    if (preReEnrollmentCheckState.fetchingStatus === FetchingStatus.ERROR) {
      setShowErrorModal(true);
    }
  }, [preReEnrollmentCheckState.fetchingStatus]);

  const onSubmit = () => {
    if (
      reEnrollmentState.fetchingStatus === FetchingStatus.INITIAL ||
      reEnrollmentState.fetchingStatus === FetchingStatus.ERROR
    ) {
      const payload = { program: programName, siteId: site.id };
      reEnrollmentState.post(payload, {
        onSuccess: () => {
          setShowSuccessModal(true);
        },
        onError: () => {
          setShowErrorModal(true);
        },
      });
    }
  };

  const programName = site?.program;

  const renderProgramDetail = (programName: ProgramName) => {
    switch (programName) {
      case ProgramName.DATA_ONLY:
        return <DataOnlyMessage isProgramDetails />;
      case ProgramName.CLIMATE_RESPONSE:
        return <ProgramContentsClimateResponse programName={programName} />;
      default:
        return <ProgramContentsDefault programName={programName} />;
    }
  };

  const isTerms = i18n.exists(`common:programs.${programName}.availableProgramScreen.terms`);
  const isLink2 = i18n.exists(`common:programs.${programName}.availableProgramScreen.terms.link2`);
  const isSentence3 = i18n.exists(`common:programs.${programName}.availableProgramScreen.terms.sentence3`);

  return (
    <Wrapper>
      <>
        <Navigator>
          <LeftSection>
            <ScreenName>{t('screens.main.settings.mySites.mySitesScreen.title')}</ScreenName>
            <SiteName>{t('screens.main.settings.mySites.reEnrollmentScreen.title')}</SiteName>
          </LeftSection>
          <LinkButton
            onClick={handleBackSetting}
            image={{
              imagePlace: 'left',
              active: { src: images.greenChevronUp.image, alt: t(images.greenChevronUp.text) },
              disabled: { src: images.greenChevronUp.image, alt: t(images.greenChevronUp.text) },
            }}
            underline={false}
            title={t('screens.main.settings.mySites.mySitesScreen.back')}
            padding="12px 16px"
          />
        </Navigator>
        <BodyWrapper>
          <TitleContainer>
            <Title>{t(`programs.${programName}.title`)}</Title>
          </TitleContainer>
          <Wrapper>{renderProgramDetail(programName)}</Wrapper>
          {isTerms && (
            <AgreeBox>
              <SingleCheckbox
                items={[{ id: 1, name: 1, text: '' }]}
                value={agreement ? 1 : 0}
                onChange={handleChangeAgree}
                checkboxLabel={t(`programs.${programName}.availableProgramScreen.terms.checkboxLabel`)}
              />
              <Terms>
                {t(`programs.${programName}.availableProgramScreen.terms.sentence1`)}
                <LinkText
                  onClick={() => handleLink(t(`programs.${programName}.availableProgramScreen.terms.link`))}
                  role="link"
                  tabIndex={0}
                >
                  {t(`programs.${programName}.availableProgramScreen.terms.sentenceBtn`)}
                </LinkText>
                {t(`programs.${programName}.availableProgramScreen.terms.sentence2`)}
                {isLink2 && (
                  <LinkText
                    onClick={() => handleLink(t(`programs.${programName}.availableProgramScreen.terms.link2`))}
                    role="link"
                    tabIndex={0}
                  >
                    {t(`programs.${programName}.availableProgramScreen.terms.sentenceBtn2`)}
                  </LinkText>
                )}
                {isSentence3 && t(`programs.${programName}.availableProgramScreen.terms.sentence3`)}
              </Terms>
            </AgreeBox>
          )}
          <div style={{ marginTop: 12, alignSelf: 'center' }}>
            {isLoadingPreReEnrollmentCheck || isLoadingReEnrollment ? (
              <ReactLoading type="spin" color={colors.primary} height="35px" width="35px" />
            ) : (
              <PrimaryButton
                disabled={!agreement}
                title={t('screens.main.settings.mySites.reEnrollmentScreen.enrollNow')}
                onClick={shouldCheckGbcAuthorizationBeforeReEnroll ? submitWithGbc : onSubmit}
              />
            )}
          </div>
        </BodyWrapper>
        {showErrorModal && (
          <ReEnrollmentErrorModal
            title={t('screens.main.settings.mySites.reEnrollmentScreen.error.headerTitle')}
            description={t('screens.main.settings.mySites.reEnrollmentScreen.error.description')}
            isVisible={showErrorModal}
            onClose={() => setShowErrorModal(false)}
            buttonText={t('common.generalButtons.close')}
          />
        )}
        {showSuccessModal && (
          <ReEnrollmentSuccessModal
            title={t('screens.main.settings.mySites.reEnrollmentScreen.title')}
            description={t('screens.main.settings.mySites.reEnrollmentSuccessScreen.description', {
              programTitle: t(`programs.${programName}.title`),
            })}
            isVisible={showSuccessModal}
            onClose={() => {
              setShowSuccessModal(false);
              navigate(PATHS.SCREEN_DASHBOARD.pathname);
            }}
            buttonText={t('common.generalButtons.ok')}
          />
        )}
      </>
    </Wrapper>
  );
};

export default ReEnrollmentScreen;
