import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PATHS from '@/types/navigationPaths';
import { sentEvent } from '@/helpers/GAHelper';
import { RootState } from '@/stores/index';
import { ProgramName, EnrollmentStatus } from '@/types/enrollment';
import contents from '@/assets/config/appInfo';
import { useTranslation } from 'react-i18next';
import {
  BodyWrapper,
  ButtonContainer,
  Empty,
  EmptyContainer,
  LeftSection,
  Navigator,
  NoSite,
  PageContainer,
  Right,
  ScreenName,
  SearchContainer,
  SearchWrapper,
  SiteListContainer,
  SiteName,
  Wrapper,
} from '@/screens/Main/Settings/MySites/ManageSites/ManageSites.styles';
import { images } from '@/assets/styles';
import Pagination from '@/screens/Main/Settings/MySites/ManageSites/Pagination';
import { LinkButton } from '@/designSystem/components/buttons';
import { SearchFilter } from '@/designSystem/components/SearchFilter';
import { useMediaQuery } from 'react-responsive';
import { sizes } from '@/assets/styles/media';
import { formatProgramTitle } from '@/helpers/TitleFormatHelper';
import ModalProgramInformation from '@/organisms/ModalProgramInformation';
import { getProgramNameFromSite } from '@/helpers/GetProgramName';
import { SiteItem } from '@/types/sites';
import useSites from '@/hooks/useSites';
import { getProgramContext } from '@/helpers/ProgramStrategy';
import useIntegrations from '@/hooks/useIntegrations';
import { FetchingStatus } from '@/hooks/types';
import { ErrorModal } from '@/molecules/ErrorModal';
import ManageSiteItem from './ManageSiteItem';
import { ReturningFromReAuthModal } from './ReturningFromReAuthModal';
import SitesRefreshModal from '../SitesRefreshModal';

const ManageSitesScreen = () => {
  const { t } = useTranslation('common');
  document.title = `${t('screens.main.settings.mySites.mySitesScreen.title')} - ${contents.name}`;
  const {
    sites: {
      data: { sites, currentSite, gbcIntegration },
      isLoading,
    },
    userInfo: {
      data: { userInfo },
    },
    loading: { loading },
  } = useSelector((state: RootState) => state);

  const navigate = useNavigate();
  const { fetchChangeSite, fetchSites } = useSites();
  const [searchKeywords, setSearchKeywords] = useState<string[]>([]);
  const [refreshSitesModal, showRefreshSitesModal] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const isMobileWindowSize = useMediaQuery({ maxWidth: sizes.lg });
  const [modalProgramInformationVisibility, setModalProgramInformationVisibility] = useState(false);
  const [returningFromReAuthModal, setReturningFromReAuthModal] = useState(false);
  const [returningFromReAuthErrorModal, setReturningFromReAuthErrorModal] = useState(false);
  const [fetchIntegrationError, setFetchIntegrationError] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const isReturnedFromReAuth = params.get('returningFromReAuth') === 'true';
  const isErrorReturnedFromReAuth = window.location.href.includes('?error');

  const searchArrayHandler = (value: string[]) => {
    setSearchKeywords([...value]);
    setPage(1);
  };

  const setPagination = (newPage: number) => {
    if (newPage > 0) {
      if (newPage < page) {
        sentEvent('lower_sites_pages', PATHS.LOWER_SITES_PAGES);
      } else {
        sentEvent('upper_site_pages', PATHS.UPPER_SITES_PAGES);
      }
      setPage(newPage);
    }
  };

  const paginate = (array: SiteItem[], pageSize: number, pageNumber: number) => {
    if (isMobileWindowSize) {
      return array;
    }
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  };

  const filteredSite = (searchKeywords: string[], sites: SiteItem[]) => {
    if (searchKeywords.length === 0) {
      return sites;
    }
    return sites.filter((site) => {
      return searchKeywords.some((keyword) => {
        return (
          site.name.toLowerCase().match(keyword.toLowerCase()) ||
          site.addressStreet1.toLowerCase().match(keyword.toLowerCase())
        );
      });
    });
  };

  const filteredSites = useMemo(() => filteredSite(searchKeywords, sites), [searchKeywords, sites]);

  const onEditSite = (siteId: string) => {
    sentEvent('edit_select_site', PATHS.EDIT_SELECT_SITE(siteId));
    navigate(`${PATHS.SCREEN_SETTING_EDIT_SITE.pathname}/${siteId}`);
  };

  const onChangeSite = (siteId: string) => {
    if (currentSite?.id === siteId) {
      return;
    }

    sentEvent('change_enrolled_site', {
      site_id: siteId,
    });
    fetchChangeSite(siteId);
  };

  const handleBackSetting = () => {
    sentEvent('back_to_settings', PATHS.BACK_TO_SETTINGS);
    navigate(PATHS.SCREEN_SETTING_MY_SITES.pathname);
  };

  const programName = getProgramNameFromSite(currentSite);
  const programContext = getProgramContext({ programName });

  const shouldDisplayRefreshMySites = programContext?.getShouldDisplayRefreshMySites();

  const thereArePendingSites = sites.some((site) => site.enrollmentStatus === EnrollmentStatus.Pending);
  let shouldDisableRefreshMySites = !!gbcIntegration || thereArePendingSites;

  const isDataOnly = programName === ProgramName.DATA_ONLY;
  const programTitle = t(`programs.${programName}.title`);
  const programDetailTitle = formatProgramTitle(
    programTitle,
    t('screens.main.settings.mySites.mySiteDetailScreen.program', { program: programTitle }),
    programName,
  );

  const handleModalProgramInformationClose = () => {
    setModalProgramInformationVisibility(false);
  };
  useEffect(() => {
    if (userInfo.isDeleted === true) {
      navigate(PATHS.SCREEN_SETTING_ACCOUNT.pathname);
    }
  });

  useEffect(() => {
    fetchSites();
  }, []);

  // Call useIntegrations hook to fetch data
  const integrationsState = useIntegrations();

  useEffect(() => {
    if (integrationsState.fetchingStatus === FetchingStatus.SUCCESS) {
      const { loginUrl } = integrationsState.data;
      window.location.href = loginUrl;
    }
    if (integrationsState.fetchingStatus === FetchingStatus.ERROR) {
      // show error modal with try again option
      setFetchIntegrationError(true);
    }
  }, [integrationsState.fetchingStatus]);

  useEffect(() => {
    if (isReturnedFromReAuth) {
      setReturningFromReAuthModal(true);
      shouldDisableRefreshMySites = true;
    }
    if (isErrorReturnedFromReAuth) setReturningFromReAuthErrorModal(true);
    // setFetchIntegrationError(false);
  }, [isReturnedFromReAuth, isErrorReturnedFromReAuth]);

  const handleRefreshSites = () => {
    if (integrationsState.fetchingStatus === FetchingStatus.INITIAL && integrationsState.fetch !== undefined) {
      integrationsState.fetch();
    }
  };

  const handleRefreshSitesTryAgain = () => {
    if (integrationsState.fetchingStatus === FetchingStatus.ERROR && integrationsState.refetch !== undefined) {
      integrationsState.refetch();
    }
  };

  const handleCloseErrorModal = () => {
    setFetchIntegrationError(false);
  };

  return (
    <Wrapper>
      {sites.length === 0 ? (
        <EmptyContainer>
          <Empty>{`${t('screens.main.settings.mySites.mySitesScreen.empty')}`}</Empty>
        </EmptyContainer>
      ) : (
        <>
          <Navigator>
            <LeftSection>
              <ScreenName>{t('screens.main.settings.mySites.mySitesScreen.title')}</ScreenName>
              {sites.length === 0 && !loading ? (
                <NoSite>{t('screens.main.appbar.noSite')}</NoSite>
              ) : (
                !isDataOnly && <SiteName>{programDetailTitle}</SiteName>
              )}
            </LeftSection>
            <LinkButton
              onClick={handleBackSetting}
              image={{
                imagePlace: 'left',
                active: { src: images.greenChevronUp.image, alt: t(images.greenChevronUp.text) },
                disabled: { src: images.greenChevronUp.image, alt: t(images.greenChevronUp.text) },
              }}
              underline={false}
              title={t('screens.main.settings.mySites.mySitesScreen.back')}
              padding="12px 16px"
            />
          </Navigator>
          <BodyWrapper>
            <SearchWrapper>
              <SearchContainer>
                <SearchFilter
                  disabled={false}
                  searchArrayHandler={searchArrayHandler}
                  ariaLabel={t('common.generalButtons.search')}
                  placeholder={t('screens.main.settings.mySites.mySiteDetailScreen.searchFilter.placeholder')}
                />
              </SearchContainer>
              <Right />
            </SearchWrapper>
            <SiteListContainer>
              {paginate(filteredSites, 6, page).map(
                ({ id, name, addressStreet1, program, updated, enrollmentStatus, pendingPrenProgram }) => (
                  <ManageSiteItem
                    key={id}
                    siteId={currentSite?.id}
                    id={id}
                    name={name}
                    addressStreet1={addressStreet1}
                    programName={program}
                    pendingProgramName={pendingPrenProgram || undefined}
                    onEditSite={onEditSite}
                    onChangeSite={onChangeSite}
                    updated={updated}
                    onProgramNameClick={() => setModalProgramInformationVisibility(true)}
                    enrollmentStatus={enrollmentStatus}
                  />
                ),
              )}
            </SiteListContainer>
            {shouldDisplayRefreshMySites && (
              <ButtonContainer>
                <LinkButton
                  title={t('screens.main.settings.mySites.refreshSites.refreshSitesLink')}
                  role="button"
                  onClick={() => showRefreshSitesModal(true)}
                  disabled={shouldDisableRefreshMySites}
                />
              </ButtonContainer>
            )}
            {!isMobileWindowSize && (
              <PageContainer>
                <Pagination setPagination={setPagination} page={page} itemLength={filteredSites.length} />
              </PageContainer>
            )}
          </BodyWrapper>
        </>
      )}
      <SitesRefreshModal
        onClickContinue={handleRefreshSites}
        onClickCancel={() => showRefreshSitesModal(false)}
        onClose={() => showRefreshSitesModal(false)}
        isVisible={refreshSitesModal}
        isLoading={isLoading}
      />
      <ModalProgramInformation
        programName={programName}
        show={modalProgramInformationVisibility}
        onClose={handleModalProgramInformationClose}
        ariaControlsId="program-information-popup"
      />
      <ReturningFromReAuthModal
        title={t('screens.main.settings.mySites.mySitesScreen.reAuthModal.title')}
        description={t('screens.main.settings.mySites.mySitesScreen.reAuthModal.description')}
        onClose={() => setReturningFromReAuthModal(false)}
        show={returningFromReAuthModal}
      />
      <ReturningFromReAuthModal
        title={t('screens.main.settings.mySites.mySitesScreen.reAuthModalError.title')}
        description={t('screens.main.settings.mySites.mySitesScreen.reAuthModalError.description')}
        onClose={() => setReturningFromReAuthErrorModal(false)}
        show={returningFromReAuthErrorModal}
      />
      <ErrorModal
        title={t('screens.errorScreen.data.title')}
        description={t('screens.errorScreen.data.description')}
        onTryAgain={handleRefreshSitesTryAgain}
        show={fetchIntegrationError}
        onClose={handleCloseErrorModal}
        isLoading={isLoading}
      />
    </Wrapper>
  );
};

export default ManageSitesScreen;
