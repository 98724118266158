import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReqUpdateUserInfo } from '@/apis/auth/types';
import { RootState } from '@/stores/index';
import PATHS from '@/types/navigationPaths';
import contents from '@/assets/config/appInfo';
import { useTranslation } from 'react-i18next';
import EnrollmentTemplate from '@/templates/EnrollmentTemplate';
import { useUserInfo } from '@/hooks/index';
import { LinkEnrollmentChoice } from '@/types/enrollment';
import { shouldDisplayLinkEnrollmentFlow } from '@/helpers/ShouldDisplayLinkEnrollmentFlow';
import { Description } from './ProgramEnrollmentScreen.styles';

const ProgramEnrollmentScreen = () => {
  const { t } = useTranslation('common');

  const {
    userInfo: {
      data: { userInfo },
    },
    enrollment: {
      enrollmentInfo: { linkEnrollmentChoice },
    },
  } = useSelector((state: RootState) => state);

  // Program Enrollment
  document.title = `${t('screens.enrollment.programEnrollment.title')} - ${contents.name}`;

  const navigate = useNavigate();
  const { fetchUpdateUserInfo } = useUserInfo();
  const displayLinkEnrollmentFlow = shouldDisplayLinkEnrollmentFlow(userInfo.email);

  const handleNext = () => {
    if (linkEnrollmentChoice === LinkEnrollmentChoice.LinkEnrollment) {
      navigate(PATHS.SCREEN_ENROLLMENT_LOCATION_SETUP.pathname);
    } else {
      navigate(PATHS.SCREEN_ENROLLMENT_PROFILE_SETUP.pathname);
    }
  };

  useEffect(() => {
    // Olivine API를 통한 첫 로그인 시 cca에서도 인증처리를 한다
    if (userInfo.id && !userInfo.isAuthenticated) {
      const payload: ReqUpdateUserInfo = {
        isAuthenticated: true,
      };

      fetchUpdateUserInfo(payload);
    }
  }, []);

  return (
    <EnrollmentTemplate
      navigationTitle={t('screens.enrollment.programEnrollment.title')}
      navigationPath={PATHS.SCREEN_ENROLLMENT_PROGRAM_ENROLLMENT.pathname}
      previousNavigationPath={
        displayLinkEnrollmentFlow ? PATHS.SCREEN_EXISTING_CUSTOMER_FLOW_START_SETUP.pathname : undefined
      }
      onNext={handleNext}
      textNext={t('common.generalButtons.next')}
    >
      <Description dangerouslySetInnerHTML={{ __html: t('screens.enrollment.programEnrollment.description') }} />
    </EnrollmentTemplate>
  );
};

export default ProgramEnrollmentScreen;
