import api, { IResponse } from '@/apis/api';
import { PRIMARY_HOST, EDITION } from '@/constants/environment';
import { Attribute, Program, ProgramName } from '@/types/enrollment';
import { Portfolio } from '@/types/portfolio';
import { EnvironmentalImpactBySeasonResponse } from '@/hooks/useEnvironmentalImpact';
import { Message } from '@/types/message';
import {
  ReqDashboard,
  ReqUsagePattern,
  ReqDR,
  ReqDrEarning,
  ReqMessage,
  ReqEventParticipate,
  ReqEventNotified,
  ReqGridEmissions,
} from './types';

export const getSites = () => {
  const url = `${PRIMARY_HOST}/sites`;

  return api.get({ url });
};

export const getPortfolio = (): Promise<IResponse<Portfolio>> => {
  const url = `${PRIMARY_HOST}/portfolio`;
  const payload: { [k: string]: string } = {
    groupEventsHistoryByYear: 'true',
  };
  return api.get({ url, payload });
};

export const selectSite = (siteId: string) => {
  const url = `${PRIMARY_HOST}/sites/${siteId}?includeProgram=1`;

  return api.get({ url });
};

export const updateSite = (siteId: string, payload: { [k: string]: string }) => {
  const url = `${PRIMARY_HOST}/sites/${siteId}/update`;

  return api.patch({ url, payload });
};

export const selectSitesForEnrollment = (siteIds: Array<string>, gbcIntegrationId: string) => {
  const url = `${PRIMARY_HOST}/sites/selectForEnrollment`;

  return api.post({ url, payload: { siteIds, gbcIntegrationId } });
};

export const deleteSite = (siteId: string) => {
  const url = `${PRIMARY_HOST}/sites/${siteId}/remove`;

  return api.patch({ url });
};

export const getDashboard = ({ siteId }: ReqDashboard) => {
  const url = `${PRIMARY_HOST}/sites/${siteId}/dashboard`;

  return api.get({ url });
};

export const getGridEmissions = ({ siteId }: ReqGridEmissions) => {
  const url = `${PRIMARY_HOST}/sites/${siteId}/gridEmissions`;

  return api.get({ url });
};

export const getUsagePattern = ({ siteId, timePeriod, range }: ReqUsagePattern) => {
  const url = `${PRIMARY_HOST}/sites/${siteId}/usages?timePeriod=${timePeriod}&range=${range}`;

  return api.get({ url });
};

export const getEnvironmentalImpact = ({
  siteId,
  seasonYear,
}: {
  siteId: string;
  seasonYear: number;
}): Promise<IResponse<EnvironmentalImpactBySeasonResponse>> => {
  const url = `${PRIMARY_HOST}/sites/${siteId}/dashboard/impact?seasonYear=${seasonYear}`;

  return api.get({ url });
};

export const getDr = ({ siteId }: ReqDR) => {
  const url = `${PRIMARY_HOST}/sites/${siteId}/dr`;

  return api.get({ url });
};

export const getDrEarnings = ({ siteId }: ReqDrEarning) => {
  const url = `${PRIMARY_HOST}/sites/${siteId}/dr/earnings?groupPerSeason=true`;

  return api.get({ url });
};

export const getDrEvents = ({ siteId }: { siteId: string }) => {
  const url = `${PRIMARY_HOST}/sites/${siteId}/dr/events`;

  return api.get({ url });
};

export const getDrEvent = (siteId: string, eventId: string) => {
  const url = `${PRIMARY_HOST}/sites/${siteId}/dr/event/${eventId}`;

  return api.get({ url });
};

export const postDrEventParticipate = (siteId: string, payload: ReqEventParticipate) => {
  const url = `${PRIMARY_HOST}/sites/${siteId}/dr/request`;

  return api.post({ url, payload });
};

export const getMessages = (): Promise<IResponse<{ messages: Array<Message> }>> => {
  const url = `${PRIMARY_HOST}/messages`;

  return api.get({ url });
};

export const patchMessage = ({ messageId }: ReqMessage) => {
  const url = `${PRIMARY_HOST}/messages/${messageId}`;
  const payload: { [k: string]: string | boolean } = {
    isNewMessage: false,
  };

  return api.patch({ url, payload });
};

export const programs = (zipCode: string) => {
  const url = `${PRIMARY_HOST}/programs/${zipCode}`;

  return api.get({ url });
};

export const attribute = (attribute: Attribute) => {
  const url = `${PRIMARY_HOST}/edition/${EDITION}/${attribute}`;
  return api.get({ url });
};

export const patchEventNotified = (siteId: string, payload: ReqEventNotified) => {
  const url = `${PRIMARY_HOST}/sites/${siteId}/dr/notification`;
  return api.patch({ url, payload });
};

export const submitTermsAndConditions = (programName: ProgramName) => {
  const url = `${PRIMARY_HOST}/misc/submit-terms-and-conditions`;
  return api.post({ url, payload: { program: programName } });
};

export const getAllPrograms = (): Promise<IResponse<{ success: boolean; data: { allPrograms: Array<Program> } }>> => {
  const url = `${PRIMARY_HOST}/programs`;
  return api.get({ url });
};
