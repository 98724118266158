import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';
import styled from 'styled-components';

export const InputWrapper = styled.div`
  margin-top: 38px;
`;

export const Description = styled.p`
  ${typography.md};
  color: ${colors.text};
`;

export const styles = {
  input: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderRadius: 0,
    padding: '0px',
    marginTop: 0,
    height: 40,
  },
  label: {
    fontSize: 16,
    marginBottom: 0,
  },
};
