import { useMutation } from '@tanstack/react-query';
import * as apiEnrollment from '@/apis/enrollment';
import { ReqVerifyLinkEnrollment } from '@/apis/enrollment/types';
import { VerifyLinkEnrollmentResponse } from '@/types/enrollment';
import { IResponse } from '@/apis/api';
import { FetchingStatus, PostRequestHook } from './types';

const usePostEnrolledCustomerVerification = (): PostRequestHook<
  ReqVerifyLinkEnrollment,
  IResponse<VerifyLinkEnrollmentResponse>
> => {
  const { error, isError, mutate, isLoading, isSuccess } = useMutation({
    mutationFn: (payload: ReqVerifyLinkEnrollment) => apiEnrollment.postVerifyLinkEnrollment(payload),
    mutationKey: ['apiEnrollment.postEnrolledCustomerVerification'],
  });

  if (isLoading) {
    return { fetchingStatus: FetchingStatus.LOADING };
  }

  if (isError) {
    return {
      fetchingStatus: FetchingStatus.ERROR,
      error,
      post: mutate,
    };
  }

  if (isSuccess) {
    return {
      fetchingStatus: FetchingStatus.SUCCESS,
      post: mutate,
    };
  }

  return {
    fetchingStatus: FetchingStatus.INITIAL,
    post: mutate,
  };
};

export default usePostEnrolledCustomerVerification;
