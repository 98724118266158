import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '@/molecules/Modal';
import { suffixForCO2Languages } from '@/helpers/Edition';
import { IModalEnergyEventViewProps } from './index.types';
import { Title, Description } from './index.styles';

const ModalEnergyEventView = ({ show, onClose, isCO2 = false }: IModalEnergyEventViewProps) => {
  const { t } = useTranslation('common');
  const suffix = isCO2 ? suffixForCO2Languages : '';

  return (
    <Modal show={show} onClose={onClose}>
      <Title>
        {t(`screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.modalEnergyEvent${suffix}.title`)}
      </Title>
      <Description
        dangerouslySetInnerHTML={{
          __html: t(
            `screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.modalEnergyEvent${suffix}.description`,
          ),
        }}
      />
    </Modal>
  );
};

export default ModalEnergyEventView;
