import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { images } from '@/assets/styles';
import { OTPStatus } from '@/types/phoneOtp';
import PATHS from '@/types/navigationPaths';
import { hyphenVerificationCode } from '@/helpers/RegexHelper';
import { RootState } from '@/stores/index';
import { Input } from '@/designSystem/components';
import { sentEvent } from '@/helpers/GAHelper';
import usePhoneOtp from '@/hooks/usePhoneOtp';
import { FillPrimary, Stroke, IconButton } from '@/designSystem/components/buttons';
import {
  Container,
  Title,
  Description,
  InputContainer,
  ResentText,
  ButtonContainer,
  VerifiedMessageContainer,
  VerifiedImage,
  VerifiedMessageText,
  Wrapper,
  HeadNavigation,
  NavigationTitle,
  Button,
} from './SMSPhoneVerificationScreen.styles';

export interface FormValues {
  verificationCode?: string;
}

const SMSPhoneVerificationScreen = ({ ariaControlsId }: { ariaControlsId: string }) => {
  const { fetchPhoneOtpResend, fetchPhoneOtpVerification, fetchChangePhoneOtp, fetchUnsetOldAndNewPhoneNumbers } =
    usePhoneOtp();

  const { t } = useTranslation('common');

  const {
    phoneOtp: {
      data: { phoneStatus, oldPhoneNumber, newPhoneNumber },
    },
    userInfo: {
      data: {
        userInfo: { phoneNumbers },
      },
    },
  } = useSelector((state: RootState) => state);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid, isSubmitted, isSubmitSuccessful },
    setError,
  } = useForm<FormValues>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    defaultValues: {
      verificationCode: '',
    },
  });

  useEffect(() => {
    if (phoneStatus === OTPStatus.notVerified && isSubmitted) {
      setError('verificationCode', {
        type: 'error',
        message: t('screens.main.settings.account.smsPhoneVerificationScreen.codeInput.errorMessage'),
      });
      reset({}, { keepErrors: true, keepIsValid: true, keepValues: true });
    } else if (phoneStatus === OTPStatus.verified && isSubmitSuccessful) {
      fetchUnsetOldAndNewPhoneNumbers();
    }
  }, [phoneStatus]);

  const handleResend = () => {
    setError('verificationCode', {
      type: 'error',
      message: '',
    });
    fetchPhoneOtpResend({ phoneNumber: newPhoneNumber });
    fetchChangePhoneOtp(OTPStatus.resent);
    reset({ verificationCode: '' });
    sentEvent('click', PATHS.RESEND_CODE);
  };

  const onVerfiy = (data: { verificationCode?: string }) => {
    const unMaskedCode = data.verificationCode?.replaceAll(/[' '-]/g, '') || '';
    const payload = { otp: unMaskedCode };
    const currentPhoneNumbers = phoneNumbers?.filter((phoneNumber) => phoneNumber !== oldPhoneNumber);
    fetchChangePhoneOtp(OTPStatus.verification);
    fetchPhoneOtpVerification([...(currentPhoneNumbers ?? []), newPhoneNumber], payload);
    sentEvent('click', PATHS.VERIFY_CODE);
  };

  const handleCloseVerifyModal = () => {
    reset({ verificationCode: '' });
    fetchChangePhoneOtp(OTPStatus.none);
  };
  const handleAddPhoneNumber = () => {
    fetchChangePhoneOtp(OTPStatus.request);
  };

  return (
    <Wrapper>
      <HeadNavigation>
        <IconButton
          onClick={handleAddPhoneNumber}
          image={{
            active: {
              src: images.headNavigationBack.image,
              alt: images.headNavigationBack.text,
            },
            disabled: {
              src: images.headNavigationBack.image,
              alt: images.headNavigationBack.text,
            },
          }}
          padding="4px"
        />
        <NavigationTitle>{`${t(
          'screens.main.settings.account.smsPhoneVerificationScreen.screenTitle',
        )}`}</NavigationTitle>
        <IconButton
          onClick={handleCloseVerifyModal}
          image={{
            active: {
              src: images.headNavigationClose.image,
              alt: images.headNavigationClose.text,
            },
            disabled: {
              src: images.headNavigationClose.image,
              alt: images.headNavigationClose.text,
            },
          }}
          padding="4px"
        />
      </HeadNavigation>
      <Container>
        <Title>{`${t('screens.main.settings.account.smsPhoneVerificationScreen.screenTitle')}`}</Title>
        <Description>{`${t('screens.main.settings.account.smsPhoneVerificationScreen.hintDescription')}`}</Description>
        <InputContainer>
          <Input
            type="text"
            rules={{
              required: true,
            }}
            minLength={9}
            maxLength={9}
            formatEntry={hyphenVerificationCode}
            name="verificationCode"
            label={t('screens.main.settings.account.smsPhoneVerificationScreen.codeInput.label')}
            control={control}
            error={errors.verificationCode?.message}
            placeholder={t('screens.main.settings.account.smsPhoneVerificationScreen.codeInput.placeholder')}
            ariaLabel={ariaControlsId}
            disabled={isSubmitSuccessful}
          />
        </InputContainer>
        <Description>{`${t(
          'screens.main.settings.account.smsPhoneVerificationScreen.checkCarrierDescription',
        )}`}</Description>
        {phoneStatus === OTPStatus.verified ? (
          <>
            <VerifiedMessageContainer>
              <VerifiedImage src={images.iconCheckRounded32px.image} alt={images.iconCheckRounded32px.text} />
              <VerifiedMessageText>
                {`${t('screens.main.settings.account.smsPhoneVerificationScreen.smsVerified.title')}`}
              </VerifiedMessageText>
            </VerifiedMessageContainer>
            <ButtonContainer>
              <Button>
                <Stroke
                  onClick={handleCloseVerifyModal}
                  title={t('screens.main.settings.account.smsPhoneVerificationScreen.smsVerified.backToAccount')}
                  padding="12px 48px"
                />
              </Button>
            </ButtonContainer>
          </>
        ) : (
          <>
            <ButtonContainer>
              <Button>
                <Stroke
                  onClick={handleResend}
                  title={t('screens.main.settings.account.smsPhoneVerificationScreen.resendCodeButton.title')}
                  padding="12px 0"
                  flex={1}
                />
              </Button>
              <Button>
                <FillPrimary
                  title={t('screens.main.settings.account.smsPhoneVerificationScreen.verifyButton.title')}
                  onClick={handleSubmit(onVerfiy)}
                  disabled={!isValid}
                  padding="12px 0"
                />
              </Button>
            </ButtonContainer>
            {phoneStatus === OTPStatus.resent && (
              <ResentText phoneStatus={phoneStatus}>
                {`${t('screens.main.settings.account.smsPhoneVerificationScreen.resendMessage.success')}`}
              </ResentText>
            )}
            {phoneStatus === OTPStatus.resendError && (
              <ResentText phoneStatus={phoneStatus}>
                {`${t('screens.main.settings.account.smsPhoneVerificationScreen.resendMessage.error')}`}
              </ResentText>
            )}
          </>
        )}
      </Container>
    </Wrapper>
  );
};

export default SMSPhoneVerificationScreen;
