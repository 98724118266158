import api, { IResponse } from '@/apis/api';
import { OLIVINE_AUTH_HOST, GB_INTERFACE_HOST } from '@/constants/environment';
import { SurveyAnswer } from '@/types/enrollment';
import {
  ReqIntegrations,
  ReqVerify,
  ReqVerifyPge,
  ReqPhoneOtpVerification,
  ReqPhoneOtpRequest,
  ReqVerifyLinkEnrollment,
} from './types';

export const postIntegrations = (payload: ReqIntegrations) => {
  const url = `${GB_INTERFACE_HOST}/integrations`;

  return api.post({ url, payload });
};

export const patchIntegrations = (): Promise<IResponse<{ loginUrl: string }>> => {
  const url = `${GB_INTERFACE_HOST}/integrations`;

  return api.patch({ url });
};

export const postVerify = (payload: ReqVerify) => {
  const url = `${GB_INTERFACE_HOST}/olivine/verify`;

  return api.post({ url, payload });
};

export const postVerifyPge = (payload: ReqVerifyPge) => {
  // includeSites=true only works with pge for now since it's the only flow that
  // creates sites during verify
  const url = `${GB_INTERFACE_HOST}/olivine/verify?includeSites=true`;

  return api.post({ url, payload });
};

export const postVerifyLinkEnrollment = (payload: ReqVerifyLinkEnrollment) => {
  const url = `${GB_INTERFACE_HOST}/service-accounts/link/verify`;

  return api.post({ url, payload });
};

export const postConfirmLinkEnrollment = () => {
  const url = `${GB_INTERFACE_HOST}/service-accounts/link/confirm`;

  return api.post({ url });
};

export const postSurvey = (payload: SurveyAnswer[]) => {
  const url = `${OLIVINE_AUTH_HOST}/users/survey`;

  return api.post({ url, payload });
};

export const recent = () => {
  const url = `${GB_INTERFACE_HOST}/redirects/recent`;

  return api.get({ url });
};

export const postPhoneOtpRequest = (payload: ReqPhoneOtpRequest) => {
  const url = `${GB_INTERFACE_HOST}/olivine/otprequest`;
  return api.post({ url, payload });
};

export const postPhoneOtpVerification = (payload: ReqPhoneOtpVerification) => {
  const url = `${GB_INTERFACE_HOST}/olivine/otpverify`;

  return api.post({ url, payload });
};

export const postActivateInvitedUser = () => {
  const url = `${GB_INTERFACE_HOST}/olivine/activate-invited-user`;

  return api.post({ url });
};

export const getUserActivationStatus = () => {
  const url = `${GB_INTERFACE_HOST}/olivine/user-activation-status`;

  return api.get({ url });
};

export const getPreReEnrollmentCheck = (siteId: string) => {
  const url = `${GB_INTERFACE_HOST}/olivine/${siteId}/pre-re-enrollment-check`;

  return api.get({ url });
};
