import { useQuery } from '@tanstack/react-query';
import * as apiPrimary from '@/apis/primary';
import { GridEmissions as GridEmissionsResponse } from '@/types/gridEmissions';
import { GeneralHook, FetchingStatus } from './types';

const useGridEmissions = (siteId: string): GeneralHook<GridEmissionsResponse> => {
  const { data, error, isError, isLoading, isRefetching, isFetching, refetch } = useQuery({
    queryKey: [siteId, 'gridEmissions'],
    queryFn: () => apiPrimary.getGridEmissions({ siteId }),
    enabled: !!siteId,
  });

  if (isLoading || isRefetching || isFetching) {
    return { fetchingStatus: FetchingStatus.LOADING };
  }

  if (isError) {
    return {
      fetchingStatus: FetchingStatus.ERROR,
      error,
      refetch,
    };
  }

  if (data?.data) {
    return {
      fetchingStatus: FetchingStatus.SUCCESS,
      data: data.data.gridEmissions,
      refetch,
    };
  }

  return {
    fetchingStatus: FetchingStatus.INITIAL,
    fetch: refetch,
  };
};

export default useGridEmissions;
