import React, { AriaRole } from 'react';
import { Button, Img } from './IconButton.styles';

type IconButtonProps = {
  title?: string;
  onClick?: () => void;
  disabled?: boolean;
  padding?: string;
  image?: {
    active: {
      src: string;
      alt: string;
    };
    disabled?: {
      src: string;
      alt: string;
    };
    width?: string;
    height?: string;
  };
  warning?: boolean;
  ariaLabel?: string;
  gap?: string;
  reverse?: boolean;
  role?: AriaRole;
  flex?: number | 'unset';
  ariaControls?: string;
  ariaExpanded?: boolean;
  borderRadius?: string;
  skipBlur?: boolean;
};

export const IconButton = ({
  title,
  onClick,
  disabled,
  image,
  padding,
  warning,
  ariaLabel,
  gap,
  reverse,
  role = 'button',
  flex,
  ariaControls,
  ariaExpanded,
  borderRadius,
  skipBlur,
}: IconButtonProps) => {
  return (
    <Button
      tabIndex={0}
      onClick={(e) => {
        if (onClick) {
          onClick();
        }
        if (skipBlur) {
          (e.target as HTMLButtonElement).focus();
        } else {
          (e.target as HTMLButtonElement).blur();
        }
      }}
      aria-label={ariaLabel}
      disabled={disabled}
      padding={padding}
      warning={warning}
      gap={gap}
      reverse={reverse}
      role={role}
      flex={flex}
      aria-controls={ariaControls}
      aria-expanded={ariaExpanded}
      borderRadius={borderRadius}
    >
      {title}
      {image && (
        <Img
          width={image.width}
          height={image.height}
          src={disabled ? image.disabled?.src : image.active.src}
          alt={disabled ? image.disabled?.alt : image.active.alt}
        />
      )}
    </Button>
  );
};
