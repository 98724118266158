import React from 'react';
import { useTranslation } from 'react-i18next';
import EnrollmentTemplate from '@/templates/EnrollmentTemplate';
import PATHS from '@/types/navigationPaths';
import contents from '@/assets/config/appInfo';
import { useNavigate } from 'react-router-dom';
import { ConfirmLinkEnrollmentResponse, VerifyLinkEnrollmentResponseStatus } from '@/types/enrollment';
import useConfirmLinkEnrollment from '@/hooks/useConfirmLinkEnrollment';
import { FetchingStatus } from '@/hooks/types';
import useLoading from '@/hooks/useLoading';
import { GBCStatus } from '@/types/userInfo';
import { useUserInfo } from '@/hooks/index';
import { Description, Margin } from './VerificationFoundScreen.styles';

const VerificationFoundScreen = () => {
  const navigate = useNavigate();

  const confirmLinkEnrollment = useConfirmLinkEnrollment();
  const { fetchUpdateUserInfo } = useUserInfo();
  const { fetchLoading } = useLoading();

  const { t } = useTranslation('common');

  const goBack = () => {
    navigate(PATHS.SCREEN_ENROLLMENT_VERIFICATION.pathname, { replace: true });
  };

  const handleConfirmLinkAccountResponse = (data: ConfirmLinkEnrollmentResponse) => {
    if (data.result === VerifyLinkEnrollmentResponseStatus.ImportedEnrollments) {
      if (window.ReactNativeWebView) {
        const data = JSON.stringify({ to: 'dashboard', gbcStatus: GBCStatus.completed });
        window.ReactNativeWebView.postMessage(data);
      }

      fetchUpdateUserInfo(
        {
          enrollmentScreen: 'NAV_SINGUP_COMPLETE',
        },
        (response) => {
          if (response?.success) {
            navigate(PATHS.SCREEN_DASHBOARD.pathname);
          } else {
            navigate(PATHS.SCREEN_ENROLLMENT_VERIFICATION_FAILED.pathname);
          }
        },
      );
    } else {
      navigate(PATHS.SCREEN_ENROLLMENT_VERIFICATION_FAILED.pathname);
    }
  };

  const onNext = () => {
    if (window.ReactNativeWebView) {
      const data = JSON.stringify({ loading: true });
      window.ReactNativeWebView.postMessage(data);
    }

    if (confirmLinkEnrollment.fetchingStatus !== FetchingStatus.LOADING) {
      fetchLoading(true);
      confirmLinkEnrollment.post(undefined, {
        onSuccess: (data) =>
          data.data
            ? handleConfirmLinkAccountResponse(data.data)
            : navigate(PATHS.SCREEN_ENROLLMENT_VERIFICATION_FAILED.pathname),
        onError: () => navigate(PATHS.SCREEN_ENROLLMENT_VERIFICATION_FAILED.pathname),
        onSettled: () => {
          if (window.ReactNativeWebView) {
            const data = JSON.stringify({ loading: false });
            window.ReactNativeWebView.postMessage(data);
          }
          fetchLoading(false);
        },
      });
    }
  };

  // Account Verification
  document.title = `${t('screens.enrollment.verificationFoundScreen.title')} - ${contents.name}`;

  return (
    <EnrollmentTemplate
      navigationTitle={t('screens.enrollment.verificationFoundScreen.title')}
      navigationPath={PATHS.SCREEN_ENROLLMENT_VERIFICATION_FOUND.pathname}
      previousNavigationPath={PATHS.SCREEN_ENROLLMENT_VERIFICATION.pathname}
      onBack={goBack}
      onNext={onNext}
      textNext={t('common.generalButtons.confirm')}
      hideUtilityLogo
      showSupport={false}
      hideSupport
    >
      <Description>{t('screens.enrollment.verificationFoundScreen.description')}</Description>
      <Margin>
        <Description>{t('screens.enrollment.verificationFoundScreen.descriptionBelow')}</Description>
      </Margin>
    </EnrollmentTemplate>
  );
};

export default VerificationFoundScreen;
