import { useQuery } from '@tanstack/react-query';
import * as apiPrimary from '@/apis/primary';
import { Dashboard } from '@/types/dashboard';
import { GeneralHook, FetchingStatus } from './types';

const useDashboard = ({ siteId }: { siteId: string }): GeneralHook<Dashboard> => {
  const { data, error, isError, isInitialLoading, isRefetching, isFetching, refetch } = useQuery({
    queryFn: () => apiPrimary.getDashboard({ siteId }),
    queryKey: ['apiPrimary.getDashboard'],
    enabled: false,
  });

  if (isInitialLoading || isRefetching || isFetching) {
    return { fetchingStatus: FetchingStatus.LOADING };
  }

  if (isError) {
    return {
      fetchingStatus: FetchingStatus.ERROR,
      error,
      refetch,
    };
  }

  if (data?.data) {
    return {
      fetchingStatus: FetchingStatus.SUCCESS,
      data: data.data,
      refetch,
    };
  }

  return {
    fetchingStatus: FetchingStatus.INITIAL,
    fetch: refetch,
  };
};

export default useDashboard;
