import moment from 'moment';
import { TimePeriod } from '@/types/usagePattern';

export const getMinMaxDescription = ({
  timestamp,
  format,
  upperCase = true,
}: {
  timestamp: string;
  format: string;
  upperCase?: boolean;
}) => {
  const date = moment.utc(timestamp);
  const formattedDate = date.format(format);
  if (upperCase) {
    return formattedDate.toUpperCase();
  }
  return formattedDate;
};

export const momentFormatByPeriod = {
  [TimePeriod.day]: 'h a',
  [TimePeriod.week]: 'ddd',
  [TimePeriod.month]: 'M/D',
  [TimePeriod.year]: 'MMM',
};
