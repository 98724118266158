import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '@/designSystem/components';
import EnrollmentTemplate from '@/templates/EnrollmentTemplate';
import PATHS from '@/types/navigationPaths';
import contents from '@/assets/config/appInfo';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import usePostEnrolledCustomerVerification from '@/hooks/usePostEnrolledCustomerVerification';
import { FetchingStatus } from '@/hooks/types';
import useOnVerifyResponse from '@/hooks/enrollment/useOnVerifyResponse';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import { ProgramName, UtilityProviderEnum } from '@/types/enrollment';
import Img from '@/atoms/Img';
import { images } from '@/assets/styles';
import Modal from '@/molecules/Modal';
import IconButton from '@/atoms/IconButton';
import { Description, InputWrapper, styles } from './index.styles';

export interface FormData extends FieldValues {
  serviceFirstName: string;
  serviceLastName: string;
  serviceAccountNumber: string;
  serviceAddress: string;
  serviceCity: string;
}

const validationPatternByUtility = (utility: UtilityProviderEnum) => {
  if (utility === UtilityProviderEnum.SCE) {
    return /^8\d{9}$/;
  }

  if (utility === UtilityProviderEnum.SDGE) {
    return /^(0\d{9}|[1-9]\d{6})$/;
  }

  if (utility === UtilityProviderEnum.PGE) {
    return /^\d{10}$/;
  }

  return undefined;
};

const VerificationScreenView = () => {
  const {
    enrollment: {
      enrollmentInfo: { zipCode, utility },
      programs: {
        currentProgram: { accountMaxLength, accountMinLength },
      },
    },
  } = useSelector((state: RootState) => state);
  const { t } = useTranslation('common');

  // Account Verification Olivine
  document.title = `${t('screens.enrollment.verificationScreen.olivine.title')} - ${contents.name}`;
  const [displayAccountNumberModal, setDisplayAccountNumberModal] = useState(false);
  const enrolledCustomerVerificationState = usePostEnrolledCustomerVerification();
  const onVerifyResponse = useOnVerifyResponse();
  const shouldDisplayAccountNumberModal = utility === UtilityProviderEnum.SCE;
  const displayAccountNumberDescription = [UtilityProviderEnum.SDGE, UtilityProviderEnum.PGE].includes(
    utility as UtilityProviderEnum,
  );

  const changeAccountNumberModalVisibility = () => setDisplayAccountNumberModal(!displayAccountNumberModal);

  const onNext: SubmitHandler<FormData> = (payload: FormData) => {
    if (enrolledCustomerVerificationState.fetchingStatus === FetchingStatus.INITIAL) {
      enrolledCustomerVerificationState.post(
        { ...payload, serviceZipCode: zipCode },
        {
          ...onVerifyResponse,
          onSettled: () => {
            if (window.ReactNativeWebView) {
              const data = JSON.stringify({ loading: false });
              window.ReactNativeWebView.postMessage(data);
            }
          },
        },
      );
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormData>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    defaultValues: {
      serviceFirstName: '',
      serviceLastName: '',
      serviceAccountNumber: '',
      serviceAddress: '',
      serviceCity: '',
    },
  });

  return (
    <EnrollmentTemplate
      navigationTitle={t('screens.enrollment.verificationScreen.olivine.title')}
      navigationPath={PATHS.SCREEN_ENROLLMENT_VERIFICATION.pathname}
      previousNavigationPath={PATHS.SCREEN_ENROLLMENT_LOCATION_SETUP.pathname}
      onNext={handleSubmit(onNext)}
      textNext={t('common.generalButtons.next')}
      disabled={!isValid}
      hideUtilityLogo
      forceLoading={enrolledCustomerVerificationState.fetchingStatus === FetchingStatus.LOADING}
    >
      <Description tabIndex={0}>{t('screens.enrollment.verificationScreen.olivine.description')}</Description>
      <InputWrapper>
        <Input
          inputStyle={styles.input}
          labelStyle={styles.label}
          type="text"
          rules={{
            required: true,
            maxLength: 50,
          }}
          name="serviceFirstName"
          control={control}
          label={t('screens.enrollment.verificationScreen.olivine.fields.firstName')}
          disabled={false}
          error={errors.serviceFirstName?.message}
          placeholder=""
          ariaLabel={t('screens.enrollment.verificationScreen.olivine.fields.firstName')}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          inputStyle={styles.input}
          labelStyle={styles.label}
          type="text"
          rules={{
            required: true,
            maxLength: 50,
          }}
          name="serviceLastName"
          control={control}
          label={t('screens.enrollment.verificationScreen.olivine.fields.lastName')}
          disabled={false}
          error={errors.serviceLastName?.message}
          placeholder=""
          ariaLabel={t('screens.enrollment.verificationScreen.olivine.fields.lastName')}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          inputStyle={styles.input}
          labelStyle={styles.label}
          type="text"
          rules={{
            required: true,
            pattern: validationPatternByUtility(utility as UtilityProviderEnum),
          }}
          maxLength={accountMaxLength}
          minLength={accountMinLength}
          formatEntry={(value) => (Number(value) || value.startsWith('0') ? value : value.slice(0, -1))}
          name="serviceAccountNumber"
          control={control}
          label={t('screens.enrollment.verificationScreen.olivine.fields.serviceAccount')}
          disabled={false}
          error={errors.serviceAccountNumber?.message}
          placeholder=""
          ariaLabel={t('screens.enrollment.verificationScreen.olivine.fields.serviceAccount')}
        />
        {shouldDisplayAccountNumberModal && (
          <IconButton
            icon={images.help}
            onClick={changeAccountNumberModalVisibility}
            ariaLabel={t('screens.main.dashboard.dashboardScreen.achievement.help')}
          />
        )}
      </InputWrapper>
      {utility === UtilityProviderEnum.SCE && errors?.serviceAccountNumber?.type === 'pattern' && (
        <p style={styles.errorDescription}>
          {t('common.validations.sceAccountNumberError.message', { accountMaxLength })}
        </p>
      )}
      {utility === UtilityProviderEnum.SDGE && errors?.serviceAccountNumber?.type === 'pattern' && (
        <p style={styles.errorDescription}>{t('common.validations.sdgeAccountNumber.error')}</p>
      )}
      {displayAccountNumberDescription && (
        <p style={styles.inputDescription}>
          {t(`common.validations.${utility.toLocaleLowerCase()}AccountNumber.description`)}
        </p>
      )}
      <InputWrapper>
        <Input
          inputStyle={styles.input}
          labelStyle={styles.label}
          type="text"
          rules={{
            required: true,
            maxLength: 50,
          }}
          name="serviceAddress"
          control={control}
          label={t('screens.enrollment.verificationScreen.olivine.fields.address')}
          disabled={false}
          error={errors.serviceAddress?.message}
          placeholder=""
          ariaLabel={t('screens.enrollment.verificationScreen.olivine.fields.address')}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          inputStyle={styles.input}
          labelStyle={styles.label}
          type="text"
          rules={{
            required: true,
            maxLength: 50,
          }}
          name="serviceCity"
          control={control}
          label={t('screens.enrollment.verificationScreen.olivine.fields.city')}
          disabled={false}
          error={errors.serviceCity?.message}
          placeholder=""
          ariaLabel={t('screens.enrollment.verificationScreen.olivine.fields.city')}
        />
      </InputWrapper>
      {shouldDisplayAccountNumberModal && displayAccountNumberModal && (
        <Modal
          show={displayAccountNumberModal}
          title={t(`programs.${ProgramName.SCE_ELRP_A6}.verificationScreen.accountNumberModal.title`)}
          onClose={changeAccountNumberModalVisibility}
          onOk={changeAccountNumberModalVisibility}
          textOk={t('common.generalButtons.close')}
        >
          <Img style={{ height: 300, width: '100%', marginBottom: 16 }} src={images.accountNumberImg.image} />
        </Modal>
      )}
    </EnrollmentTemplate>
  );
};
export default VerificationScreenView;
