import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PATHS from '@/types/navigationPaths';
import { RootState } from '@/stores/index';
import contents from '@/assets/config/appInfo';
import { useTranslation } from 'react-i18next';
import TimeAgo from 'react-timeago';
import { Message } from '@/types/message';
import { useMediaQuery } from 'react-responsive';
import { sizes } from '@/assets/styles/media';
import { timeagoFormatter } from '@/helpers/LanguageHelper';
import useMessages from '@/hooks/useMessages';
import { FetchingStatus } from '@/hooks/types';
import { Wrapper, MessageWrapper, Container, Title, DateText, Contents } from './MessagesScreen.styles';

const getMessage = (messages: Message[], messageId?: string) => {
  return messages.find((message) => message.id === messageId);
};

const MessageDetailScreen = () => {
  const { t } = useTranslation('common');
  document.title = `${t('screens.main.messages.messagesDetailScreen.title')} - ${contents.name}`;
  const {
    userInfo: {
      data: { userInfo },
    },
    setting: {
      data: { language },
    },
  } = useSelector((state: RootState) => state);
  const navigate = useNavigate();
  const { messageId } = useParams();
  const messagesState = useMessages();
  const isMobileWindowSize = useMediaQuery({ maxWidth: sizes.lg });

  useEffect(() => {
    if (userInfo.isDeleted === true) {
      navigate(PATHS.SCREEN_SETTING_ACCOUNT.pathname);
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    if (!isMobileWindowSize) {
      navigate(PATHS.SCREEN_MESSAGE.pathname);
    }
  }, [isMobileWindowSize]);

  if (messagesState.fetchingStatus === FetchingStatus.SUCCESS) {
    const message = getMessage(messagesState.data.messages, messageId);

    return (
      <Wrapper>
        <MessageWrapper>
          <Container>
            <Title>{message?.name}</Title>
            <DateText>
              <TimeAgo date={message?.time || ''} formatter={timeagoFormatter(language)} />
            </DateText>
            <Contents>{message?.content}</Contents>
          </Container>
        </MessageWrapper>
      </Wrapper>
    );
  }

  return <div />;
};

export default MessageDetailScreen;
