import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import { confirmAlert } from 'react-confirm-alert';
import WelcomeScreen from '@/screens/Auth/WelcomeScreen';
import InvitationLanding from '@/screens/Auth/InvitationLandingScreen/InvitationLanding';
import AppLoginScreen from '@/screens/Auth/AppLoginScreen';
import RedirectScreen from '@/screens/Auth/RedirectScreen';
import ProfileSetupScreen from '@/screens/Enrollment/ProfileSetupScreen';
import LocationSetupScreen from '@/screens/Enrollment/LocationSetupScreen';
import SelectUtilityScreen from '@/screens/Enrollment/SelectUtilityScreen';
import FindProgramScreen from '@/screens/Enrollment/FindProgramScreen';
import VerificationScreen from '@/screens/Enrollment/VerificationScreen';
import NotVerifiedScreen from '@/screens/Enrollment/NotVerifiedScreen';
import AvailableProgramScreen from '@/screens/Enrollment/AvailableProgramScreen';
import NoThanksScreen from '@/screens/Enrollment/NoThanksScreen';
import ConnectToUtilityScreen from '@/screens/Enrollment/ConnectToUtilityScreen';
import MultipleSurveyScreen from '@/screens/Enrollment/MultipleSurveyScreen';
import SingleSurveyScreen from '@/screens/Enrollment/SingleSurveyScreen';
import ProvisionalProgramsScreen from '@/screens/Enrollment/ProvisionalProgramsScreen';
import SingUpCompletedScreenContainer from '@/screens/Enrollment/SignUpCompleted';
import ProgramEnrollmentScreen from '@/screens/Enrollment/ProgramEnrollmentScreen';

import DashboardScreen from '@/screens/Main/Dashboard';
import UsagePatternScreen from '@/screens/Main/UsagePattern/UsagePatternScreen';
import SavingsEventsScreen from '@/screens/Main/SavingsEvents/SavingsEventsScreen';
import MessagesScreen from '@/screens/Main/Messages/MessagesScreen';
import AccountScreen from '@/screens/Main/Settings/Account/AccountScreen';
import AboutScreen from '@/screens/Main/Settings/AboutScreen';
import ReferralScreen from '@/screens/Main/Settings/ReferralScreen';
import ManageSitesScreen from '@/screens/Main/Settings/MySites/ManageSites';
import SupportScreen from '@/screens/Main/Settings/Support';
import LanguageSelectionScreen from '@/screens/Main/Settings/LanguageSelectionScreen';

import NotFoundScreen from '@/screens/Error/NotFoundScreen';
import { GOOGLE_ANALYTICS_ID, WEB_HOST } from '@/constants/environment';
import { getPathKeyByPathname, initGA, logPageView, sentEvent, setUserId, setUserProperties } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import { RootState } from '@/stores/index';
import { GBCStatus } from '@/types/userInfo';
import Loading from '@/molecules/Loading';
import { changeMomentLocale, localeDetect } from '@/helpers/LanguageHelper';
import contents from '@/assets/config/appInfo';
import '@/assets/styles/react-confirm-alert.css';
import { useMediaQuery } from 'react-responsive';
import MainTemplate from '@/templates/MainTemplate';
import AuthTemplate from '@/templates/AuthTemplate';
import ProgramDetailScreen from '@/screens/Main/Settings/ProgramDetail';
import MobileSettings from '@/screens/Main/Settings/MobileSettings';
import { PortfolioScreen } from '@/screens/Main/SavingsEvents/SavingsEventsScreen/PortfolioScreen';
import MessageDetailScreen from '@/screens/Main/Messages/MessageDetailScreen';
import MobileEventsScreen from '@/screens/Main/SavingsEvents/SavingsEventsScreen/MobileEventsScreen';
import MobileHistoryDetailScreen from '@/screens/Main/SavingsEvents/SavingsEventsScreen/MobileHistoryDetailScreen';
import MySitesScreen from '@/screens/Main/Settings/MySites';
import MobileEnvironmentalImpactScreen from '@/screens/Main/Dashboard/MobileEnvironmentalImpact';
import MobileCurrentGridEmissions from '@/screens/Main/Dashboard/MobileCurrentGridEmissions';
import { sizes } from './assets/styles/media';
import RedirectErrorScreen from './screens/Auth/RedirectErrorScreen';
import NoEnrollmentErrorScreen from './screens/Auth/NoEnrollmentErrorScreen';
import SiteEnrollmentScreen from './screens/Main/Settings/MySites/SiteEnrollment/SiteEnrollment';
import SiteEnrollSuccessScreen from './screens/Main/Settings/MySites/SiteEnrollment/SiteEnrollSuccess';
import SiteEnrollFailedScreen from './screens/Main/Settings/MySites/SiteEnrollment/SiteEnrollFailed';
import { getActivePlatform } from './helpers/GetActivePlatform';
import { ProgramName } from './types/enrollment';
import { isUtilityEdition } from './helpers/Edition';
import {
  initializeAdobeTracking,
  initializeMetaPixel,
  initializeGAForSCE,
  noScriptGAForSCE,
  noScriptMetaPixelForSCE,
} from './helpers/AnalyticsForSCE';
import VerificationFailedScreen from './screens/Enrollment/VerificationFailedScreen/VerificationFailedScreen';
import ExistingCustomerFlowStartScreen from './screens/Enrollment/ExistingCustomerFlowStartScreen';
import VerificationNotEnrolledScreen from './screens/Enrollment/VerificationNotEnrolledScreen/VerificationNotEnrolledScreen';
import VerificationPendingScreen from './screens/Enrollment/VerificationPendingScreen/VerificationPendingScreen';
import VerificationFoundScreen from './screens/Enrollment/VerificationFoundScreen/VerificationFoundScreen';
import ReEnrollmentScreen from './screens/Main/Settings/MySites/ReEnrollmentScreen/ReEnrollmentScreen';
import ReEnrollmentMultipleSitesScreen from './screens/Main/Settings/MySites/ReEnrollmentMultipleSites';

const App = () => {
  // Unregister service workers from the old app
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  }
  const {
    loading: { loading },
    userInfo: {
      data: {
        userInfo,
        userInfo: { gbcStatus },
      },
    },
    setting: {
      data: { language },
    },
    misc: { appLogin },
    enrollment: {
      programs: { currentProgram },
    },
  } = useSelector((state: RootState) => state);

  const [GAInitialized, setGAInitialized] = useState(false);
  const isMobileWindowSize = useMediaQuery({ maxWidth: sizes.lg });
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { t } = useTranslation('common');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const shouldEnableAnalyticsForSCE = params.get('sitecode') === 'sce_cr_2024';

    if (shouldEnableAnalyticsForSCE && isUtilityEdition) {
      initializeGAForSCE();
      initializeMetaPixel();
      initializeAdobeTracking();
      noScriptGAForSCE();
      noScriptMetaPixelForSCE();
    }
  }, []);

  const switchToApp = () => {
    // TODO: appLogin을 window.ReactNativeWebView로 대체하는것이 어떨까? 리액트에서 appLogin를 불러와서 느리다.

    if (!isMobile || window.ReactNativeWebView) {
      return;
    }

    // TODO: language 파일에 추가한다.
    confirmAlert({
      title: t(`common.switchToApp`),
      message: '',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const uri = isAndroid
              ? `intent://${WEB_HOST}/#Intent;scheme=com.olivineinc.climateresponse.utility;package=com.olivineinc.climateresponse.utility;end`
              : contents.APP_DEEP_LINK;
            try {
              window.location.href = uri;

              if (isIOS) {
                setTimeout(() => {
                  window.location.href = contents.APP_STORE_LINK;
                }, 100);
              }
            } catch (err) {
              console.log(err);
            }
          },
        },
        {
          label: 'No',
          onClick: () => {
            // Do nothing
          },
        },
      ],
    });
  };

  useEffect(() => {
    if (
      !isMobileWindowSize &&
      (location.pathname === PATHS.SCREEN_MOBILE_EVENT.pathname ||
        location.pathname === PATHS.SCREEN_MOBILE_HISTORY_DETAIL.pathname)
    ) {
      navigate(PATHS.SCREEN_SAVING_EVENT.pathname);
    } else if (
      !isMobileWindowSize &&
      (location.pathname === PATHS.SCREEN_MOBILE_CURRENT_GRID_EMISSIONS.pathname ||
        location.pathname === PATHS.SCREEN_MOBILE_ENVIRONMENTAL_IMPACT.pathname)
    ) {
      navigate(PATHS.SCREEN_DASHBOARD.pathname);
    }
  }, [isMobileWindowSize]);

  useEffect(() => {
    initGA(GOOGLE_ANALYTICS_ID);
    setGAInitialized(true);
  }, []);

  useEffect(() => {
    const setAnalyticsDefaultParams = async () => {
      const activePlatform = getActivePlatform();
      if (userInfo.id) {
        setUserId(userInfo.id);
      }

      setUserProperties({
        language: localeDetect(language),
        platform: activePlatform,
        program: userInfo?.utility ? userInfo?.utility : 'not set',
      });
    };

    setAnalyticsDefaultParams();
  }, [userInfo?.id, language, userInfo?.utility]);

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language.toLowerCase());
      changeMomentLocale(language);
    }
    switchToApp();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const activePlatform = getActivePlatform();
    if (GAInitialized) {
      const programName = currentProgram?.program || userInfo?.program;
      const programTitle = programName ? t(`programs.${programName}.title`) : ProgramName.NONE;
      const params = {
        ...getPathKeyByPathname(location.pathname, programTitle),
        active_platform: activePlatform,
      };
      logPageView();
      sentEvent('screen_view', params);
    }
  }, [GAInitialized, location]);

  return (
    <Loading loading={appLogin ? false : loading}>
      <Routes>
        <Route element={<AuthTemplate />}>
          <Route index element={<WelcomeScreen />} />
          {isUtilityEdition && <Route path={PATHS.SCREEN_INVITATION.pathname} element={<InvitationLanding />} />}
          {!isUtilityEdition && <Route path={PATHS.SCREEN_CI.pathname} element={<InvitationLanding />} />}
        </Route>
        <Route path={PATHS.SCREEN_APP_LOGIN.pathname} element={<AppLoginScreen />} />
        <Route path={PATHS.SCREEN_AUTH_REDIRECT.pathname} element={<RedirectScreen />} />
        <Route path={PATHS.SCREEN_REDIRECT_ERROR.pathname} element={<RedirectErrorScreen />} />
        <Route path={PATHS.SCREEN_NO_ENROLLMENT_ERROR.pathname} element={<NoEnrollmentErrorScreen />} />
        <Route
          path="enrollment"
          element={
            <RouteGuard>
              <AuthTemplate />
            </RouteGuard>
          }
        >
          <Route path="programEnrollment" element={<ProgramEnrollmentScreen />} />
          <Route path="profile" element={<ProfileSetupScreen />} />
          <Route path="location" element={<LocationSetupScreen />} />
          <Route path="utility" element={<SelectUtilityScreen />} />
          <Route path="findProgram" element={<FindProgramScreen />} />
          <Route path="linkAccount" element={<ExistingCustomerFlowStartScreen />} />
          <Route path="verification" element={<VerificationScreen />} />
          <Route path="notVerified" element={<NotVerifiedScreen />} />
          <Route path="verificationFailed" element={<VerificationFailedScreen />} />
          <Route path="verificationNotEnrolled" element={<VerificationNotEnrolledScreen />} />
          <Route path="verificationPending" element={<VerificationPendingScreen />} />
          <Route path="verificationFound" element={<VerificationFoundScreen />} />
          <Route path="availableProgram">
            <Route path="" element={<AvailableProgramScreen />} />
            {!isUtilityEdition && <Route path="appOnly" element={<NoThanksScreen />} />}
          </Route>
          <Route path="connectToUtility" element={<ConnectToUtilityScreen />} />
          <Route path="survey/multiple" element={<MultipleSurveyScreen />} />
          <Route path="survey/single" element={<SingleSurveyScreen />} />
          <Route path="confirmProgram" element={<ProvisionalProgramsScreen />} />
          <Route path="signupCompleted" element={<SingUpCompletedScreenContainer />} />
        </Route>

        <Route
          path="main"
          element={
            <RouteGuard>
              <MainTemplate />
            </RouteGuard>
          }
        >
          <Route path="dashboard">
            <Route path="" element={<DashboardScreen />} />
            {isMobileWindowSize && (
              <>
                <Route path="environmentalImpact" element={<MobileEnvironmentalImpactScreen />} />
                <Route path="currentGridEmissions" element={<MobileCurrentGridEmissions />} />
              </>
            )}
          </Route>
          <Route path="usagePattern" element={<UsagePatternScreen />} />
          <Route path="dr">
            <Route path="" element={<SavingsEventsScreen />} />
            {isMobileWindowSize && (
              <Route path="event">
                <Route path="" element={<MobileEventsScreen />} />
                <Route path="detail" element={<MobileHistoryDetailScreen />} />
              </Route>
            )}
            <Route path="portfolio" element={<PortfolioScreen />} />
          </Route>
          <Route path="message" element={<MessagesScreen />} />
          <Route path="message/:messageId" element={<MessageDetailScreen />} />
        </Route>

        <Route
          path="setting"
          element={
            <RouteGuard>
              <MainTemplate />
            </RouteGuard>
          }
        >
          {isMobileWindowSize ? (
            <Route path="" element={<MobileSettings />} />
          ) : (
            <Route path="" element={<Navigate to="account" />} />
          )}
          <Route path="account" element={<AccountScreen />} />
          <Route path="sites">
            <Route path="" element={<MySitesScreen />} />
            <Route path="manage" element={<ManageSitesScreen />} />
            <Route path="enroll_success" element={<SiteEnrollSuccessScreen />} />
            <Route path="enroll_failed" element={<SiteEnrollFailedScreen />} />
            <Route path="enroll" element={<SiteEnrollmentScreen />} />
            <Route path="reEnrollment" element={<ReEnrollmentScreen />} />
            <Route path="reEnrollmentMultipleSites" element={<ReEnrollmentMultipleSitesScreen />} />
          </Route>
          {/* <Route path="site">
            <Route path="enroll_success" element={<SiteEnrollSuccessScreen />} />
            <Route path="enroll" element={<SiteEnrollmentScreen />} /> 
          </Route> */}
          <Route path="program" element={<ProgramDetailScreen />} />
          <Route path="support" element={<SupportScreen />} />
          <Route path="language" element={<LanguageSelectionScreen />} />
          <Route path="about" element={<AboutScreen />} />
          <Route path="referral" element={<ReferralScreen />} />
        </Route>

        <Route path="/*" element={gbcStatus === GBCStatus.notStarted ? <Navigate to="/" /> : <NotFoundScreen />} />
      </Routes>
    </Loading>
  );
};

const RouteGuard = ({ children }: { children: React.ReactNode }) => {
  const {
    userInfo: {
      data: { userInfo },
    },
  } = useSelector((state: RootState) => state);
  if (userInfo.id === '') {
    return <Navigate to="/" replace />;
  }
  if (
    userInfo.gbcStatus === GBCStatus.completed &&
    userInfo.enrollmentScreen === 'NAV_SINGUP_COMPLETE' &&
    window.location.pathname.split('/').includes('enrollment') &&
    window.location.pathname !== PATHS.SCREEN_ENROLLMENT_CONFIRM_PROGRAM.pathname
  ) {
    return <Navigate to="/" replace />;
  }
  if (
    userInfo.enrollmentScreen === 'NAV_SINGUP_COMPLETE' &&
    (userInfo.gbcStatus === GBCStatus.inProgress || userInfo.gbcStatus === GBCStatus.stillInProgress) &&
    window.location.pathname !== PATHS.SCREEN_ENROLLMENT_SINGUP_COMPLETE.pathname
  ) {
    return <Navigate to={PATHS.SCREEN_ENROLLMENT_SINGUP_COMPLETE.pathname} replace />;
  }
  return children ? <>{children}</> : <Outlet />;
};

export default App;
