import styled, { css } from 'styled-components';
import { helpers, media } from '@/assets/styles';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';
import { IStyleValue } from './index.types';

const reducedColor = (reduced?: boolean) => {
  if (reduced === undefined) {
    return colors.text;
  }

  return reduced ? colors.accent : colors.bad;
};

export const Wrapper = styled.div`
  flex: 1;
  padding: 15px 0 15px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ${media.lg`
    flex-direction: row;
    padding: 15px;
  `}

  ${media.xl`
    gap: 40px;
    justify-content: unset;
  `}
`;

export const Container = styled.div``;

export const EventTitleConainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 10px;
  flex: 1;
  width: 100%;
  ${media.lg`  
    width: unset;
  `}
`;

export const EventTitle = styled.p`
  ${typography.xs};
  line-height: 18px;
  font-weight: 700;
  color: ${colors.text};
`;

export const DateContainer = styled.div`
  display: flex;
  gap: 5px;
`;
export const Date = styled.p`
  ${typography.xxs};
  line-height: 16px;
  font-weight: 400;
  color: ${colors.grey};
`;

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2px;
  flex: 1;
  ${media.lg`
    width: unset;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  `};
  ${media.xl`
    width: unset;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  `};
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: ${colors.lightGrey};
  ${media.lg`
    border-top: 1.5px solid ${colors.lightGrey};
    height: 37px;
    width: 1px;
    margin-top: 0px;
    margin-bottom: 0px;
  `}
`;

export const Header = styled.div`
  ${helpers.flexSet('row', 'space-between', 'flex-end')};
`;

export const Name = styled.p(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: theme.typography.h5.fontSize })}
  `,
);

export const Percent = styled.p<IStyleValue>`
  ${typography.xs};
  font-weight: 800;
  line-height: 18px;
  color: ${colors.text};
`;

export const UsageRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding-bottom: 5px;
`;

export const EventUsage = styled.p<IStyleValue>`
  ${typography.xs};
  font-weight: 800;
  line-height: 18px;
  color: ${(props) => reducedColor(props.reduced)};
`;

export const Description = styled.span<IStyleValue>`
  ${typography.xs};
  font-weight: 400;
  line-height: 18px;
  color: ${(props) => reducedColor(props.reduced)};
`;

interface ButtonProps {
  padding?: string;
  warning?: boolean;
  iconFirst?: boolean;
  gap?: string;
  reverse?: boolean;
  selected?: boolean;
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  padding: ${(props) => props.padding};
  width: 100%;
  background-color: ${colors.white};
  gap: 20px;

  ${media.lg`
    width: unset;
    gap: 0;
  `};
  ${(props) =>
    props.selected &&
    css`
      background-color: ${colors.white};
      box-shadow: unset;
      ${media.lg`
        background-color: ${colors.hoverFocus};
        box-shadow: ${'0px 0px 0px 4px rgba(69, 129, 29, 0.2)'};
      `}
    `}
  &:hover {
    background-color: ${(props) => (props.warning ? colors.badFocus : colors.hoverFocus)};
  }
  &:focus {
    background-color: ${(props) => (props.warning ? colors.badFocus : colors.hoverFocus)};
    box-shadow: ${(props) =>
      props.warning ? '0px 0px 0px 4px rgba(215, 2, 26, 0.2);' : '0px 0px 0px 4px rgba(69, 129, 29, 0.2)'};
  }
  &:disabled {
    cursor: default;
    color: ${colors.grey};
  }
  &:disabled:hover {
    background-color: transparent;
  }
`;

export const Img = styled.img<{ width?: string; height?: string }>`
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
`;

export const ImgArrow = styled.img<{ width?: string; height?: string }>`
  padding-right: 15px;
  display: block;
  ${media.lg`
    display: none;
  `}
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
`;
