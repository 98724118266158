import React, { useState } from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { InputComponent, Label, ErrorText } from './input.styles';

type InputProps<T extends FieldValues> = {
  type: string;
  label: string;
  formatEntry?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  ariaLabel?: string;
  error?: string;
  maxLength?: number;
  minLength?: number;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  labelStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
} & Pick<UseControllerProps<T>, 'control' | 'name' | 'rules'>;

export const Input = <T extends FieldValues>({
  type,
  label,
  formatEntry,
  placeholder,
  disabled,
  ariaLabel,
  error,
  minLength,
  maxLength,
  name,
  rules,
  control,
  onKeyUp,
  labelStyle,
  inputStyle,
  ...inputProps
}: InputProps<T>) => {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <div style={{ width: '100%' }}>
      <Label style={labelStyle}>{label}</Label>
      <Controller
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <InputComponent
              style={inputStyle}
              type={type}
              onFocus={() => setIsFocused(true)}
              onBlur={() => {
                setIsFocused(false);
                onBlur();
              }}
              isFocused={isFocused}
              error={error}
              disabled={disabled}
              onChange={(e) =>
                formatEntry
                  ? onChange(formatEntry(e.target.value) as unknown as React.ChangeEvent<Element>)
                  : onChange(e)
              }
              value={value}
              placeholder={placeholder}
              aria-label={ariaLabel}
              maxLength={maxLength}
              minLength={minLength}
              onKeyUp={onKeyUp}
            />
          );
        }}
        rules={{ ...rules, maxLength, minLength }}
        name={name}
        control={control}
        {...inputProps}
      />
      {!!error && <ErrorText>{error}</ErrorText>}
    </div>
  );
};
