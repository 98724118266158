import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PATHS from '@/types/navigationPaths';
import contents from '@/assets/config/appInfo';
import { useTranslation } from 'react-i18next';
import { EDITION } from '@/constants/environment';
import useEnrollment from '@/hooks/useEnrollment';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import { LinkEnrollmentChoice } from '@/types/enrollment';
import { useUserInfo } from '@/hooks/index';
import ExistingCustomerFlowStartScreenView from './index.view';

const ExistingCustomerFlowStartScreen = () => {
  const { t } = useTranslation('common');
  // => "Make a selection"
  document.title = `${t('screens.enrollment.existingCustomerFlowStartScreen.title')} - ${contents.name}`;

  const linkEnrollmentChoice = useSelector((state: RootState) => state.enrollment.enrollmentInfo.linkEnrollmentChoice);
  const [nextEnrollmentStage, setNextEnrollmentStage] = useState<LinkEnrollmentChoice | null>(
    linkEnrollmentChoice ?? null,
  );
  const { fetchChangeLinkEnrollmentChoice } = useEnrollment();
  const { fetchUpdateUserInfo } = useUserInfo();

  const navigate = useNavigate();

  const disabledNext = useMemo(() => !nextEnrollmentStage, [nextEnrollmentStage]);

  const handleNext = () => {
    fetchChangeLinkEnrollmentChoice(nextEnrollmentStage ?? undefined);
    fetchUpdateUserInfo({ linkEnrollmentChoice: nextEnrollmentStage ?? undefined });

    switch (nextEnrollmentStage) {
      case LinkEnrollmentChoice.NewEnrollment:
        if (EDITION === 'Olivine') {
          navigate(PATHS.SCREEN_ENROLLMENT_PROGRAM_ENROLLMENT.pathname);
        } else if (EDITION === 'Utility') {
          navigate(PATHS.SCREEN_ENROLLMENT_PROFILE_SETUP.pathname);
        } else {
          navigate(PATHS.SCREEN_ENROLLMENT_PROFILE_SETUP.pathname);
          console.error(`Navigating to ${nextEnrollmentStage} - Unknown Edition ${EDITION}.`);
        }
        break;
      case LinkEnrollmentChoice.LinkEnrollment:
        navigate(PATHS.SCREEN_ENROLLMENT_LOCATION_SETUP.pathname);
        break;
      default:
        navigate(PATHS.SCREEN_ENROLLMENT_PROFILE_SETUP.pathname);
        console.error(`Navigating to ${nextEnrollmentStage} - possible invalid selection.`);
    }
  };

  return (
    <ExistingCustomerFlowStartScreenView
      disabledNext={disabledNext}
      onNext={handleNext}
      nextEnrollmentStage={nextEnrollmentStage}
      setNextEnrollmentStage={setNextEnrollmentStage}
    />
  );
};

export default ExistingCustomerFlowStartScreen;
