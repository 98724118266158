import { useQuery } from '@tanstack/react-query';
import * as apiPrimary from '@/apis/primary';
import { Message } from '@/types/message';
import { GeneralHook, FetchingStatus } from './types';

const useMessages = (): GeneralHook<{ messages: Array<Message> }> => {
  const { data, error, isError, isLoading, isRefetching, isFetching, refetch } = useQuery({
    queryFn: () => apiPrimary.getMessages(),
    queryKey: ['apiPrimary.getMessages'],
    staleTime: 60 * 5 * 1000,
  });

  if (isLoading || isRefetching || isFetching) {
    return { fetchingStatus: FetchingStatus.LOADING };
  }

  if (isError) {
    return {
      fetchingStatus: FetchingStatus.ERROR,
      error,
      refetch,
    };
  }

  if (data?.data) {
    return {
      fetchingStatus: FetchingStatus.SUCCESS,
      data: data.data,
      refetch,
    };
  }

  return {
    fetchingStatus: FetchingStatus.INITIAL,
    fetch: refetch,
  };
};

export default useMessages;
