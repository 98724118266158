import { VerifyLinkEnrollmentResponse, VerifyLinkEnrollmentResponseStatus } from '@/types/enrollment';
import PATHS from '@/types/navigationPaths';
import { useNavigate } from 'react-router-dom';
import { IResponse } from '@/apis/api';

const useOnVerifyResponse = () => {
  const navigate = useNavigate();

  const onSuccess = (data: IResponse<VerifyLinkEnrollmentResponse> | undefined) => {
    if (!data?.data) {
      navigate(PATHS.SCREEN_ENROLLMENT_VERIFICATION_FAILED.pathname);
      return;
    }

    switch (data.data.result) {
      case VerifyLinkEnrollmentResponseStatus.DisenrolledOrRejected:
        navigate(PATHS.SCREEN_ENROLLMENT_VERIFICATION_NOT_ENROLLED.pathname);
        break;
      case VerifyLinkEnrollmentResponseStatus.PendingEnrollments:
        navigate(PATHS.SCREEN_ENROLLMENT_VERIFICATION_PENDING.pathname);
        break;
      case VerifyLinkEnrollmentResponseStatus.ExistingEnrollmentsFound:
        navigate(PATHS.SCREEN_ENROLLMENT_VERIFICATION_FOUND.pathname);
        break;
      default:
        navigate(PATHS.SCREEN_ENROLLMENT_VERIFICATION_FAILED.pathname);
    }
  };

  const onError = () => navigate(PATHS.SCREEN_ENROLLMENT_VERIFICATION_FAILED.pathname);

  return {
    onSuccess,
    onError,
  };
};

export default useOnVerifyResponse;
