import { LinkEnrollmentChoice } from '@/types/enrollment';
import { Language, TempScale } from '@/types/setting';
import { GBCStatus } from '@/types/userInfo';

export interface ReqUpdateUserInfo {
  isAuthenticated?: boolean;
  email?: string;
  firstName?: string;
  lastName?: string;
  isLocationBusiness?: boolean;
  zipcode?: string;
  phoneNumbers?: string[];
  siteName?: string;
  gbcStatus?: GBCStatus;
  expoPushToken?: string;
  utility?: string;
  program?: string;
  enrollmentScreen?: string;
  linkEnrollmentChoice?: LinkEnrollmentChoice;
}

export interface ReqSetting {
  language: Language;
  tempScale: TempScale;
}

export interface ReqDeleteUser {
  id: string;
  deletedBy: string;
}

export enum AuthErrorCodes {
  AlreadyBeganActivation = 'AlreadyBeganActivation',
  NoSupportedEnrollments = 'NoSupportedEnrollments',
}
