import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '@/designSystem/components';
import EnrollmentTemplate from '@/templates/EnrollmentTemplate';
import PATHS from '@/types/navigationPaths';
import contents from '@/assets/config/appInfo';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import usePostEnrolledCustomerVerification from '@/hooks/usePostEnrolledCustomerVerification';
import { FetchingStatus } from '@/hooks/types';
import useOnVerifyResponse from '@/hooks/enrollment/useOnVerifyResponse';
import { Description, InputWrapper, styles } from './index.styles';

export interface FormData extends FieldValues {
  serviceFirstName: string;
  serviceLastName: string;
  serviceAccountNumber: string;
  serviceAddress: string;
  serviceCity: string;
  serviceZipCode: string;
}

const VerificationScreenView = () => {
  const { t } = useTranslation('common');
  // Account Verification Olivine
  document.title = `${t('screens.enrollment.verificationScreen.olivine.title')} - ${contents.name}`;
  const enrolledCustomerVerificationState = usePostEnrolledCustomerVerification();
  const onVerifyResponse = useOnVerifyResponse();

  const onNext: SubmitHandler<FormData> = (payload: FormData) => {
    if (enrolledCustomerVerificationState.fetchingStatus === FetchingStatus.INITIAL) {
      enrolledCustomerVerificationState.post(payload, {
        ...onVerifyResponse,
        onSettled: () => {
          if (window.ReactNativeWebView) {
            const data = JSON.stringify({ loading: false });
            window.ReactNativeWebView.postMessage(data);
          }
        },
      });
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormData>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    defaultValues: {
      serviceFirstName: '',
      serviceLastName: '',
      serviceAccountNumber: '',
      serviceAddress: '',
      serviceCity: '',
      serviceZipCode: '',
    },
  });

  return (
    <EnrollmentTemplate
      navigationTitle={t('screens.enrollment.verificationScreen.olivine.title')}
      navigationPath={PATHS.SCREEN_ENROLLMENT_VERIFICATION.pathname}
      previousNavigationPath={PATHS.SCREEN_ENROLLMENT_LOCATION_SETUP.pathname}
      onNext={handleSubmit(onNext)}
      textNext={t('common.generalButtons.next')}
      disabled={!isValid}
      hideUtilityLogo
      forceLoading={enrolledCustomerVerificationState.fetchingStatus === FetchingStatus.LOADING}
    >
      <Description tabIndex={0}>{t('screens.enrollment.verificationScreen.olivine.description')}</Description>
      <InputWrapper>
        <Input
          inputStyle={styles.input}
          labelStyle={styles.label}
          type="text"
          rules={{
            required: true,
            maxLength: 50,
          }}
          name="serviceFirstName"
          control={control}
          label={t('screens.enrollment.verificationScreen.olivine.fields.firstName')}
          disabled={false}
          error={errors.serviceFirstName?.message}
          placeholder=""
          ariaLabel={t('screens.enrollment.verificationScreen.olivine.fields.firstName')}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          inputStyle={styles.input}
          labelStyle={styles.label}
          type="text"
          rules={{
            required: true,
            maxLength: 50,
          }}
          name="serviceLastName"
          control={control}
          label={t('screens.enrollment.verificationScreen.olivine.fields.lastName')}
          disabled={false}
          error={errors.serviceLastName?.message}
          placeholder=""
          ariaLabel={t('screens.enrollment.verificationScreen.olivine.fields.lastName')}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          inputStyle={styles.input}
          labelStyle={styles.label}
          type="text"
          rules={{
            required: true,
          }}
          maxLength={10}
          minLength={10}
          formatEntry={(value) => (Number(value) ? value : value.slice(0, -1))}
          name="serviceAccountNumber"
          control={control}
          label={t('screens.enrollment.verificationScreen.olivine.fields.serviceAccount')}
          disabled={false}
          error={errors.serviceAccountNumber?.message}
          placeholder=""
          ariaLabel={t('screens.enrollment.verificationScreen.olivine.fields.serviceAccount')}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          inputStyle={styles.input}
          labelStyle={styles.label}
          type="text"
          rules={{
            required: true,
            maxLength: 50,
          }}
          name="serviceAddress"
          control={control}
          label={t('screens.enrollment.verificationScreen.olivine.fields.address')}
          disabled={false}
          error={errors.serviceAddress?.message}
          placeholder=""
          ariaLabel={t('screens.enrollment.verificationScreen.olivine.fields.address')}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          inputStyle={styles.input}
          labelStyle={styles.label}
          type="text"
          rules={{
            required: true,
            maxLength: 50,
          }}
          name="serviceCity"
          control={control}
          label={t('screens.enrollment.verificationScreen.olivine.fields.city')}
          disabled={false}
          error={errors.serviceCity?.message}
          placeholder=""
          ariaLabel={t('screens.enrollment.verificationScreen.olivine.fields.city')}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          inputStyle={styles.input}
          labelStyle={styles.label}
          type="text"
          rules={{
            required: true,
          }}
          formatEntry={(value) => (Number(value) ? value : value.slice(0, -1))}
          maxLength={5}
          minLength={5}
          name="serviceZipCode"
          control={control}
          label={t('screens.enrollment.verificationScreen.olivine.fields.zipcode')}
          disabled={false}
          error={errors.serviceZipCode?.message}
          placeholder=""
          ariaLabel={t('screens.enrollment.verificationScreen.olivine.fields.zipcode')}
          onKeyUp={(e) => {
            if (e.key.toLocaleLowerCase() === 'enter' && onNext && isValid) {
              handleSubmit(onNext)();
            }
          }}
        />
      </InputWrapper>
    </EnrollmentTemplate>
  );
};
export default VerificationScreenView;
