import React, { useState } from 'react';
import HistoryItemDetail from '@/organisms/HistoryItemDetail';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import { PerformanceToDateEnum } from '@/types/dr';

import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import ModalReward from '@/organisms/ModalReward';
import ModalEnergyEvent from '@/organisms/ModalEnergyEvent';
import { HistoryTabs } from '@/screens/Main/SavingsEvents/SavingsEventsScreen/index.types';
import { BodyContainer, Wrapper } from './MobileHistoryDetailScreen.styles';

const MobileHistoryDetailScreen = () => {
  const {
    dr: {
      historyScreen: { selectedTab },
      history: {
        events: {
          data: { selectedEvent },
        },
        earnings: {
          data: { selectedEarningsEvent },
        },
      },
    },
  } = useSelector((state: RootState) => state);
  const [selectedHistoryTab, setSelectedHistoryTab] = useState<HistoryTabs>(HistoryTabs.QuickView);
  const [shouldDisplayRewardModal, setShouldDisplayRewardModal] = useState(false);
  const [showEnergyEventModal, setShowEnergyEventModal] = useState(false);

  const isEmissions = selectedTab === PerformanceToDateEnum.emissions;
  const selectedItem =
    selectedTab === PerformanceToDateEnum.reduction || isEmissions ? selectedEvent : selectedEarningsEvent;

  const changeEnergyEventModalVisibility = () => {
    sentEvent('click', PATHS.HELP_ENERGY_EVENT);
    setShowEnergyEventModal(!showEnergyEventModal);
  };

  return (
    <Wrapper>
      <BodyContainer>
        <HistoryItemDetail
          item={selectedItem}
          tabLinkSelected={selectedHistoryTab}
          onTabClick={setSelectedHistoryTab}
          onHelp={changeEnergyEventModalVisibility}
          selectedTab={selectedTab}
        />
      </BodyContainer>
      <ModalReward show={shouldDisplayRewardModal} onClose={() => setShouldDisplayRewardModal(false)} />
      <ModalEnergyEvent show={showEnergyEventModal} onClose={changeEnergyEventModalVisibility} isCO2={isEmissions} />
    </Wrapper>
  );
};

export default MobileHistoryDetailScreen;
