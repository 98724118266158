import React from 'react';
import { useTranslation } from 'react-i18next';
import ProfileSetup from '@/organisms/ProfileSetup';
import EnrollmentTemplate from '@/templates/EnrollmentTemplate';
import PATHS from '@/types/navigationPaths';
import { isUtilityEdition } from '@/helpers/Edition';
import { shouldDisplayLinkEnrollmentFlow } from '@/helpers/ShouldDisplayLinkEnrollmentFlow';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import { IProfileScreenViewProps } from './index.types';

const ProfileSetupScreenView = ({ disabledNext, onNext }: IProfileScreenViewProps) => {
  const { t } = useTranslation('common');
  const {
    userInfo: {
      data: { userInfo },
    },
  } = useSelector((state: RootState) => state);

  const displayLinkEnrollmentFlow = shouldDisplayLinkEnrollmentFlow(userInfo.email);

  const utilityPreviousPath = displayLinkEnrollmentFlow
    ? PATHS.SCREEN_EXISTING_CUSTOMER_FLOW_START_SETUP.pathname
    : undefined;

  const previousPath = isUtilityEdition ? utilityPreviousPath : PATHS.SCREEN_ENROLLMENT_PROGRAM_ENROLLMENT.pathname;

  return (
    <EnrollmentTemplate
      disabled={disabledNext}
      underline
      navigationTitle={t('screens.enrollment.profileSetupScreen.title')}
      navigationPath={PATHS.SCREEN_ENROLLMENT_PROFILE_SETUP.pathname}
      onNext={onNext}
      textNext={t('common.generalButtons.next')}
      previousNavigationPath={previousPath}
    >
      <ProfileSetup onNext={onNext} disabled={disabledNext} />
    </EnrollmentTemplate>
  );
};

export default ProfileSetupScreenView;
