import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '@/stores/index';
import { useUserInfo, useEnrollment } from '@/hooks/index';
import contents from '@/assets/config/appInfo';
import { ReqUpdateUserInfo } from '@/apis/auth/types';
import { sentEvent } from '@/helpers/GAHelper';
import { useTranslation } from 'react-i18next';
import { moveChangeScreen, nextScreenStep } from '@/helpers/NavigationHelper';
import PATHS from '@/types/navigationPaths';
import { LinkEnrollmentChoice } from '@/types/enrollment';
import SelectUtilityScreenView from './index.view';

const SelectUtilityScreenContainer = () => {
  const { t } = useTranslation('common');
  document.title = `${t('screens.enrollment.selectUtilityScreen.title')} - ${contents.name}`;
  const {
    enrollment: {
      enrollmentInfo,
      enrollmentInfo: { utility },
      utilityPrograms,
    },
    userInfo: {
      data: { userInfo },
    },
  } = useSelector((state: RootState) => state);

  // Select Your Electric Utility
  document.title = `${t('screens.enrollment.selectUtilityScreen.title')} - ${contents.name}`;

  const navigate = useNavigate();
  const { fetchChangePrograms, fetchChangeProgram } = useEnrollment();
  const { fetchUpdateUserInfo } = useUserInfo();
  const [modal, setModal] = useState({ noPrograms: false });

  const linkEnrollmentChoice = enrollmentInfo?.linkEnrollmentChoice || userInfo?.linkEnrollmentChoice || undefined;

  const handleNext = () => {
    const programs = utilityPrograms[utility];
    fetchChangePrograms(programs);
    const newPayload: ReqUpdateUserInfo = { utility };
    if (programs.length) {
      newPayload.program = programs[0].program;
    }
    fetchUpdateUserInfo(newPayload);

    if (programs.length > 0) {
      sentEvent('select_energy_provider_submitted');

      if (linkEnrollmentChoice === LinkEnrollmentChoice.LinkEnrollment) {
        navigate(PATHS.SCREEN_ENROLLMENT_VERIFICATION.pathname);
      } else {
        const changedScreenSteps = moveChangeScreen(
          { availablePrograms: programs },
          programs[0].program,
          fetchChangeProgram,
        );
        const nextScreen = nextScreenStep(changedScreenSteps, window.location, enrollmentInfo);
        navigate(nextScreen);
      }
    } else {
      setModal({ noPrograms: true });
    }
  };

  const handleNoProgramsOk = () => {
    setModal({ noPrograms: false });
  };

  return (
    <SelectUtilityScreenView
      disabledNext={!utility || !utilityPrograms[utility]}
      onNext={handleNext}
      modalNoPrograms={modal.noPrograms}
      modalNoProgramsOk={handleNoProgramsOk}
      utility={utility}
    />
  );
};

export default SelectUtilityScreenContainer;
