import React from 'react';
import Img from '@/atoms/Img';
import { accessibilityUsageLabel } from '@/helpers/AccessibilityHelper';
import type { DefaultImage } from '@/types/images';
import { ExtremeValueIndicator, Time, Value, ValueContainer, ValueContainerMobile, ValueTitle } from '../index.styles';

export const UsageMinMax = ({
  title,
  value,
  description,
  textColor,
  icon,
}: {
  title: string;
  value: string;
  description: string;
  textColor: string;
  icon: DefaultImage;
}) => {
  return (
    <ExtremeValueIndicator>
      <ValueTitle>{title}</ValueTitle>
      <ValueContainer>
        <ValueContainerMobile>
          <Img src={icon} />
          <Value aria-label={accessibilityUsageLabel(value)} color={textColor}>
            {value}
          </Value>
        </ValueContainerMobile>
        <Time>{description}</Time>
      </ValueContainer>
    </ExtremeValueIndicator>
  );
};
