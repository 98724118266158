import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PATHS from '@/types/navigationPaths';
import contents from '@/assets/config/appInfo';
import { DashboardTypes } from '@/types/dashboard';
import { RootState } from '@/stores/index';
import { GBCStatus, UserInfo } from '@/types/userInfo';
import { useUserInfo, useDashboard, useSites, useEvents, useEventsHistory } from '@/hooks/index';
import { getProgramContext } from '@/helpers/ProgramStrategy';
import { Wrapper } from '@/screens/Main/Dashboard/index.styles';
import { getProgramNameFromSite } from '@/helpers/GetProgramName';
import { billCreditPrograms, moneyEarnedDisclaimer } from '@/helpers/MoneyEarnedDisclaimer';
import DashboardWithDRAndGrid from './DashboardWithDRAndGrid';
import DashboardWithEarningsDRAndGrid from './DashboardWithEarningsDRAndGrid';
import DashboardWithEarningsEnergyHistoryAndGrid from './DashboardWithEarningsEnergyHistoryAndGrid';
import DashboardDataOnly from './DashboardDataOnly';

const DashboardScreenContainer = () => {
  const { t } = useTranslation('common');
  document.title = `${t('screens.main.dashboard.dashboardScreen.title')} - ${contents.name}`;

  const {
    sites: {
      data: { currentSite },
    },
    userInfo: {
      data: { userInfo },
    },
    dr: {
      history: {
        events: {
          data: { currentSeason: eventsCurrentSeason },
        },
      },
    },
    dashboard: {
      data: { achievement },
    },
  } = useSelector((state: RootState) => state);

  const { fetchSites } = useSites();
  const { fetchUserInfo } = useUserInfo();
  const { fetchDashboard } = useDashboard();
  const { fetchDr } = useEvents();
  const { fetchEventsHistoryRequest } = useEventsHistory();

  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo.isDeleted === true) {
      navigate(PATHS.SCREEN_SETTING_ACCOUNT.pathname);
    }
  });
  useEffect(() => {
    fetchUserInfo((userInfo: UserInfo) => {
      if (userInfo.gbcStatus === GBCStatus.completed && !currentSite) {
        fetchSites(userInfo.siteName);
      }
    });
  }, []);

  useEffect(() => {
    if (currentSite?.id) {
      fetchDashboard(currentSite.id);
      fetchDr(currentSite.id);
      fetchEventsHistoryRequest(currentSite.id);
      fetchSites();
    }
  }, [currentSite?.id]);

  const displayMeterData = currentSite?.program?.attributes?.displayMeterData;
  const moneyEarnedPerEvent = currentSite?.program?.attributes?.moneyEarnedPerEvent;
  const summaryState = achievement?.summaryState;
  const programName = getProgramNameFromSite(currentSite);
  const hasEvents = eventsCurrentSeason?.history?.length > 0;

  const context = getProgramContext({ programName });
  const dashboardType = context?.getDashboardType(displayMeterData, hasEvents);

  const { disclaimerNamespace, symbol } = moneyEarnedDisclaimer({
    moneyEarnedPerEvent,
    summaryState,
    isSceWithEvents: billCreditPrograms.includes(programName) && hasEvents,
  });

  const disclaimer = t(disclaimerNamespace);

  const renderDashboard = useCallback(() => {
    switch (dashboardType) {
      /**
       * SCE_ELRP_A6
       */
      case DashboardTypes.DASHBOARD_WITH_DR_AND_GRID:
        return <DashboardWithDRAndGrid />;
      /**
       * SCE_ELRP_A6, hasEvent && displayMeterData
       */
      case DashboardTypes.DASHBOARD_WITH_EARNINGS_DR_AND_GRID:
        return <DashboardWithEarningsDRAndGrid achievementDisclaimer={disclaimer} achievementSymbol={symbol} />;
      /**
       * PGE_ELRP_A6
       * OLIVINE_ENERGY
       * CLIMATE_RESPONSE
       */
      case DashboardTypes.DASHBOARD_WITH_EARNINGS_ENERGY_HISTORY_AND_GRID:
        return (
          <DashboardWithEarningsEnergyHistoryAndGrid achievementDisclaimer={disclaimer} achievementSymbol={symbol} />
        );

      /**
       * APP ONLY- placeholder messages
       */
      case DashboardTypes.DASHBOARD_DATA_ONLY:
        return <DashboardDataOnly achievementDisclaimer={disclaimer} achievementSymbol={symbol} />;

      default:
        return <DashboardWithDRAndGrid />;
    }
  }, [dashboardType]);

  return <Wrapper>{renderDashboard()}</Wrapper>;
};

export default DashboardScreenContainer;
