import React from 'react';
import { useTranslation } from 'react-i18next';
import EnrollmentTemplate from '@/templates/EnrollmentTemplate';
import PATHS from '@/types/navigationPaths';
import HasExistingEnrollment from '@/organisms/HasExistingEnrollment';
import { IExistingCustomerFlowStartScreenViewProps } from './index.types';

const ExistingCustomerFlowStartScreenView = ({
  onNext,
  disabledNext,
  setNextEnrollmentStage,
  nextEnrollmentStage,
}: IExistingCustomerFlowStartScreenViewProps) => {
  const { t } = useTranslation('common');

  return (
    <EnrollmentTemplate
      disabled={disabledNext}
      underline
      navigationTitle={t('screens.enrollment.existingCustomerFlowStartScreen.header')}
      navigationPath={PATHS.SCREEN_EXISTING_CUSTOMER_FLOW_START_SETUP.pathname}
      onNext={onNext}
      textNext={t('common.generalButtons.next')}
    >
      <HasExistingEnrollment
        setNextEnrollmentStage={setNextEnrollmentStage}
        nextEnrollmentStage={nextEnrollmentStage}
      />
    </EnrollmentTemplate>
  );
};

export default ExistingCustomerFlowStartScreenView;
