import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import useDashboard from '@/hooks/useDashboard';
import { FetchingStatus } from '@/hooks/types';
import RecentEnergyHistoryView from './index.view';
import { RecentEnergyHistoryProps } from './index.types';

const RecentEnergyHistoryContainer = ({ ariaControls }: RecentEnergyHistoryProps) => {
  const {
    sites: {
      data: { currentSite },
    },
  } = useSelector((state: RootState) => state);
  const dashboardState = useDashboard({ siteId: currentSite?.id || '' });
  const [stateModal, setStateModal] = useState({ energyInformation: false });

  const handleHelp = () => {
    sentEvent('click', PATHS.HELP_ENERGY_INFORMATION);
    setStateModal({ ...stateModal, energyInformation: true });
  };

  const handleModalEnergyHistoryClose = () => {
    setStateModal({ ...stateModal, energyInformation: false });
  };

  if (dashboardState.fetchingStatus === FetchingStatus.SUCCESS) {
    return (
      <RecentEnergyHistoryView
        item={dashboardState.data.history}
        onHelp={handleHelp}
        modalEnergyHistory={stateModal.energyInformation}
        modalEnergyHistoryClose={handleModalEnergyHistoryClose}
        ariaControls={ariaControls}
      />
    );
  }
  return <div />;
};

export default RecentEnergyHistoryContainer;
