import { useMutation } from '@tanstack/react-query';
import * as apiPrimary from '@/apis/primary';
import { ProgramName } from '@/types/enrollment';
import { IResponse } from '@/apis/api';
import { FetchingStatus, PostRequestHook } from './types';

export type PostReEnrollmentPayload = {
  program: ProgramName;
  siteId: string;
};

type ResponseType = IResponse;

const usePostReEnrollment = (): PostRequestHook<PostReEnrollmentPayload, ResponseType> => {
  const { error, isError, mutate, isLoading, isSuccess } = useMutation({
    mutationFn: (payload: PostReEnrollmentPayload) => apiPrimary.postReEnrollSite(payload),
    mutationKey: ['apiAuth.postReEnrollSite'],
  });

  if (isLoading) {
    return { fetchingStatus: FetchingStatus.LOADING };
  }

  if (isError) {
    return {
      fetchingStatus: FetchingStatus.ERROR,
      error,
      post: mutate,
    };
  }

  if (isSuccess) {
    return {
      fetchingStatus: FetchingStatus.SUCCESS,
      post: mutate,
    };
  }

  return {
    fetchingStatus: FetchingStatus.INITIAL,
    post: mutate,
  };
};

export default usePostReEnrollment;
