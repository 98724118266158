import styled, { css } from 'styled-components';
import { helpers, media } from '@/assets/styles';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';

export const Wrapper = styled.div`
  padding: 40px 20px 20px 20px;
  height: 100vh;
  ${media.lg`
    height: unset;
  `}
`;

export const TitleContainer = styled.div`
  margin-top: 20px;
  ${media.lg`
    margin-top: 0;
  `}
`;

export const Title = styled.p`
  ${typography.sm};
  line-height: 24px;
  font-weight: 900;
  color: ${colors.text};
  ${media.lg`
    ${typography.xl1};
    line-height: 29px;
    font-weight: 800;
  `}
`;

export const AgreeBox = styled.div(
  ({ theme }) => css`
    ${helpers.flexSet('row', 'flex-start', 'flex-start')};
    ${helpers.borderSet({
      borderRadius: theme.borders.default.radius,
      borderWidth: theme.borders.default.width,
      borderColor: theme.colors.primary[100],
    })}
    ${helpers.colorSet({ backgroundColor: theme.colors.primary[50] })};

    padding: 12px 20px;
    margin: 16px 0;
  `,
);

export const Terms = styled.p(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: theme.typography.body.fontSize })};
    ${helpers.colorSet({ color: theme.colors.text[100] })};
    margin-left: 10px;
    align-self: center;
  `,
);

export const LinkText = styled.a(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: theme.typography.body.fontSize })};
    ${helpers.colorSet({ color: theme.colors.primary[100] })};
  `,
);

export const ModalErrorDescription = styled.p`
  ${typography.sm};
  padding: 20px 8px;
`;
