import { useMutation } from '@tanstack/react-query';
import * as apiPrimary from '@/apis/primary';
import { ProgramName } from '@/types/enrollment';
import { IResponse } from '@/apis/api';

import { FetchingStatus, PostRequestHook } from './types';

export type PostReEnrollmentMultiSitesPayload = Array<{
  program: ProgramName;
  siteId: string;
}>;

type ResponseType = IResponse;

const usePostReEnrollmentMultiSites = (): PostRequestHook<PostReEnrollmentMultiSitesPayload, ResponseType> => {
  const { error, isError, isLoading, isSuccess, mutate } = useMutation({
    mutationFn: (payload: PostReEnrollmentMultiSitesPayload) => apiPrimary.postReEnrollMultiSite(payload),
    mutationKey: ['apiPrimary.postReEnrollMultiSite'],
  });

  if (isLoading) {
    return { fetchingStatus: FetchingStatus.LOADING };
  }

  if (isError) {
    return {
      fetchingStatus: FetchingStatus.ERROR,
      error,
      post: mutate,
    };
  }

  if (isSuccess) {
    return {
      fetchingStatus: FetchingStatus.SUCCESS,
      post: mutate,
    };
  }

  return {
    fetchingStatus: FetchingStatus.INITIAL,
    post: mutate,
  };
};

export default usePostReEnrollmentMultiSites;
