import React, { CSSProperties } from 'react';
import { Button, Title } from './primaryButton.styles';

type PrimaryButtonProps = {
  title: string;
  onClick: () => void;
  disabled?: boolean;
  small?: boolean;
  style?: CSSProperties;
  ariaLabel?: string;
};

export const PrimaryButton = ({
  title,
  onClick,
  small,
  style,
  disabled = false,
  ariaLabel = title,
}: PrimaryButtonProps) => {
  return (
    <Button
      aria-label={ariaLabel}
      aria-disabled={disabled}
      disabled={disabled}
      onClick={onClick}
      small={small}
      style={style}
    >
      <Title>{title}</Title>
    </Button>
  );
};
