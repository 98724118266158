import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import { sentEvent } from '@/helpers/GAHelper';
import GBCPendingView from './index.view';
import { IGBCPendingContainerProps } from './index.types';

const GBCPendingContainer = ({
  shareTextColor,
  minHeight,
  containerPadding,
  titleMargin,
}: IGBCPendingContainerProps) => {
  const {
    userInfo: {
      data: {
        userInfo,
        userInfo: {
          userGbiRedirect: { program },
        },
      },
    },
    enrollment: {
      programs: { currentProgram },
      utilityPrograms,
    },
  } = useSelector((state: RootState) => state);

  const programName = currentProgram && currentProgram.program ? currentProgram.program : program;

  const { firstName, lastName } = userInfo;

  const userUtility = userInfo.utility;
  const utilityData = utilityPrograms[userUtility];
  let programFromUtility = '';
  if (utilityData?.length > 0) {
    programFromUtility = utilityData[0]?.program;
  }

  const isEmptyProgramName = programName === 'none' || !programName;

  const handleEmail = (subject: string, body: string) => {
    try {
      sentEvent('referral_link_clicked', { referral_type: 'email' });
      if (window.ReactNativeWebView) {
        const data = JSON.stringify({ open: 'mailto', message: `subject=${subject}&body=${body}` });
        window.ReactNativeWebView.postMessage(data);
      } else {
        window.open(`mailto:?subject=${subject}&body=${body}`, '_blank');
      }
    } catch {
      // eslint-disable-next-line no-alert
      alert('Email Not Available');
    }
  };

  const handleSms = (smsContent: string) => {
    try {
      sentEvent('referral_link_clicked', { referral_type: 'sms' });
      if (window.ReactNativeWebView) {
        const data = JSON.stringify({ open: 'sms', message: `body=${smsContent}` });
        window.ReactNativeWebView.postMessage(data);
      } else {
        window.open(`sms:?body=${smsContent}`);
      }
    } catch {
      // eslint-disable-next-line no-alert
      alert('SMS Not Available');
    }
  };

  return (
    <GBCPendingView
      name={`${firstName} ${lastName}`}
      programName={isEmptyProgramName ? programFromUtility : programName}
      onEmail={handleEmail}
      onSms={handleSms}
      shareTextColor={shareTextColor}
      minHeight={minHeight}
      containerPadding={containerPadding}
      titleMargin={titleMargin}
    />
  );
};

export default GBCPendingContainer;
