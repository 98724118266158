import React from 'react';
import Modal from '@/molecules/Modal';
import { Description } from './ReEnrollmentSuccessModal.styles';

const ReEnrollmentSuccessModal = ({
  isVisible,
  onClose,
  title,
  description,
  buttonText,
}: {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  description: string;
  buttonText: string;
}) => {
  return (
    <Modal showClose show={isVisible} title={title} onClose={onClose} onOk={onClose} textOk={buttonText}>
      <div style={{ display: 'grid', placeItems: 'center' }}>
        <Description>{description}</Description>
      </div>
    </Modal>
  );
};

export default ReEnrollmentSuccessModal;
