/* eslint-disable class-methods-use-this */
import { DashboardTypes } from '@/types/dashboard';
import { ProgramStrategy } from '../Context/interface';

export class SCE_ELRP_A1 implements ProgramStrategy {
  getDashboardType(displayMeterData?: boolean, hasEvents?: boolean) {
    if (hasEvents && displayMeterData) {
      return DashboardTypes.DASHBOARD_WITH_EARNINGS_DR_AND_GRID;
    }
    return DashboardTypes.DASHBOARD_WITH_DR_AND_GRID;
  }

  getShouldDisplayMoneyEarned(hasEvents?: boolean) {
    if (hasEvents) {
      return true;
    }
    return false;
  }

  getShouldDisplayEnergyEventsTab() {
    return false;
  }

  getShouldDisplayProgramInformation() {
    return false;
  }

  getShouldDisplaySavingsIdeas() {
    return false;
  }

  getShouldDisplayPortfolio() {
    return true;
  }

  getShouldDisplayReferralBlock() {
    return false;
  }

  getShouldDisplayReferral() {
    return false;
  }

  getShouldDisplayRefreshMySites() {
    return false;
  }

  getShouldDisplayCO2Emissions() {
    return false;
  }

  getShouldDisplayReEnrollmentFeature() {
    return false;
  }

  shouldCheckGbcAuthStatusBeforeReEnroll() {
    return false;
  }
}
