import React from 'react';
import { useTranslation } from 'react-i18next';
import LocationSetup from '@/organisms/LocationSetup';
import ModalZipcode from '@/organisms/ModalZipcode';
import EnrollmentTemplate from '@/templates/EnrollmentTemplate';
import ModalNoPrograms from '@/organisms/ModalNoPrograms';
import PATHS from '@/types/navigationPaths';
import { LinkEnrollmentChoice } from '@/types/enrollment';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import { shouldDisplayLinkEnrollmentFlow } from '@/helpers/ShouldDisplayLinkEnrollmentFlow';
import { ILocationSetupScreenViewProps } from './index.types';

const LocationSetupScreenView = ({
  onNext,
  disabledNext,
  modalZipcode,
  modalZipcodeClose,
  modalZipcodeOk,
  modalZipcodeCancel,
  modalNoPrograms,
  modalNoProgramsOk,
}: ILocationSetupScreenViewProps) => {
  const { t } = useTranslation('common');
  const {
    enrollment: {
      enrollmentInfo: { linkEnrollmentChoice },
    },
    userInfo: {
      data: { userInfo },
    },
  } = useSelector((state: RootState) => state);

  const displayLinkEnrollmentFlow =
    shouldDisplayLinkEnrollmentFlow(userInfo.email) && linkEnrollmentChoice === LinkEnrollmentChoice.LinkEnrollment;

  return (
    <>
      <EnrollmentTemplate
        disabled={disabledNext}
        underline
        navigationTitle={t('screens.enrollment.locationSetupScreen.title')}
        navigationPath={PATHS.SCREEN_ENROLLMENT_LOCATION_SETUP.pathname}
        previousNavigationPath={
          displayLinkEnrollmentFlow
            ? PATHS.SCREEN_EXISTING_CUSTOMER_FLOW_START_SETUP.pathname
            : PATHS.SCREEN_ENROLLMENT_PROFILE_SETUP.pathname
        }
        onNext={onNext}
        textNext={t('common.generalButtons.next')}
      >
        <LocationSetup onNext={onNext} disabled={disabledNext} />
      </EnrollmentTemplate>
      <ModalZipcode
        show={modalZipcode}
        onClose={modalZipcodeClose}
        onOk={modalZipcodeOk}
        onCancel={modalZipcodeCancel}
      />
      <ModalNoPrograms show={modalNoPrograms} onOk={modalNoProgramsOk} />
    </>
  );
};

export default LocationSetupScreenView;
