import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { images } from '@/assets/styles';
import { FillPrimary, IconButton, PrimaryButton, Stroke } from '@/designSystem/components/buttons';
import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import EnrollmentStatusLabel from '@/organisms/EnrollmentStatusLabel';
import TimeAgo from 'react-timeago';
import { timeagoFormatter } from '@/helpers/LanguageHelper';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '@/stores/index';
import { Controller, useForm } from 'react-hook-form';
import ModalRemoveSite from '@/organisms/ModalRemoveSite';
import { useMediaQuery } from 'react-responsive';
import { sizes } from '@/assets/styles/media';
import { Modal } from '@/designSystem/components';
import useSites from '@/hooks/useSites';
import { EnrollmentStatus } from '@/types/enrollment';
import { getProgramContext } from '@/helpers/ProgramStrategy';
import {
  LastUpdateContainer,
  SiteAddressText,
  EditButtonContainer,
  SiteItemHeader,
  SiteItemLowerContainer,
  SiteItemTitle,
  SiteItemUpperContainer,
  SiteItemWrapper,
  SiteItemButtonContainer,
  InputComponent,
  DeleteSiteButtonContainer,
  editModalStyle,
  MobileEditPopupWrapper,
  MobileEditSiteContainer,
  CloseButtonContainer,
  SiteItemMiddleContainer,
} from './ManageSiteItem.styles';
import { ManageSiteItemTypes } from './ManageSiteItem.types';
import ItemBody from './ItemBody';

const ManageSiteItem = ({
  siteId,
  id,
  name,
  addressStreet1,
  programName,
  onChangeSite,
  updated,
  onProgramNameClick,
  enrollmentStatus,
  pendingProgramName,
  onClickEnroll,
}: ManageSiteItemTypes) => {
  const {
    userInfo: {
      data: {
        userInfo: { isPrimary },
      },
    },
    sites: {
      data: { sites },
    },
    setting: {
      data: { language },
    },
  } = useSelector((state: RootState) => state);
  const { fetchUpdateSite, fetchDeleteSite, fetchEnrollingSiteId } = useSites();
  const { t } = useTranslation('common');
  const isMobileWindowSize = useMediaQuery({ maxWidth: sizes.lg });
  const navigate = useNavigate();
  const site = sites.find((site) => site.id === id);
  const [isFocused, setIsFocused] = useState(false);
  const [disabledSave, setDisabledSave] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [mobileEditMode, setMobileEditMode] = useState(false);
  const [modalRemoveSiteVisiblility, setModalRemoveSiteVisiblility] = useState(false);
  const context = getProgramContext({ programName });
  const shouldDisplayReEnrollmentFeature = context?.getShouldDisplayReEnrollmentFeature();

  const handleEditMode = () => {
    if (editMode) {
      sentEvent('edit_site', PATHS.EDIT_SITE);
    } else {
      sentEvent('edit_done', PATHS.EDIT_DONE);
    }
    setEditMode(!editMode);
  };

  const handleMobileEditMode = () => {
    if (mobileEditMode) {
      sentEvent('edit_site', PATHS.EDIT_SITE);
    } else {
      sentEvent('edit_done', PATHS.EDIT_DONE);
    }
    setMobileEditMode(!mobileEditMode);
  };

  const handleSiteName = (value: string) => {
    setDisabledSave(site?.name === value);
  };

  const { control, handleSubmit } = useForm<{ editName: string }>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    defaultValues: {
      editName: site?.name || '',
    },
  });

  const handleDelete = () => {
    if (sites.length === 1) {
      // eslint-disable-next-line no-alert
      alert('There must be one site.');
      return;
    }
    sentEvent('delete_site_modal', PATHS.DELETE_SITE_MODAL);
    setModalRemoveSiteVisiblility(true);
  };

  const onCancelEdit = () => {
    sentEvent('cancel_edit_site', PATHS.CANCEL_EDIT_SITE);
    setEditMode(false);
  };

  const handleSave = ({ editName }: { editName: string }) => {
    sentEvent('save_edit_site', PATHS.SAVE_EDIT_SITE(editName || ''));
    const payload = { name: editName || '' };
    if (site) {
      fetchUpdateSite(site.id, payload);
      setDisabledSave(true);
    }
    if (isMobileWindowSize) {
      setMobileEditMode(false);
    }
  };

  const handleCloseDeleteModal = () => {
    sentEvent('cancel_delete_site_', PATHS.CANCEL_DELETE_SITE);
    setModalRemoveSiteVisiblility(false);
  };

  const onEnrollment = () => {
    // sets id to be fetched in SiteEnrollment component
    fetchEnrollingSiteId(id);
    navigate(PATHS.SCREEN_SETTING_SITE_ENROLL.pathname);
    sentEvent('enrollment_link', PATHS.ENROLLMENT_LINK);
  };

  const handleModalRemoveSiteOk = () => {
    sentEvent('delete_site', PATHS.DELETE_SITE(site?.id || ''));
    fetchDeleteSite(site?.id || '');

    handleCloseDeleteModal();
  };

  useEffect(() => {
    if (isMobileWindowSize) {
      setEditMode(false);
    } else {
      setMobileEditMode(false);
    }
  }, [isMobileWindowSize]);

  return (
    <SiteItemWrapper selected={siteId === id} editmode={editMode}>
      <SiteItemUpperContainer>
        <SiteItemHeader>
          {!editMode ? (
            <SiteItemTitle>{name}</SiteItemTitle>
          ) : (
            <Controller
              name="editName"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputComponent
                  data-testid="editName"
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleSiteName(e.target.value);
                  }}
                  isFocused={isFocused}
                  value={value}
                  aria-label={t('common.validations.siteNickname.title')}
                  disabled={!isPrimary}
                  readOnly={!isPrimary}
                />
              )}
            />
          )}
        </SiteItemHeader>
        <EditButtonContainer>
          <IconButton
            padding="5px"
            ariaLabel="Edit site button"
            onClick={isMobileWindowSize ? handleMobileEditMode : handleEditMode}
            image={{
              width: '20px',
              height: '20px',
              active: {
                src: images.editSite.image,
                alt: t(images.editSite.text),
              },
              disabled: {
                src: images.editSiteActive.image,
                alt: t(images.editSiteActive.text),
              },
            }}
            disabled={editMode}
          />
        </EditButtonContainer>
      </SiteItemUpperContainer>
      <SiteItemMiddleContainer>
        <SiteAddressText>{addressStreet1}</SiteAddressText>

        <EnrollmentStatusLabel enrollmentStatus={enrollmentStatus} />
      </SiteItemMiddleContainer>
      <ItemBody
        enrollmentStatus={enrollmentStatus}
        programName={programName}
        pendingProgramName={pendingProgramName}
        handleEnrollment={onEnrollment}
        handleProgramNameClick={onProgramNameClick}
      />
      {!editMode ? (
        <SiteItemLowerContainer>
          <LastUpdateContainer>
            {t('screens.main.settings.mySites.mySiteDetailScreen.lastUpdate')}
            (<TimeAgo date={updated ?? ''} formatter={timeagoFormatter(language)} />)
          </LastUpdateContainer>
          <SiteItemButtonContainer>
            <FillPrimary
              title={t('screens.main.settings.mySites.mySiteDetailScreen.switchSite')}
              onClick={() => {
                onChangeSite(id);
                sentEvent('select_site', PATHS.EDIT_SELECT_SITE(id));
              }}
              padding="10px 14px"
              disabled={siteId === id}
              flex={isMobileWindowSize ? 1 : 'unset'}
            />
          </SiteItemButtonContainer>
        </SiteItemLowerContainer>
      ) : (
        <SiteItemLowerContainer>
          <DeleteSiteButtonContainer>
            {/* {isPrimary && (
              <IconButton
                onClick={handleDelete}
                title={t('screens.main.settings.mySites.mySiteDetailScreen.delete')}
                padding="10px 10px"
                warning
              />
            )} */}
          </DeleteSiteButtonContainer>
          <SiteItemButtonContainer>
            <Stroke
              onClick={onCancelEdit}
              title={t('common.generalButtons.cancel')}
              padding="10px 0"
              flex={1}
              showBorder={false}
            />
            {isPrimary && (
              <FillPrimary
                title={t('common.generalButtons.save')}
                onClick={handleSubmit(handleSave)}
                padding="10px 0"
                disabled={disabledSave}
                flex={1}
              />
            )}
          </SiteItemButtonContainer>
        </SiteItemLowerContainer>
      )}

      {shouldDisplayReEnrollmentFeature && enrollmentStatus === EnrollmentStatus.Disenrolled && (
        <PrimaryButton title={t('common.generalButtons.enroll')} onClick={onClickEnroll} />
      )}
      <Modal show={mobileEditMode} ariaControlsId="site-edit-mobile-popup" style={editModalStyle} fullWidth small>
        <MobileEditPopupWrapper>
          <CloseButtonContainer>
            <IconButton
              padding="8px"
              onClick={handleMobileEditMode}
              image={{
                width: '12px',
                height: '6px',
                active: {
                  src: images.closeDownBtn.image,
                  alt: t(images.closeDownBtn.text),
                },
                disabled: {
                  src: images.closeDownBtn.image,
                  alt: t(images.closeDownBtn.text),
                },
              }}
              disabled={editMode}
            />
          </CloseButtonContainer>
          <SiteItemUpperContainer>
            <SiteItemHeader>
              <MobileEditSiteContainer>
                <Controller
                  name="editName"
                  rules={{
                    required: true,
                  }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputComponent
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                      onChange={(e) => {
                        onChange(e.target.value);
                        handleSiteName(e.target.value);
                      }}
                      isFocused={isFocused}
                      value={value}
                      aria-label={t('common.validations.siteNickname.title')}
                      disabled={!isPrimary}
                      readOnly={!isPrimary}
                    />
                  )}
                />
                <IconButton
                  padding="5px"
                  onClick={handleDelete}
                  image={{
                    width: '32px',
                    height: '32px',
                    active: {
                      src: images.removeSiteBtn.image,
                      alt: t(images.removeSiteBtn.text),
                    },
                    disabled: {
                      src: images.removeSiteBtn.image,
                      alt: t(images.removeSiteBtn.text),
                    },
                  }}
                  disabled={editMode}
                />
              </MobileEditSiteContainer>
            </SiteItemHeader>
          </SiteItemUpperContainer>

          <EnrollmentStatusLabel enrollmentStatus={enrollmentStatus} />

          <SiteItemLowerContainer>
            <SiteItemButtonContainer>
              <Stroke
                onClick={handleMobileEditMode}
                title={t('common.generalButtons.cancel')}
                padding="10px 0"
                flex={1}
                showBorder={false}
              />
              {isPrimary && (
                <FillPrimary
                  title={t('common.generalButtons.save')}
                  onClick={handleSubmit(handleSave)}
                  padding="10px 0"
                  disabled={disabledSave}
                  flex={1}
                />
              )}
            </SiteItemButtonContainer>
          </SiteItemLowerContainer>
        </MobileEditPopupWrapper>
      </Modal>
      <ModalRemoveSite
        isVisible={modalRemoveSiteVisiblility}
        onClose={handleCloseDeleteModal}
        onClickYes={handleModalRemoveSiteOk}
        onClickNo={handleCloseDeleteModal}
        ariaControlsId="site-delete-popup"
      />
    </SiteItemWrapper>
  );
};

export default ManageSiteItem;
