import React, { useEffect } from 'react';
import Img from '@/atoms/Img';
import { images } from '@/assets/styles';
import DropboxItem from '@/molecules/Dropdown/modules/DropboxItem';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import { Wrapper, Container, SelectedDate, ItemWrapper, ItemContainer, OpenedDropdown } from './index.styles';
import { IDropdownProps } from './index.types';

const DropdownContainer = ({
  accessibilityLabel,
  keepOpen,
  placeholder,
  items,
  values,
  borderRadius,
  marginTop,
  marginLeft,
  marginRight,
  marginBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingBottom,
  onChange,
  hideBoxShadow,
  hideBackground,
  ariaLabelledBy,
  textAlign,
  width,
  isBorder,
}: IDropdownProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const { buttonProps, isOpen, setIsOpen } = useDropdownMenu(items.length);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const { target } = event;

      if (isOpen && !ref?.current?.contains(target as HTMLElement)) {
        setIsOpen(false);
      }
    };

    window.addEventListener('click', handleClick, false);

    return () => {
      window.removeEventListener('click', handleClick, false);
    };
  }, [isOpen]);

  const handleChange = (value: string | number, text: string) => {
    if (!keepOpen) {
      setIsOpen(false);
    }

    onChange([value], text);
  };

  const retText = () => {
    const ret = items.filter((item) => {
      return values[0] === item.name;
    });

    const { length } = ret;
    if (length === 0 && placeholder) {
      return placeholder;
    }

    return ret[0]?.text || placeholder;
  };

  const displayedText = retText();

  return (
    <Wrapper ref={ref}>
      <Container
        {...buttonProps}
        borderRadius={borderRadius}
        marginTop={marginTop}
        marginLeft={marginLeft}
        marginRight={marginRight}
        marginBottom={marginBottom}
        paddingLeft={paddingLeft}
        paddingRight={paddingRight}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        hideBoxShadow={hideBoxShadow}
        hideBackground={hideBackground}
        disabled={items?.length === 0}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        aria-label={accessibilityLabel}
        id="button-select"
        aria-haspopup="listbox"
        aria-labelledby={`${ariaLabelledBy} ${displayedText}`}
        width={width}
        isBorder={isBorder}
      >
        <SelectedDate id={displayedText} textAlign={textAlign}>
          {displayedText}
        </SelectedDate>
        {values?.length > 0 && <Img altAttr="" src={isOpen ? images.dropdownUp.image : images.dropdownDown.image} />}
      </Container>
      {isOpen && items.length > 0 && (
        <OpenedDropdown>
          <ItemWrapper>
            <ItemContainer role="listbox" aria-expanded={isOpen}>
              <DropboxItem items={items} values={values} onChange={handleChange} />
            </ItemContainer>
          </ItemWrapper>
        </OpenedDropdown>
      )}
    </Wrapper>
  );
};

export default DropdownContainer;
