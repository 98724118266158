import { IResponse } from '@/apis/api';
import { ReqDeleteUser, ReqSetting, ReqUpdateUserInfo } from '@/apis/auth/types';
import { setLocalToken } from '@/services/token';
import { LinkEnrollmentChoice, ProgramName } from '@/types/enrollment';
import { ActivationStatus, GBCStatus, UserInfo, UtilityProviderEnum } from '@/types/userInfo';

// Types
// Reset User data
export const USER_INFO_RESET = 'userInfo/USER_INFO_RESET';

// User Info
export const USER_INFO_REQUEST = 'userInfo/USER_INFO_REQUEST';
export const USER_INFO_SUCCESS = 'userInfo/USER_INFO_SUCCESS';
export const USER_INFO_FAILURE = 'userInfo/USER_INFO_FAILURE';

// Update User Info
export const UPDATE_USER_INFO_REQUEST = 'userInfo/UPDATE_USER_INFO_REQUEST';
export const UPDATE_USER_INFO_SUCCESS = 'userInfo/UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_FAILURE = 'userInfo/UPDATE_USER_INFO_FAILURE';

// Delete User
export const DELETE_USER_REQUEST = 'userInfo/DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'userInfo/DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'userInfo/DELETE_USER_FAILURE';

// Acitvate Invited User
export const ACTIVATE_INVITED_USER_REQUEST = 'userInfo/ACTIVATE_INVITED_USER_REQUEST';
export const ACTIVATE_INVITED_USER_UPDATE = 'userInfo/ACTIVATE_INVITED_USER_UPDATE';
export const ACTIVATE_INVITED_USER_SUCCESS = 'userInfo/ACTIVATE_INVITED_USER_SUCCESS';
export const ACTIVATE_INVITED_USER_FAILURE = 'userInfo/ACTIVATE_INVITED_USER_FAILURE';

interface UserInfoResetAction {
  type: typeof USER_INFO_RESET;
}

interface UserInfoRequestAction {
  type: typeof USER_INFO_REQUEST;
}

interface UserInfoSuccessAction {
  type: typeof USER_INFO_SUCCESS;
  userInfo: UserInfo;
}

interface UserInfoFailureAction {
  type: typeof USER_INFO_FAILURE;
  errorMessage: string;
}

interface UpdateUserInfoRequestAction {
  type: typeof UPDATE_USER_INFO_REQUEST;
  userInfo: UserInfo;
}

interface UpdateUserInfoSuccessAction {
  type: typeof UPDATE_USER_INFO_SUCCESS;
  userInfo: UserInfo;
}

interface UpdateUserInfoFailureAction {
  type: typeof UPDATE_USER_INFO_FAILURE;
  errorMessage: string;
}

interface DeleteUserRequestAction {
  type: typeof DELETE_USER_REQUEST;
  userInfo: UserInfo;
}

interface DeleteUserSuccessAction {
  type: typeof DELETE_USER_SUCCESS;
  userInfo: UserInfo;
}

interface DeleteUserFailureAcition {
  type: typeof DELETE_USER_FAILURE;
  errorMessage: string;
}

interface ActivateInvitedUserRequestAction {
  type: typeof ACTIVATE_INVITED_USER_REQUEST;
}
interface ActivateInvitedUserUpdateAction {
  type: typeof ACTIVATE_INVITED_USER_UPDATE;
  activationStatus: ActivationStatus;
}
interface ActivateInvitedUserSuccessAction {
  type: typeof ACTIVATE_INVITED_USER_SUCCESS;
  activationStatus: ActivationStatus;
}
interface ActivateInvitedUserFailureAction {
  type: typeof ACTIVATE_INVITED_USER_FAILURE;
  errorMessage: boolean;
}

type InitActionTypes =
  | UserInfoResetAction
  | UserInfoRequestAction
  | UserInfoSuccessAction
  | UserInfoFailureAction
  | UpdateUserInfoRequestAction
  | UpdateUserInfoSuccessAction
  | UpdateUserInfoFailureAction
  | DeleteUserRequestAction
  | DeleteUserSuccessAction
  | DeleteUserFailureAcition
  | ActivateInvitedUserRequestAction
  | ActivateInvitedUserUpdateAction
  | ActivateInvitedUserSuccessAction
  | ActivateInvitedUserFailureAction;

// initial state
export interface UserInfoState {
  data: {
    userInfo: UserInfo;
    activation: {
      activationStatus: ActivationStatus | null;
      error: boolean;
    };
  };
  isLoading: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  isActivatingInvitedUser: boolean;
  error: {
    hasError: boolean;
    errorMessage: string;
  };
}

const userInfoInitialState = {
  id: '',
  isAuthenticated: false,
  oidcAccessToken: '',
  oidcRefreshToken: '',
  oidcIdToken: '',
  gbcStatus: GBCStatus.none,
  email: '',
  firstName: '',
  lastName: '',
  isLocationBusiness: false,
  zipcode: '',
  phoneNumbers: [],
  siteName: '',
  language: '',
  isDeleted: false,
  utility: '',
  enrollmentScreen: '',
  program: '' as ProgramName,
  linkEnrollmentChoice: LinkEnrollmentChoice.NewEnrollment,
  userGbiRedirect: {
    program: '',
    provider: UtilityProviderEnum.NONE,
  },
  isPrimary: true,
};

const initialState: UserInfoState = {
  data: {
    userInfo: userInfoInitialState,
    activation: {
      activationStatus: null,
      error: false,
    },
  },
  isLoading: false,
  isUpdating: false,
  isDeleting: false,
  isActivatingInvitedUser: false,
  error: {
    hasError: false,
    errorMessage: '',
  },
};

// Reducer
// eslint-disable-next-line default-param-last
export default (state: UserInfoState = initialState, action: InitActionTypes): UserInfoState => {
  switch (action.type) {
    case USER_INFO_RESET: {
      return {
        ...initialState,
      };
    }

    case USER_INFO_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };
    }

    case USER_INFO_SUCCESS:
      setLocalToken(action.userInfo.oidcAccessToken);
      return {
        ...state,
        data: {
          ...state.data,
          userInfo: {
            id: action.userInfo.id,
            isAuthenticated: action.userInfo.isAuthenticated,
            oidcAccessToken: action.userInfo.oidcAccessToken,
            oidcIdToken: action.userInfo.oidcIdToken,
            oidcRefreshToken: action.userInfo.oidcRefreshToken,
            gbcStatus: action.userInfo.gbcStatus,
            email: action.userInfo.email,
            firstName: action.userInfo.firstName,
            lastName: action.userInfo.lastName,
            isLocationBusiness: action.userInfo.isLocationBusiness,
            zipcode: action.userInfo.zipcode,
            phoneNumbers: action.userInfo.phoneNumbers,
            siteName: action.userInfo.siteName,
            language: action.userInfo.language,
            utility: action.userInfo.utility,
            enrollmentScreen: action.userInfo.enrollmentScreen,
            isDeleted: action.userInfo.isDeleted,
            program: action.userInfo.program,
            linkEnrollmentChoice: action.userInfo.linkEnrollmentChoice,
            userGbiRedirect: action.userInfo.userGbiRedirect
              ? {
                  program: action.userInfo.userGbiRedirect.program,
                  provider: action.userInfo.userGbiRedirect.provider,
                }
              : state.data.userInfo.userGbiRedirect,
            isPrimary: action.userInfo.isPrimary === undefined ? true : action.userInfo.isPrimary,
          },
        },
      };

    case USER_INFO_FAILURE:
      return {
        ...state,
        data: { ...state.data, userInfo: userInfoInitialState },
        isLoading: false,
        error: {
          hasError: true,
          errorMessage: action.errorMessage,
        },
      };

    case UPDATE_USER_INFO_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case UPDATE_USER_INFO_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        data: {
          ...state.data,
          userInfo: Object.assign(state.data.userInfo, action.userInfo),
        },
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case UPDATE_USER_INFO_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: {
          hasError: true,
          errorMessage: action.errorMessage,
        },
      };

    case DELETE_USER_REQUEST:
      return {
        ...state,
        isDeleting: true,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        data: {
          ...state.data,
          userInfo: Object.assign(state.data.userInfo, action.userInfo),
        },
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case DELETE_USER_FAILURE:
      return {
        ...state,
        isDeleting: false,
        error: {
          hasError: true,
          errorMessage: action.errorMessage,
        },
      };

    case ACTIVATE_INVITED_USER_REQUEST:
      return {
        ...state,
        isActivatingInvitedUser: true,
        data: {
          ...state.data,
          activation: {
            activationStatus: null,
            error: false,
          },
        },
      };

    case ACTIVATE_INVITED_USER_SUCCESS:
      return {
        ...state,
        isActivatingInvitedUser: false,
        data: {
          ...state.data,
          activation: {
            activationStatus: action.activationStatus,
            error: false,
          },
        },
      };

    case ACTIVATE_INVITED_USER_UPDATE:
      return {
        ...state,
        isActivatingInvitedUser: false,
        data: {
          ...state.data,
          activation: {
            activationStatus: action.activationStatus,
            error: false,
          },
        },
      };

    case ACTIVATE_INVITED_USER_FAILURE:
      return {
        ...state,
        isActivatingInvitedUser: false,
        data: {
          ...state.data,
          activation: {
            activationStatus: null,
            error: true,
          },
        },
      };

    default:
      return state;
  }
};

// Actions

export const userInfoReset = () => ({ type: USER_INFO_RESET });

export const deleteUserRequest = (userInfo: ReqDeleteUser, callback?: () => void) => ({
  type: DELETE_USER_REQUEST,
  userInfo,
  callback,
});

export const userInfoRequest = (callback: (userInfo: UserInfo) => void, settingInfo?: ReqSetting) => ({
  type: USER_INFO_REQUEST,
  callback,
  settingInfo,
});

export const updateUserInfoRequest = (userInfo: ReqUpdateUserInfo, callback?: (response: IResponse) => void) => ({
  type: UPDATE_USER_INFO_REQUEST,
  userInfo,
  callback,
});

export const activateInvitedUserRequest = () => ({
  type: ACTIVATE_INVITED_USER_REQUEST,
});
