import styled from 'styled-components';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';

export const Description = styled.p`
  ${typography.xs};
  color: ${colors.grey};
`;

export const Margin = styled.div`
  margin-top: 20px;
`;
