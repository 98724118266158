import React from 'react';
import Modal from '@/molecules/Modal';
import { PrimaryButton } from '@/designSystem/components/buttons';
import { Description } from './ReEnrollmentErrorModal.styles';

const ReEnrollmentErrorModal = ({
  isVisible,
  onClose,
  title,
  description,
  buttonText,
}: {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  description: string;
  buttonText: string;
}) => {
  return (
    <Modal showClose show={isVisible} title={title} onClose={onClose}>
      <div style={{ display: 'grid', placeItems: 'center' }}>
        <Description>{description}</Description>
        <PrimaryButton title={buttonText} onClick={onClose} />
      </div>
    </Modal>
  );
};

export default ReEnrollmentErrorModal;
