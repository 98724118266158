import styled, { css } from 'styled-components';
import { global, helpers, media } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';

export const Wrapper = styled.div`
  margin: 16px;
  border-radius: 8px;
  display: grid;
  gap: 20px;
  background-color: ${colors.white};
  padding: 16px;

  ${media.lg`
    padding: unset;
    margin: unset;
    border-radius: unset;
    background-color: unset;
  `}
`;
export const Navigator = styled.div`
  border-radius: 8px;
  background: ${colors.white};
  display: none;

  ${media.md`
    padding: 24px;
  `}
  ${media.lg`
    display: flex;
    justify-content: space-between;
  `}
`;

export const SearchWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  ${media.lg`
    grid-template-columns: repeat(2, 1fr);
  `}
`;

export const Title = styled.p`
  ${typography.xl2}
  color: ${colors.text};
  font-weight: 900;
`;

export const Right = styled.div`
  display: none;
  ${media.lg`
    display: block;
  `}
`;

export const SearchContainer = styled.div`
  width: 100%;
`;

export const BodyWrapper = styled.div`
  border-radius: 8px;
  background: ${colors.white};
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  height: calc(100vh - 102px);
  ${media.lg`
    height: fit-content;
    padding: 0 16px;
    gap: 30px;
    padding: 16px 24px;
  `}
`;
export const SiteListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding-bottom: 20px;
  margin-bottom: 20px;

  ${media.lg`
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    border-bottom: 1px solid ${colors.lightGrey};
  `}
`;

export const ButtonContainer = styled.div`
  align-self: center;
`;

export const PageContainer = styled.div`
  ${helpers.flexSet('row', 'center', 'center')};
  margin-bottom: 22px;
`;

export const EmptyContainer = styled.div`
  ${helpers.flexSet('column', 'center', 'center')};
  padding: 20px;
`;

export const Empty = styled(global.H5)(
  ({ theme }) => css`
    ${helpers.colorSet({ color: theme.colors.text[80] })};

    margin-top: 20px;
  `,
);

export const LeftSection = styled.div`
  ${helpers.flexSet('row', 'flex-start', 'center')};
`;

export const NoSite = styled.p`
  margin-right: 8px;
`;

export const ScreenName = styled.p`
  display: none;
  color: ${colors.text};
  font-weight: 900;
  margin-right: 4px;
  ${media.lg`
    ${typography.xl};
    line-height: 22px;
    display: block;
  `};
`;
export const SiteName = styled.p`
  ${typography.md}
  color: ${colors.grey};
  font-weight: 700;
  ${media.lg`
    ${typography.xl};
    line-height: 22px;
  `};
  &::before {
    content: ' / ';
  }
`;

export const Information = styled.div`
  margin-left: 20px;
`;
