import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '@/stores/index';
import { useUserInfo, useEnrollment, useSites } from '@/hooks/index';
import { setLocalToken } from '@/services/token';
import PATHS from '@/types/navigationPaths';
import { GBCStatus, UserInfo } from '@/types/userInfo';
import { TMP_PATHS } from '@/helpers/NavigationHelper';
import { ProgramName, UtilityPrograms } from '@/types/enrollment';
import contents from '@/assets/config/appInfo';
import { TempScale } from '@/types/setting';
import { ReqSetting } from '@/apis/auth/types';
import { NAVIGATED_FROM_INVITATION_LANDING_KEY } from '@/constants/sessionStorageKeys';
import { isUtilityEdition } from '@/helpers/Edition';
import { shouldDisplayLinkEnrollmentFlow } from '@/helpers/ShouldDisplayLinkEnrollmentFlow';
import RedirectErrorScreen from '../RedirectErrorScreen';

const RedirectContainer = () => {
  document.title = `Redirect - ${contents.name}`;
  const {
    loading: { loading: isLoading },
    setting: {
      data: { language },
    },
  } = useSelector((state: RootState) => state);
  const navigate = useNavigate();
  const fromInvitationLanding = sessionStorage.getItem(NAVIGATED_FROM_INVITATION_LANDING_KEY);
  const [timeout, isTimeout] = useState(false);
  const [hasAccessToken, setHasAccessToken] = useState<boolean>(false);
  const { fetchSites } = useSites();
  const { fetchUserInfo, fetchUpdateUserInfo } = useUserInfo();

  const { fetchProgramRequest, fetchChangePrograms, fetchEnrollment, fetchUtility, fetchChangeLinkEnrollmentChoice } =
    useEnrollment();

  const invitationLandingURL = isUtilityEdition ? PATHS.SCREEN_INVITATION.pathname : PATHS.SCREEN_CI.pathname;

  useEffect(() => {
    const timer = setTimeout(() => {
      isTimeout(true);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (hasAccessToken) return;

    const urlSearchParams = new URLSearchParams(window.location.search);
    const accessToken = urlSearchParams.get('accessToken');
    const error = urlSearchParams.get('error');

    if (error) {
      throw new Error(error);
    }

    if (!accessToken) {
      // Send as root screen because it is an invalid access
      navigate(PATHS.SCREEN_ROOT.pathname, { replace: true });
      return;
    }

    setLocalToken(accessToken);
    setHasAccessToken(true);
  }, [hasAccessToken]);

  useEffect(() => {
    fetchEnrollment();
    const payload: ReqSetting = {
      language,
      tempScale: TempScale.celsius,
    };

    fetchUserInfo((userInfo: UserInfo) => {
      if (fromInvitationLanding === 'true') {
        navigate(invitationLandingURL);
        sessionStorage.removeItem(NAVIGATED_FROM_INVITATION_LANDING_KEY);
        return;
      }

      if ([GBCStatus.none, GBCStatus.notStarted].includes(userInfo.gbcStatus)) {
        if (userInfo.program === ProgramName.SCE_ELRP_A1) {
          navigate(PATHS.SCREEN_NO_ENROLLMENT_ERROR.pathname);
        } else {
          const firstScreenNewEnrollment = isUtilityEdition
            ? PATHS.SCREEN_ENROLLMENT_PROFILE_SETUP.pathname
            : PATHS.SCREEN_ENROLLMENT_PROGRAM_ENROLLMENT.pathname;

          const firstScreenStepOfEnrollment = shouldDisplayLinkEnrollmentFlow(userInfo.email)
            ? PATHS.SCREEN_EXISTING_CUSTOMER_FLOW_START_SETUP.pathname
            : firstScreenNewEnrollment;

          navigate(firstScreenStepOfEnrollment);
        }
      } else if (
        userInfo.gbcStatus === GBCStatus.completed &&
        TMP_PATHS[userInfo.enrollmentScreen] === TMP_PATHS.NAV_SINGUP_COMPLETE
      ) {
        fetchSites(userInfo.siteName, (sites) => {
          if (sites?.gbcIntegration?.sites?.length) {
            if (window.ReactNativeWebView) {
              const data = JSON.stringify({ to: 'confirmProgram', gbcStatus: userInfo.gbcStatus });
              window.ReactNativeWebView.postMessage(data);
            }
            navigate(PATHS.SCREEN_ENROLLMENT_CONFIRM_PROGRAM.pathname);
            return;
          }
          if (window.ReactNativeWebView) {
            const data = JSON.stringify({ to: 'dashboard', gbcStatus: userInfo.gbcStatus });
            window.ReactNativeWebView.postMessage(data);
          } else {
            console.log(`not window.ReactNativeWebView`);
          }
          navigate(PATHS.SCREEN_DASHBOARD.pathname);
        });
      } else if ([GBCStatus.inProgress, GBCStatus.stillInProgress, GBCStatus.completed].includes(userInfo.gbcStatus)) {
        fetchProgramRequest(userInfo.zipcode, (utilityPrograms: UtilityPrograms) => {
          if (Object.values(utilityPrograms).length > 0) {
            const programs = utilityPrograms[userInfo.utility];
            fetchUtility(userInfo.utility);
            const programName = userInfo.program;
            const newPayload = {
              utility: userInfo.utility,
              program: programName,
            };
            fetchUpdateUserInfo(newPayload);
            fetchChangePrograms(programs);
          }

          if (
            isUtilityEdition &&
            TMP_PATHS[userInfo.enrollmentScreen] === PATHS.SCREEN_ENROLLMENT_PROGRAM_ENROLLMENT.pathname
          ) {
            navigate(PATHS.SCREEN_EXISTING_CUSTOMER_FLOW_START_SETUP.pathname);
          } else {
            fetchChangeLinkEnrollmentChoice(userInfo?.linkEnrollmentChoice || undefined);
            navigate(TMP_PATHS[userInfo.enrollmentScreen]);
          }
        });
      }
    }, payload);
  }, []);

  return <>{!isLoading && timeout && <RedirectErrorScreen />}</>;
};

export default RedirectContainer;
